import { React, Fragment, useState, useEffect } from "react";
import { PaperClipIcon } from "@heroicons/react/20/solid";
import {
  Accordion,
  AccordionHeader,
  AccordionBody
} from "@material-tailwind/react";
import Select from 'react-select'
import Automation from "./Automation";
import Stacked from "./Stacked";
import { adjustHeadcount, currencyFormat } from "./helpers";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Airtable from "airtable";
import Notification from "./Notification";
import { setShowNotif, setContents } from "./helpers";

//For airtable usage
const baseId = process.env.REACT_APP_AIR_BASEID;
const apiKey = process.env.REACT_APP_AIR_KEY;
const usersTableId = process.env.REACT_APP_AIR_USERS;
const base = new Airtable({ apiKey }).base(baseId);


function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${id === open ? "rotate-180" : ""
        } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Company({ company, isLoading, getQueryVariable, grantWriters, setSideBarOpen, userId, userFirstName, userLastName, submitFormNotif, setSubmitFormNotif }) {
  const [open, setOpen] = useState(1);
  const [loading, setLoading] = useState(true);
  // const [first_name, setFirstName] = useState('');
  // const [last_name, setLastName] = useState('');
  // let [isSubmitted, setIsSubmitted] = useState(true); //assume that the user has already submitted form so page load in is cleaner

  // function askForName() {
  //   if (!userFirstName || !userLastName) {
  //       setIsSubmitted(false);
  //   } else {
  //       setIsSubmitted(true);
  //   }
  // }

  useEffect(() => {
    setLoading(isLoading); //askForName(); 
  }, [isLoading]);

  const handleOpen = value => {
    setOpen(open === value ? 0 : value);
  };

  const people = [
    {
      name: 'Vedran',
      title: 'Lawyer',
      department: 'Legal',
      email: 'lindsay.walton@example.com',
      status: 'Active',
      compensation: '$65,432',
      role: 'Member',
      image:
        'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
      name: 'Kerem',
      title: 'Front-end Developer',
      department: 'Optimization',
      email: 'lindsay.walton@example.com',
      status: 'Active',
      compensation: '$65,432',
      role: 'Member',
      image:
        'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
      name: 'Michael',
      title: 'Front-end Developer',
      department: 'Optimization',
      email: 'lindsay.walton@example.com',
      status: 'Active',
      compensation: '$65,432',
      role: 'Member',
      image:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
      name: 'Derek',
      title: 'Front-end Developer',
      department: 'Optimization',
      email: 'lindsay.walton@example.com',
      status: 'Active',
      compensation: '$65,432',
      role: 'Member',
      image:
        'https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  ]

  const responsibilities = [
    {
      name: 'Vedran',
      title: 'Lawyer',
      department: 'Legal',
      email: 'lindsay.walton@example.com',
      status: 'Active',
      compensation: '$65,432',
      role: 'Member',
      image:
        'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
      name: 'Kerem',
      title: 'Front-end Developer',
      department: 'Optimization',
      email: 'lindsay.walton@example.com',
      status: 'Active',
      compensation: '$65,432',
      role: 'Member',
      image:
        'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
      name: 'Michael',
      title: 'Front-end Developer',
      department: 'Optimization',
      email: 'lindsay.walton@example.com',
      status: 'Active',
      compensation: '$65,432',
      role: 'Member',
      image:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
      name: 'Derek',
      title: 'Front-end Developer',
      department: 'Optimization',
      email: 'lindsay.walton@example.com',
      status: 'Active',
      compensation: '$65,432',
      role: 'Member',
      image:
        'https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  ]


  const options = [
    { value: 'lawyer', label: 'Lawyer' },
    { value: 'front-end developer', label: 'front-end developer' },
    { value: 'back-end developer', label: 'back-end developer' }
  ]

  const statuses = {
    Active: 'text-green-400 bg-green-400/10',
    Error: 'text-rose-400 bg-rose-400/10'
  }
  // const onSubmit = async (e)  => {
  //   e.preventDefault();

  //     fetch ("https://api.airtable.com/v0/" + baseId + "/" + usersTableId + "/" + userId, {

  //         method: "PATCH",
  //         headers: {
  //           Authorization: `Bearer ${apiKey}`,
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           fields: {
  //             ['First Name']: first_name,
  //             ['Last Name']: last_name,
  //           },
  //         }),
  //       })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         console.log("patch user table");
  //       })
  //       .catch((error) => console.error("Error:", error)); 

  //     setFirstName("");
  //     setLastName("");
  //     setIsSubmitted(true);
  //     setShowNotif(true, setSubmitFormNotif);
  // 	  setContents(
  // 		{ type: "success", heading: "Form Submitted", message: "Thank you for updating your information." },
  // 		setSubmitFormNotif
  // 	);
  //   }

  return (
    <>
      <div id="company-info"></div>
      <div className="sticky top-[105px] flex md:top-16 bg-white mx-auto w-full mt-10 z-10 pt-4">
        <div className="flex justify-between items-center w-full gap-5 mb-5">
          <div
            onClick={() => {
              setSideBarOpen(true);
            }}
            className="ml-4 flex items-center gap-2">
            <h1 className="text-2xl font-semibold leading-6 text-gray-900">Company Profile</h1>
            <a href="#vendor-savings" className="text-sm font-semibold leading-6 text-[rgb(110,72,101)] pt-1"
              onClick={() => {
                setSideBarOpen(true);
              }}>
              Add +
            </a>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-7xl ml-4 mr-4">
        <Fragment>
          {/* {!isSubmitted && (
            <div>
          <h1 className="text-m font-semibold leading-6 text-gray-900">We're Going To Need Some Personal Information First...What's Your Name?</h1>
          <div className="flex justify-between items-center mb-5 w-full mt-5 ">


          <form className="flex" 
          onSubmit={(e) => onSubmit(e)}>
            <input
              type="text"
              name="search"
              id="search"
              placeholder="First Name"
              value={first_name}
              className="mr-5 rounded "
              onChange = {(event) => setFirstName(event.target.value)}
              >
            </input>
         
            <input
              type="text"
              name="search"
              id="search"
              placeholder="Last Name"
              value={last_name}
              className="ml-5 mr-5 rounded"
              onChange = {(event) => setLastName(event.target.value)}
              >
            </input>

            
            <button type="submit"
            className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-3 text-center me-2 mb-2 shrink-">
              Submit
                 </button>
               </form>
            </div>
        </div>
        )
        } */}

          <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
            <AccordionBody className="pt-0">
              <div className="mt-6 mb-10 border-t border-gray-100">
                <dl className="divide-y divide-gray-100">

                  {loading && !(company?.fields?.Name) ? <Skeleton height={20} /> :
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Full name
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {company.fields.Name}
                      </dd>
                    </div>
                  }
                  {loading && !(company?.fields?.Domain) ? <Skeleton height={20} /> :
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Website
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        <a href={`https://${company.fields.Domain}`} target="_blank">{company.fields.Domain}</a>
                      </dd>
                    </div>
                  }
                  {loading && !(company?.fields?.LinkedInUrl) ? <Skeleton height={20} /> :
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        LinkedIn Url
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        <a href={company.fields.LinkedInUrl} target="_blank">{company.fields.LinkedInUrl}</a>
                      </dd>
                    </div>
                  }
                  {loading && !(company?.fields?.Headcount && company?.fields?.Employees) ? <Skeleton height={20} /> :
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Headcount
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {company.fields.Headcount ? adjustHeadcount(company.fields.Headcount, company.fields.Employees) + " | " + company.fields.Employees + " employees on LinkedIn"
                          : company.fields.Employees + " employees on LinkedIn"}
                      </dd>
                    </div>
                  }

                  {loading && !(company?.fields?.Founded) ? <Skeleton height={20} /> :
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Founded
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {company.fields.Founded}
                      </dd>
                    </div>
                  }

                  {loading && !(company?.fields?.Description) ? <Skeleton height={20} /> :
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Description
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {company.fields.Description}
                      </dd>
                    </div>
                  }

                  {loading && !(company?.fields?.Industry) ? <Skeleton height={20} /> :
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Industries
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {company.fields.Industry}
                      </dd>
                    </div>
                  }

                  {loading && !(company?.fields?.Country && company?.fields?.Address) ? <Skeleton height={20} /> :
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Address
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {company.fields.Address} {/*company.fields.Country*/}
                      </dd>
                    </div>
                  }

                  {loading && !(company?.fields?.CompanyType) ? <Skeleton height={20} /> :
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Company Type
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {company?.fields?.CompanyType ? company.fields.CompanyType : ""}
                      </dd>
                    </div>
                  }

                  {loading && !(company?.fields?.FollowerCount) ? <Skeleton height={20} /> :
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        LinkedIn Follower Count
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {company.fields.FollowerCount}
                      </dd>
                    </div>
                  }

                  {loading && !(company?.fields?.AnnualTraffic) ? <Skeleton height={40} /> :
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Annual Traffic
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {/* the "Traffic" field is still to be added to airtable*/}{company.fields?.AnnualTraffic ? company.fields.AnnualTraffic : "n/a"}
                      </dd>
                    </div>
                  }

                  {loading && !(company?.fields?.ConversionRate) ? <Skeleton height={20} /> :
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Conversion Rate
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {company?.fields?.ConversionRate ? company.fields.ConversionRate : 2}%
                      </dd>
                    </div>
                  }

                  {loading && !(company?.fields?.AvgDealSize) ? <Skeleton height={20} /> :
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Deal Size
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {currencyFormat(company?.fields?.AvgDealSize ? company.fields.AvgDealSize : 12250)}
                      </dd>
                    </div>
                  }

                  {loading && !(company?.fields?.AnnualRevenue && company?.fields.Employees) ? <Skeleton height={20} /> :
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Revenue
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {currencyFormat((company?.fields?.AnnualRevenue ? company.fields.AnnualRevenue : company.fields.Employees * 100000))}
                      </dd>
                    </div>
                  }

                  {loading && !(company?.fields?.Technologies) ? <Skeleton height={60} count={5} /> :
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Technologies
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {company?.fields?.Technologies
                          ? company.fields.Technologies
                          : ""}
                      </dd>
                    </div>
                  }

                  {loading && !(company?.fields) ? <Skeleton height={40} /> :
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Profitable
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {company?.fields['Profitable?'] ? company?.fields['Profitable?'] : ""}
                      </dd>
                    </div>
                  }

                  {loading && !(company?.fields) ? <Skeleton height={40} /> :
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Website Info
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {company?.fields.LongDescription ? company?.fields.LongDescription : ""}
                      </dd>
                    </div>
                  }
                </dl>
              </div>
            </AccordionBody>
          </Accordion>

          {/* Automations Table, now rendered directly through App.js */}
          {/* <Automation key={company.id} company={company} getQueryVariable={getQueryVariable} grantWriters={grantWriters} /> */}
        </Fragment>
      </div>
    </>
  );
}

export default Company;
