import React, { useState, useEffect } from "react";
import { currencyFormat } from "./helpers.js";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  FaRocket,
  FaGamepad,
  FaSun,
  FaChair,
  FaDice,
  FaLaptop,
  FaBuilding,
  FaHeart,
  FaBrain,
  FaRegCompass,
  FaUsers,
} from "react-icons/fa";

// Create a mapping of companies to specific icons for consistency
const companyIcons = {
  "Early Bird Enterprises": FaRocket,
  "Rocking Chair Revolution": FaChair,
  "Golden Years Gaming": FaGamepad,
  "Sunset Solutions LLC": FaSun,
  "Leisure Life Labs": FaHeart,
  "Bingo Tech Ventures": FaDice,
  "Silver Surfers Software": FaLaptop,
  "Retirement Rebels": FaUsers,
  "Wisdom Web Works": FaBrain,
  "Senior Social Systems": FaRegCompass,
  "Pension Peak Products": FaBuilding,
};

const MedalIcon = ({ position }) => {
  const medals = {
    1: "🥇",
    2: "🥈",
    3: "🥉",
  };

  return (
    <div className="w-12 text-center">
      {position <= 3 ? (
        <span className="text-3xl">{medals[position]}</span>
      ) : (
        <span className="text-md text-emerald-500 font-bold">{position}</span>
      )}
    </div>
  );
};

// Add a default avatar component for fallback
const DefaultAvatar = ({ name }) => {
  const initials =
    name
      ?.split(" ")
      .map((n) => n[0])
      .join("")
      .toUpperCase() || "?";
  return (
    <div className="h-6 w-6 rounded-full bg-gray-700 flex items-center justify-center text-white text-xs font-medium">
      {initials}
    </div>
  );
};

const LeaderboardEntry = ({ entry, position }) => {
  const CompanyIcon = companyIcons[entry.name] || FaBuilding;

  return (
    <div className="flex items-center w-full py-2.5 px-4 hover:bg-gray-800/50 rounded-lg">
      {/* Rank */}
      <div className="w-[60px] text-center">
        <MedalIcon position={position} />
      </div>

      {/* Company Icon */}
      <div className="w-[60px]">
        <div className="w-8 h-8 flex items-center justify-center mt-1 mr-2">
          {/* <CompanyIcon className="text-lg text-blue-400" /> */}
          <img
            src={entry.logo}
            alt={entry.name}
            className="w-6 h-6 object-contain rounded-xl"
            onError={(e) => {
              // Fallback to a default image or initial letter
              e.target.src = `https://ui-avatars.com/api/?name=${encodeURIComponent(
                entry.name
              )}&background=1a1a1a&color=fff&size=32`;
            }}
          />
        </div>
      </div>

      {/* Company Name */}
      <div className="flex-1 min-w-[80px] truncate">
        <span className="text-white text-sm link link-hover font-bold">
          {entry.name}
        </span>
      </div>

      {/* Revenue */}
      <div className="w-[80px] text-right">
        <span className="text-[#ffbe1a] font-mono text-sm font-bold">
          ${entry.revenue.toLocaleString()}/yr
        </span>
      </div>

      {/* Maker */}
      <div className="w-[200px] pl-8 flex items-center">
        {entry.maker.avatar && entry.maker.avatar.startsWith("http") ? (
          <img
            src={entry.maker.avatar}
            alt={entry.maker.name}
            className="h-6 w-6 rounded-full mr-2"
            onError={(e) => {
              e.target.style.display = "none";
              e.target.nextSibling.style.display = "flex";
            }}
          />
        ) : (
          <DefaultAvatar name={entry.maker.name} />
        )}
        <span className="text-gray-300 text-sm font-bold ml-2 text-nowrap truncate max-w-[120px]">
          {entry.maker.name}
        </span>
      </div>
    </div>
  );
};

const TableHeader = () => (
  <div className="flex items-center w-full px-4 pt-8 pb-4 text-gray-100/80 font-bold border-b border-[#D2B48C]/20 my-1 text-md font-bold">
    <div className="w-[60px] text-center">Rank</div>
    <div className="w-[60px]"></div> {/* Space for company icon */}
    <div className="flex-1 min-w-[80px]">Startup</div>
    <div className="w-[150px] pl-16">Revenue</div>
    <div className="w-[200px] pl-8">Maker</div>
  </div>
);

const Header = () => (
  <header className="gap-4 flex max-lg:flex-col justify-between p-2">
    <div className="space-y-4">
      <h1 className="text-3xl md:text-4xl font-extrabold text-white">
        Make +$150,000/yr easy?
      </h1>
      <div className="text-gray-300 leading-relaxed max-w-xl font-semibold">
        Your network is your net worth.
        <br /><br />
        Upgrade it systematically with Upgraded & freedom will follow.
        <br /><br />
        Compete with other upgraders and climb the leaderboard 🏆 🚀 🧗‍♂️ 🥇
      </div>
    </div>

    <div className="flex flex-row gap-4">
      {/* Navigation Icons */}
      <div className="flex">
        <a
          href="/automate-my-work"
          className="mt-4 bg-[#1c1c1c] flex items-center justify-center w-10 h-10 rounded-lg border border-gray-800 transition-all duration-150 ease-in-out hover:bg-gray-800 group"
          title="Automate My Work"
        >
          <span className="text-xl group-hover:scale-110 duration-150">🤖</span>
        </a>
      </div>

      {/* Existing Auth Buttons */}
      <div className="space-y-0.5">
        <div className="text-xs text-gray-500">New here?</div>
        <a
          className="bg-[#1c1c1c] flex items-center group justify-start gap-2 rounded-lg border border-gray-800 w-42 px-3 py-2 transition-all duration-150 ease-in-out hover:bg-gray-800"
          href="/sign-up"
        >
          <div className="flex items-center space-x-2">
            <svg
              className="w-[20px] h-[20px] fill-yellow-400 group-hover:scale-125 group-hover:rotate-3 duration-150 ease-in-out"
              viewBox="0 0 375 509"
            >
              <path d="M249.685 14.125C249.685 11.5046 248.913 8.94218 247.465 6.75675C246.017 4.57133 243.957 2.85951 241.542 1.83453C239.126 0.809546 236.463 0.516683 233.882 0.992419C231.301 1.46815 228.917 2.69147 227.028 4.50999L179.466 50.1812C108.664 118.158 48.8369 196.677 2.11373 282.944C0.964078 284.975 0.367442 287.272 0.38324 289.605C0.399039 291.938 1.02672 294.226 2.20377 296.241C3.38082 298.257 5.06616 299.929 7.09195 301.092C9.11775 302.255 11.4133 302.867 13.75 302.869H129.042V494.875C129.039 497.466 129.791 500.001 131.205 502.173C132.62 504.345 134.637 506.059 137.01 507.106C139.383 508.153 142.01 508.489 144.571 508.072C147.131 507.655 149.516 506.503 151.432 504.757L172.698 485.394C247.19 417.643 310.406 338.487 359.975 250.894L373.136 227.658C374.292 225.626 374.894 223.327 374.882 220.99C374.87 218.653 374.243 216.361 373.065 214.341C371.887 212.322 370.199 210.646 368.17 209.482C366.141 208.318 363.841 207.706 361.5 207.707H249.685V14.125Z" />
            </svg>
            <span className="font-medium text-white text-sm">Get Upgraded</span>
          </div>
        </a>
      </div>

      <div className="space-y-0.5">
        <div className="text-xs text-gray-500">Already member?</div>
        <a
          className="bg-[#1c1c1c] flex items-center group justify-start gap-2 rounded-lg border border-gray-800 w-42 px-3 py-2 transition-all duration-150 ease-in-out hover:bg-gray-800"
          href="/sign-in"
        >
          <svg
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="text-white w-[20px] h-[20px] group-hover:scale-125 group-hover:-rotate-3 duration-150 ease-in-out"
          >
            <circle cx="12" cy="8" r="5" />
            <path d="M20 21a8 8 0 0 0-16 0" />
          </svg>
          <span className="font-medium text-white text-sm">My Profile</span>
        </a>
      </div>
    </div>
  </header>
);

export default function PublicLeaderboard() {
  const [activeTab, setActiveTab] = useState("monthly");
  const [loading, setLoading] = useState(true);
  const [monthlyData, setMonthlyData] = useState([]);
  const [allTimeData, setAllTimeData] = useState([]);

  // Simulated data - replace with your actual API call
  useEffect(() => {
    // Fetch your leaderboard data here
    const fetchData = async () => {
      setLoading(true);
      try {
        // Replace with actual API calls
        const monthlyResponse = [
          {
            id: 1,
            name: "Tesla",
            logo: "https://logo.clearbit.com/tesla.com",
            revenue: 148030,
            maker: {
              name: "Kerem Tirmandi",
              avatar:
                "https://media.licdn.com/dms/image/v2/D5603AQGWcnTn0TvsJg/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1692826073695?e=1740009600&v=beta&t=Jns81Br0RBeK_a1rXGz4l90GBypQIoGWK7yJikl5stc",
            },
          },
          {
            id: 2,
            name: "Apple",
            logo: "https://logo.clearbit.com/apple.com",
            revenue: 137720,
            maker: {
              name: "Romain",
              avatar:
                "https://media.licdn.com/dms/image/v2/C5603AQE7GYURJ6YFCA/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1654653657454?e=1740009600&v=beta&t=J8Q0x_UmrPP00ATFjbCE2UH4GT6xaLchylEEoQw5VXk",
            },
          },
          {
            id: 3,
            name: "Microsoft",
            logo: "https://logo.clearbit.com/microsoft.com",
            revenue: 135540,
            maker: {
              name: "Colby Richardson Bryant",
              avatar:
                "https://media.licdn.com/dms/image/v2/D4D03AQHnYQUBeUADug/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1730298343380?e=1740009600&v=beta&t=juaftIszWenf19PpWcvkHb3bFbdzi5Pj9Y-9U98W0c0",
            },
          },
          {
            id: 4,
            name: "Amazon",
            logo: "https://logo.clearbit.com/amazon.com",
            revenue: 132210,
            maker: {
              name: "Derek Campbell",
              avatar: "/avatar4.png",
            },
          },
          {
            id: 5,
            name: "Nvidia",
            logo: "https://logo.clearbit.com/nvidia.com",
            revenue: 129880,
            maker: {
              name: "Houtan Sanandaji",
              avatar:
                "https://media.licdn.com/dms/image/v2/D5603AQF7mGytCxjdQQ/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1696808259613?e=1740009600&v=beta&t=O75SKJzUbu7j1Vx8eH5A6V3rjSe6To0uh-R1YTPwtq8",
            },
          },
          {
            id: 6,
            name: "Meta",
            logo: "https://logo.clearbit.com/meta.com",
            revenue: 127550,
            maker: {
              name: "Jameson",
              avatar:
                "https://media.licdn.com/dms/image/v2/C5603AQHcYGmOefJ-wg/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1582682922396?e=1740009600&v=beta&t=q1nkQyl67AMjsC7nTa1WEdqQs_w_IEGxQVpisdqxGEg",
            },
          },
          {
            id: 7,
            name: "Alphabet",
            logo: "https://logo.clearbit.com/abc.xyz",
            revenue: 125430,
            maker: {
              name: "Kyle Fox",
              avatar:
                "https://media.licdn.com/dms/image/v2/C4D03AQEhXmoOdIna0w/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1597428447805?e=1740009600&v=beta&t=1E-kovJw5vqbhLu6W_Ek4N6Yv2cWyUrWvC7ihTnj48Q",
            },
          },
          {
            id: 8,
            name: "SpaceX",
            logo: "https://logo.clearbit.com/spacex.com",
            revenue: 123320,
            maker: {
              name: "Matthew Parson",
              avatar:
                "https://media.licdn.com/dms/image/v2/C4E03AQFW7wg7Snlpvw/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1593286633877?e=1740009600&v=beta&t=KFes14IeoY0nIco-oq2l_j7-X9hn_5YEaqVZ6hTTJC8",
            },
          },
          {
            id: 9,
            name: "OpenAI",
            logo: "https://logo.clearbit.com/openai.com",
            revenue: 121980,
            maker: {
              name: "Michael Williams",
              avatar:
                "https://media.licdn.com/dms/image/v2/C5603AQGI0z2ea28WiQ/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1516506635574?e=1740009600&v=beta&t=OsGxmLJcCdguCCbEfeuSRUC5V9ukpzSAztiGoHic5no",
            },
          },
          {
            id: 10,
            name: "Palantir",
            logo: "https://logo.clearbit.com/palantir.com",
            revenue: 120870,
            maker: {
              name: "Shimona Slomowitz",
              avatar:
                "https://media.licdn.com/dms/image/v2/C5603AQFT-5EeSk_qOw/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1631632351260?e=1740009600&v=beta&t=slPi9syNJn96Y87QSqj2prdLXYEbP-fWOyekZKpn7z8",
            },
          },
          {
            id: 11,
            name: "Stripe",
            logo: "https://logo.clearbit.com/stripe.com",
            revenue: 119650,
            maker: {
              name: "Vederofsky",
              avatar:
                "https://media.licdn.com/dms/image/v2/C5603AQH0L-pP0m5O2Q/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1630359744660?e=1740009600&v=beta&t=Iosw-Iv-1XRCHuWKxbptCtuJp8Kcn4bO4I_jjhiTRRg",
            },
          },
        ];

        setMonthlyData(monthlyResponse);
        setAllTimeData(monthlyResponse); // Replace with all-time data
        setLoading(false);
      } catch (error) {
        console.error("Error fetching leaderboard data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const renderLeaderboard = (data) => {
    if (loading) {
      return [1, 2, 3].map((id) => (
        <div key={id} className="w-full">
          <Skeleton height={60} />
        </div>
      ));
    }

    return (
      <div className="space-y-1 bg-[#1a1a1a] rounded-xl border border-[#D2B48C]/20">
        <TableHeader />
        {data.map((entry, index) => (
          <LeaderboardEntry key={entry.id} entry={entry} position={index + 1} />
        ))}
      </div>
    );
  };

  return (
    <div className="bg-[#0F0F0F]">
      <main className="min-h-screen max-lg:pb-16 max-w-7xl mx-auto max-lg:p-6 md:py-12 space-y-4 flex flex-col px-8">
        <Header />
        {/* Leaderboards Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 mt-0">
          {/* Monthly Leaderboard */}
          <div className="rounded-xl p-2">
            <div className="flex items-center mb-6">
              <h2 className="text-xl text-gray-200 font-bold">December</h2>
              <span className="relative flex ml-2 h-3 w-3">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-emerald-500 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-emerald-500"></span>
              </span>
            </div>
            {renderLeaderboard(monthlyData)}
          </div>

          {/* All-time Leaderboard */}
          <div className="rounded-xl p-2">
            <h2 className="text-xl text-gray-200 font-bold mb-6">All-time</h2>
            {renderLeaderboard(allTimeData)}
          </div>
        </div>
      </main>
    </div>
  );
}
