import {
    nextButtonStyle,
    textAnswerStyle,
    textDivStyle,
    questionStyle,
} from "../styles";

export function RequestAmount({ formData, handleChangeInput, nextStep }) {
    return (
        <>
            <div>
                {/* <label for="fundingAmountRequested" class={`${questionStyle}`}>
                    To start, how much money are you looking for?
                </label>
                <div class={`${textDivStyle}`}>
                    <input
                        type="text"
                        name="fundingAmountRequested"
                        placeholder="Enter funding amount"
                        aria-describedby="price-currency"
                        value={formData.fundingAmountRequested}
                        onChange={(event) => handleChangeInput(event)}
                        class={`${textAnswerStyle}`}
                    />
                </div> */}
                <div className="m-auto w-3/4">
                    <label htmlFor="price" className="block text-xl font-medium leading-6 text-gray-900 mt-6 mb-6">
                        To start, how much money are you looking for?
                    </label>
                    <div className="relative mt-2 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <span className="text-gray-500 sm:text-sm">$</span>
                        </div>
                        <input
                            type="text"
                            id="amount-requested"
                            className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="100,000.00"
                            aria-describedby="price-currency"
                            name="fundingAmountRequested"
                            value={formData.fundingAmountRequested}
                            onChange={(event) => {
                                // Allow only numbers and decimal point
                                const value = event.target.value.replace(/[^\d.]/g, '');
                                
                                // Ensure only one decimal point
                                const decimalCount = (value.match(/\./g) || []).length;
                                if (decimalCount > 1) return;
                                
                                // Format with commas for thousands
                                const parts = value.split('.');
                                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                const formattedValue = parts.join('.');
                                
                                handleChangeInput({
                                    target: {
                                        name: event.target.name,
                                        value: formattedValue
                                    }
                                });
                            }}
                        />
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <span className="text-gray-500 sm:text-sm" id="price-currency">
                                CAD
                            </span>
                        </div>
                    </div>
                </div>

                <button
                    type="button"
                    class={`${nextButtonStyle} mt-6`}
                    onClick={nextStep}
                    disabled={!formData.fundingAmountRequested}
                >
                    Next
                </button>
            </div>
        </>
    );
}
