import { MagnifyingGlassIcon, SparklesIcon } from "@heroicons/react/24/outline";
import { useState, Fragment, useEffect } from "react";
import { Listbox, Dialog, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { CheckIcon } from "@heroicons/react/20/solid";
import Airtable from "airtable";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { automationCards, getAutomationCards } from "./automationCards";
import { ShieldCheckIcon } from "@heroicons/react/24/outline";
import { useUser } from "@clerk/clerk-react";
import { ShareIcon } from "@heroicons/react/24/outline";
import AuthPromptModal from "./AuthPromptModal";
import Joyride, { STATUS } from "react-joyride";
import { syncAutomationsToAirtable } from "../utils/airtableSync";
import { useLocation } from "react-router-dom";
import { PencilIcon, PlusIcon } from "@heroicons/react/24/outline";

const base = new Airtable({
  apiKey: process.env.REACT_APP_AIR_KEY,
}).base(process.env.REACT_APP_AIR_BASEID);

// const STRIPE_DEPOSIT_LINK = 'https://buy.stripe.com/6oEdUAfk68WM6LS7sP';
const STRIPE_DEPOSIT_LINK = "https://buy.stripe.com/7sI03K3Bo1ukgms4gG";

const createSlug = (title) => {
  return title
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/(^-|-$)/g, "");
};

const AutomationDirectory = ({
  company,
  runTour = false,
  setRunTour = () => {},
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedCard, setSelectedCard] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showRequirementsForm, setShowRequirementsForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [allCards, setAllCards] = useState([]);
  const [filteredCards, setFilteredCards] = useState([]);
  const { isLoaded, isSignedIn, user } = useUser();
  const [urlParams] = useState(new URLSearchParams(window.location.search));
  const [selectedStage, setSelectedStage] = useState("all");
  const [heartedCards, setHeartedCards] = useState(() => {
    const saved = localStorage.getItem("heartedCards");
    return saved ? JSON.parse(saved) : [];
  });
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [expandedImage, setExpandedImage] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedCard, setEditedCard] = useState(null);

  // Add categories based on your automations
  const categories = [
    { id: "all", name: "All Categories" },
    { id: "sales_marketing", name: "Sales & Marketing" },
    { id: "onboarding", name: "Onboarding" },
    { id: "customer_success", name: "Customer Success" },
    { id: "project_management", name: "Project Management" },
    { id: "hr_recruiting", name: "HR & Recruiting" },
    { id: "finance_accounting", name: "Finance & Accounting" },
  ];

  const popularAutomations = [
    "Automatically process and categorize incoming emails",
    "Generate meeting summaries and action items using AI",
    "Automate data entry from documents and forms",
    "Schedule social media posts across platforms",
  ];

  // Update the useEffect for loading cards
  useEffect(() => {
    const loadCards = async () => {
      try {
        const cards = await getAutomationCards();
        // Parse requirements if they're stored as strings
        const parsedCards = cards.map(card => ({
          ...card,
          requirements: typeof card.requirements === 'string' 
            ? JSON.parse(card.requirements)
            : card.requirements || {},
          fields: {
            ...card.fields,
            requirements: typeof card.fields?.requirements === 'string'
              ? JSON.parse(card.fields.requirements)
              : card.fields?.requirements || {}
          }
        }));
        
        console.log("Initial cards loaded:", parsedCards.length);
        setAllCards(parsedCards);
        setFilteredCards(parsedCards);
      } catch (error) {
        console.error("Error loading cards:", error);
      }
    };
    loadCards();
  }, []);

  // Add this useEffect to handle initial load
  useEffect(() => {
    const automationSlug = urlParams.get("automation");
    if (automationSlug) {
      const matchingCard = allCards.find(
        (card) => createSlug(card.title) === automationSlug
      );
      console.log("Matching card:", matchingCard);
      if (matchingCard) {
        setSelectedCard(matchingCard);
        setIsModalOpen(true);
      }
    }
  }, []);

  const HeartButton = ({ card, isHearted, onHeart }) => {
    const [localHeartCount, setLocalHeartCount] = useState(() => {
      try {
        // Handle both string and array formats
        const heartedBy = typeof card.heartedBy === 'string' 
          ? JSON.parse(card.heartedBy) 
          : (card.heartedBy || []);
        return Array.isArray(heartedBy) ? heartedBy.length : 0;
      } catch (e) {
        console.error("Error parsing initial heartedBy:", e);
        return 0;
      }
    });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      try {
        // Handle both string and array formats
        const heartedBy = typeof card.heartedBy === 'string'
          ? JSON.parse(card.heartedBy)
          : (card.heartedBy || []);
        setLocalHeartCount(Array.isArray(heartedBy) ? heartedBy.length : 0);
      } catch (e) {
        console.error("Error parsing heartedBy update:", e);
      }
    }, [card.heartedBy]);

    const handleClick = async (e) => {
      e.stopPropagation();
      if (isLoading) return;

      setIsLoading(true);
      try {
        await onHeart(card);
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <div className="absolute top-2 right-2 flex items-center gap-1">
        <span
          className={`text-sm ${isHearted ? "text-red-500" : "text-gray-400"}`}
        >
          {localHeartCount}
        </span>
        <button
          onClick={handleClick}
          disabled={isLoading}
          className={`p-2 rounded-full transition-colors duration-200 ${
            isHearted
              ? "text-red-500 bg-red-50 hover:bg-red-100"
              : "text-gray-400 bg-white hover:bg-gray-50"
          }`}
        >
          <svg
            className="w-5 h-5"
            fill={isHearted ? "currentColor" : "none"}
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
            />
          </svg>
        </button>
      </div>
    );
  };

  const buySteps = [
    {
      id: "01",
      name: "Review Automation Details",
      description: "Review benefits",
      href: "#",
      status: "complete",
    },
    {
      id: "02",
      name: "Buy Now",
      description: "Input info & buy",
      href: "#",
      status: "current",
    },
    {
      id: "03",
      name: "Requirements & QA",
      description: "Watch it run",
      href: "#",
      status: "upcoming",
    },
    {
      id: "04",
      name: "Watch it Run",
      description: "Watch it run",
      href: "#",
      status: "upcoming",
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const stages = [
    { id: "all", name: "All" },
    { id: "ready", name: "Ready to Use" },
    { id: "coming_soon", name: "Coming Soon" },
    { id: "requested", name: "Requested" },
    { id: "my_list", name: "My List 📌" },
    { id: "popular", name: "Popular", deprecated: true },
    { id: "value_menu", name: "Value Menu 🍔🍟", deprecated: true },
    { id: "value_rank", name: "Value Rank ✨📈", deprecated: true },
    { id: "start_grow", name: "Start & Grow 🚀💼", deprecated: true },
  ];

  // Add this console log to debug our cards
  useEffect(() => {
    console.log(
      "All cards:",
      allCards.map((card) => ({
        title: card.title,
        stage: card.stage,
      }))
    );
  }, [allCards]);

  // Update filtered cards whenever search query or stage changes
  useEffect(() => {
    const filtered = allCards.filter((card) => {
      const query = searchQuery.toLowerCase().trim();

      // Apply stage filter first
      if (selectedStage !== "all") {
        // Handle My List filter
        if (selectedStage === "my_list") {
          try {
            // Check if the card has been hearted by the current user
            const heartedBy = typeof card.heartedBy === 'string' 
              ? JSON.parse(card.heartedBy) 
              : (card.heartedBy || []);
            if (!user?.id || !heartedBy.includes(user.id)) {
              return false;
            }
          } catch (error) {
            console.error("Error parsing heartedBy:", error);
            return false;
          }
        } else {
          // Handle other stage filters as before
          if (selectedStage === "ready" && card.stage !== "ready") return false;
          if (selectedStage === "coming_soon" && card.stage !== "coming_soon") return false;
          if (selectedStage === "requested" && card.stage !== "requested") return false;
        }
      }

      // If no search query, return all cards that passed the stage filter
      if (!query) return true;

      // Apply search filter to cards that passed the stage filter
      return (
        card.title?.toLowerCase().includes(query) ||
        card.description?.toLowerCase().includes(query) ||
        card.tools?.some((tool) => tool.toLowerCase().includes(query)) ||
        card.type?.toLowerCase().includes(query)
      );
    });

    setFilteredCards(filtered);
  }, [searchQuery, selectedStage, allCards, user?.id]);

  const handleCardClick = async (e, card) => {
    if (!isSignedIn) {
      setShowAuthModal(true);
      return;
    }

    // Keep existing CreatableSelect check
    if (
      e.target.closest(".react-select__control") ||
      e.target.closest(".react-select__menu")
    ) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    // Parse requirements before setting selected card
    const parsedCard = {
      ...card,
      requirements: typeof card.requirements === 'string' 
        ? JSON.parse(card.requirements)
        : card.requirements || {},
      fields: {
        ...card.fields,
        requirements: typeof card.fields?.requirements === 'string'
          ? JSON.parse(card.fields.requirements)
          : card.fields?.requirements || {}
      }
    };

    // Update URL with card slug
    const slug = createSlug(card.title);
    const newUrl = new URL(window.location);
    newUrl.searchParams.set("automation", slug);
    window.history.pushState({}, "", newUrl);

    // Get email from Clerk user
    const userEmail = user?.primaryEmailAddress?.emailAddress;

    if (!isLoaded || !isSignedIn || !userEmail) {
      console.error("User not loaded or not signed in");
      return;
    }

    try {
      // Create record in Airtable
      await base("automationRequests").create([
        {
          fields: {
            RequesterEmail: userEmail,
            PartnerEmail: card.partnerEmail || "spark@merchantgrowth.com",
            AutomationDescription: card.title,
            Status: "Interested",
            AutomationCategory: card.tools.join(", "),
            Savings: card.benefits ? card.benefits[0] : "",
            Budget: card.pricing?.setup || 5000,
            ClientName: company?.fields?.Name || "",
            Date: new Date().toISOString().split("T")[0],
            Type: "Automation",
            LoomVideoLink: card.promptUrl || "",
            TechnologiesUsed: card.tools.join(", "),
            Requirements: JSON.stringify(card.requirements || {}),
          },
        },
      ]);

      console.log("Created automation request record");
    } catch (error) {
      console.error("Error creating automation request:", error);
    }

    // Set the parsed card
    setSelectedCard(parsedCard);
    setIsModalOpen(true);
  };

  const handleBuyNow = (card) => {
    // Generate a unique payment ID
    const paymentId = `payment_${Date.now()}_${Math.random()
      .toString(36)
      .substr(2, 9)}`;

    // Store the payment info
    localStorage.setItem(
      "pendingAutomation",
      JSON.stringify({
        id: paymentId,
        title: card.title,
        website: new URLSearchParams(window.location.search).get("website"),
        company: new URLSearchParams(window.location.search).get("company"),
        timestamp: Date.now(),
      })
    );

    // Open Stripe payment in new tab with success_url
    window.open(
      `${STRIPE_DEPOSIT_LINK}?client_reference_id=${paymentId}&success_url=${window.location.origin}/automation-success`,
      "_blank"
    );
  };

  // Add this useEffect to poll for payment completion
  useEffect(() => {
    const handlePaymentMessage = (event) => {
      if (event.data.type === "PAYMENT_SUCCESS") {
        const card = automationCards.find(
          (c) => c.title === event.data.data.title
        );
        if (card) {
          setSelectedCard(card);
          setShowRequirementsForm(true);
        }
      }
    };

    window.addEventListener("message", handlePaymentMessage);
    return () => window.removeEventListener("message", handlePaymentMessage);
  }, []);

  // Keep the existing polling mechanism as a fallback
  useEffect(() => {
    const checkPaymentStatus = async () => {
      const pendingAutomation = localStorage.getItem("pendingAutomation");
      if (!pendingAutomation) return;

      const data = JSON.parse(pendingAutomation);
      const timestamp = data.timestamp;

      // If it's been more than 30 minutes, clear the pending automation
      if (Date.now() - timestamp > 30 * 60 * 1000) {
        localStorage.removeItem("pendingAutomation");
        return;
      }

      try {
        // Replace this URL with your API endpoint that checks Stripe payment status
        const response = await fetch("/api/check-payment-status");
        const { isComplete } = await response.json();

        if (isComplete) {
          const card = automationCards.find((c) => c.title === data.title);
          if (card) {
            setSelectedCard(card);
            setShowRequirementsForm(true);
          }
          localStorage.removeItem("pendingAutomation");
        }
      } catch (error) {
        console.error("Error checking payment status:", error);
      }
    };

    const pollInterval = setInterval(checkPaymentStatus, 5000);
    return () => clearInterval(pollInterval);
  }, []);

  // Updated form submit handler (removing the duplicate)
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Get email from Clerk user
    const userEmail = user?.primaryEmailAddress?.emailAddress;

    if (!isLoaded || !isSignedIn || !userEmail) {
      console.error("User not loaded or not signed in");
      return;
    }

    try {
      // Format the requirements responses
      const formattedRequirements = {
        fromClient:
          selectedCard.requirements.fromClient?.map((req) => ({
            name: req.name,
            value: formData[`client-${req.name}`] || "",
          })) || [],
        technical:
          selectedCard.requirements.technical?.map((req) => ({
            name: req.name,
            value: formData[`tech-${req.name}`] || "",
          })) || [],
      };

      // Create record in Airtable with the form responses
      await base("automationRequests").create([
        {
          fields: {
            RequesterEmail: userEmail,
            PartnerEmail:
              selectedCard.partnerEmail || "spark@merchantgrowth.com",
            AutomationDescription: selectedCard.title,
            Status: "Submitted",
            AutomationCategory: selectedCard.tools.join(", "),
            Savings: selectedCard.benefits ? selectedCard.benefits[0] : "",
            Budget: selectedCard.pricing?.setup || 5000,
            ClientName: company?.fields?.Name || "",
            Date: new Date().toISOString().split("T")[0],
            Type: "Automation",
            LoomVideoLink: selectedCard.promptUrl || "",
            TechnologiesUsed: selectedCard.tools.join(", "),
            Requirements: JSON.stringify(formattedRequirements), // Send the user's responses
            UserResponses: JSON.stringify(formData), // Also store raw form data as backup
          },
        },
      ]);

      console.log(
        "Form submitted successfully with data:",
        formattedRequirements
      );

      // Close the form and show success message
      setShowRequirementsForm(false);
      setIsModalOpen(false);
      // Optionally add a success toast/notification here
    } catch (error) {
      console.error("Error submitting form:", error);
      // Optionally add an error toast/notification here
    }
  };

  const handleModalClose = () => {
    const newUrl = new URL(window.location);
    newUrl.searchParams.delete("automation");
    window.history.pushState({}, "", newUrl);
    setIsModalOpen(false);
  };

  const getShareableLink = (card) => {
    const url = new URL(window.location);
    url.searchParams.set("automation", createSlug(card.title));
    return url.toString();
  };

  const MAX_HEARTS = 3000; // Set a safety limit

  const handleHeartCard = async (card) => {
    const userId = user?.id;
    if (!userId) return;

    try {
      // Get current hearted users array
      const record = await base("automationsClickMinded").find(card.id);
      const heartedBy = JSON.parse(record.fields.HeartedBy || "[]");

      // Check if user already hearted
      const isHearted = heartedBy.includes(userId);

      // Update array
      const newHeartedBy = isHearted
        ? heartedBy.filter((id) => id !== userId)
        : [...heartedBy, userId];

      // Update Airtable
      const updatedRecord = await base("automationsClickMinded").update([
        {
          id: card.id,
          fields: {
            HeartedBy: JSON.stringify(newHeartedBy),
          },
        },
      ]);

      // Update the allCards state to reflect the new HeartedBy value
      setAllCards((prev) =>
        prev.map((c) =>
          c.id === card.id
            ? {
                ...c,
                heartedBy: JSON.stringify(newHeartedBy), // Update the heartedBy field
              }
            : c
        )
      );

      // Update local state for hearts
      setHeartedCards((prev) =>
        isHearted ? prev.filter((id) => id !== card.id) : [...prev, card.id]
      );

      localStorage.setItem("heartedCards", JSON.stringify(heartedCards));

      console.log("Updated card:", {
        id: card.id,
        heartedBy: newHeartedBy,
        count: newHeartedBy.length,
      });
    } catch (error) {
      console.error("Error updating hearts:", error);
    }
  };

  // Add this useEffect to monitor allCards changes
  useEffect(() => {
    console.log(
      "allCards updated:",
      allCards.map((card) => ({
        id: card.id,
        title: card.title,
        heartedBy: card.heartedBy,
      }))
    );
  }, [allCards]);

  // Update the stage selection buttons to force a refresh
  const handleStageChange = (newStage) => {
    setSelectedStage(newStage);
    // Don't clear the search query anymore
    // Don't clear allCards anymore - let the filter handle it
  };

  // Add this near the top of the component where showRequirementsForm is used
  useEffect(() => {
    console.log("showRequirementsForm state:", showRequirementsForm);
  }, [showRequirementsForm]);

  // Add this where selectedCard is set
  useEffect(() => {
    console.log("Selected card data:", selectedCard);
    console.log(
      "Selected card requirements:",
      selectedCard?.requirements?.fromClient
    );
    console.log(
      "Selected card fields requirements:",
      selectedCard?.fields?.requirements?.fromClient
    );
  }, [selectedCard]);

  // Add this state to store the form requirements JSON
  const [formRequirements, setFormRequirements] = useState({
    fromClient: [
      {
        name: "User ID",
        example: "user123",
      },
      {
        name: "User Input Data",
        example: "text example",
      },
    ],
  });

  // Add this function to update the requirements
  const updateFormRequirements = (newRequirements) => {
    try {
      // If it's a string, parse it, otherwise use the object directly
      const parsedReqs =
        typeof newRequirements === "string"
          ? JSON.parse(newRequirements)
          : newRequirements;

      setFormRequirements(parsedReqs);
    } catch (e) {
      console.error("Failed to parse requirements:", e);
    }
  };

  // Simple search handler
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Add this near your other state declarations
  const [debug, setDebug] = useState({
    totalCards: 0,
    filteredCards: 0,
    searchQuery: "",
    stage: "",
    category: "",
  });

  // Add this filter function
  const getFilteredCards = () => {
    if (!searchQuery) return allCards;

    const query = searchQuery.toLowerCase().trim();
    console.log("Filtering for:", query);

    return allCards.filter((card) => card.title.toLowerCase().includes(query));
  };

  const steps = [
    {
      target: ".search-bar",
      content:
        "Describe what you want to automate in plain English, or search through our existing automations",
      placement: "bottom",
    },
    {
      target: ".category-selector",
      content: "Filter automations by category to find exactly what you need",
      placement: "bottom",
    },
    {
      target: ".filters-section",
      content:
        "Quick filters to show popular, ready-to-use, or upcoming automations",
      placement: "bottom",
    },
    {
      target: ".automation-type",
      content: "See the type of automation and which tools it uses",
      placement: "top",
    },
    {
      target: ".heart-button",
      content: "Heart your favorite automations to save them to your list",
      placement: "left",
    },
    {
      target: ".pricing-info",
      content: "View the expected monthly benefit and cost of the automation",
      placement: "top",
    },
    {
      target: ".status-badge",
      content:
        "Check if the automation is ready to use, coming soon, or has been requested by others",
      placement: "left",
    },
  ];

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) && setRunTour) {
      setRunTour(false);
    }
  };

  const handleSync = async () => {
    try {
      const result = await syncAutomationsToAirtable(automationCards);
      if (result.success) {
        alert(`Sync successful! ${result.message}`);
      } else {
        alert(`Sync failed: ${result.error}`);
      }
    } catch (error) {
      console.error("Error during sync:", error);
      alert("Failed to sync automations to Airtable");
    }
  };

  const location = useLocation();
  const isAutomatePage = location.pathname === "/automate-my-work";

  // Add this function to handle saving all edits
  const handleSaveEdits = async () => {
    try {
      await base("automationsClickMinded").update([
        {
          id: editedCard.id,
          fields: {
            Title: editedCard.title,
            Benefits: JSON.stringify(editedCard.benefits),
            BestFor: JSON.stringify(editedCard.bestFor),
            NotGoodFor: JSON.stringify(editedCard.notGoodFor),
            Deliverables: JSON.stringify(editedCard.deliverables),
            SetupPrice: editedCard.SetupPrice,
            MonthlyPrice: editedCard.MonthlyPrice,
            TurnaroundDays: editedCard.turnaroundDays,
          },
        },
      ]);

      setAllCards(prev =>
        prev.map(card => card.id === editedCard.id ? editedCard : card)
      );
      setSelectedCard(editedCard);
      setIsEditing(false);
    } catch (error) {
      console.error("Error saving edits:", error);
      alert("Failed to save changes");
    }
  };

  // Add this function to handle field updates
  const handleFieldUpdate = (field, value, index = null, subfield = null) => {
    setEditedCard(prev => {
      const newCard = { ...prev };
      
      if (index !== null && Array.isArray(newCard[field])) {
        // Handling array updates (like bestFor, benefits, etc.)
        const newArray = [...newCard[field]];
        if (subfield) {
          newArray[index] = { ...newArray[index], [subfield]: value };
        } else {
          newArray[index] = value;
        }
        newCard[field] = newArray;
      } else {
        // Handling direct field updates
        newCard[field] = value;
      }
      
      return newCard;
    });
  };

  // Add this function to handle adding new items to arrays
  const handleAddItem = (field) => {
    setEditedCard(prev => ({
      ...prev,
      [field]: [...(prev[field] || []), ""]
    }));
  };

  // Add this function to handle removing items from arrays
  const handleRemoveItem = (field, index) => {
    setEditedCard(prev => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index)
    }));
  };

  return (
    <div className="h-full overflow-y-auto px-6 py-2">
      <Joyride
        steps={steps}
        run={runTour}
        continuous
        showProgress
        showSkipButton
        callback={handleJoyrideCallback}
        styles={{
          options: {
            primaryColor: "#44403c",
            zIndex: 1000,
          },
        }}
      />

      {/* <button
        onClick={() => setRunTour(true)}
        className="mb-4 inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-orange-500 rounded-md hover:bg-orange-600"
      >
        <svg className="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        Take a Tour
      </button> */}

      {/* FOR SYNCING TO AIRTABLE */}
      {/* <button
        onClick={handleSync}
        className="group inline-flex items-center px-4 py-2.5 text-sm font-medium text-stone-700 bg-transparent rounded-md hover:bg-stone-50 transition-colors duration-200 drop-shadow-md border-2 border-stone-700"
      >
        <svg
          className="w-4 h-4 mr-2"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          />
        </svg>
        Sync to Airtable
      </button> */}

      <h2 className="text-3xl font-bold text-gray-900 mb-6">
        What would you like to automate?
        <span className="block mt-2 text-sm font-medium md:font-semibold text-gray-400 text-[#615e5c] md:inline md:mt-0 md:ml-2">
          ({filteredCards.length} automations. You're on your way to a
          AI-powered, fully automated company.)
        </span>
      </h2>

      {/* Filter by category */}
      <div
        className={`${
          isAutomatePage ? "sticky bg-[#F5F0EA]" : ""
        } top-0 z-40 backdrop-blur-sm py-2 relative`}
      >
        <div className="relative mb-4">
          <div className="p-[5px] rounded-lg bg-gradient-to-r from-yellow-300 via-orange-500 to-purple-600 shadow-md">
            <div className="flex flex-col gap-2">
              {/* Search Input - always at the top */}
              <div className="relative flex-1">
                <SparklesIcon className="absolute left-3 top-1/2 -translate-y-1/2 h-7 w-7 text-gray-400 z-10" />
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="search-bar w-full py-[25px] pl-12 pr-4 text-sm md:text-xl font-light text-gray-900 bg-white rounded-md border-white focus:outline-none focus:ring-2 focus:ring-orange-500"
                  placeholder="Search AI & automations or describe what you'd like to automate..."
                />
              </div>

              {/* Category Selector - below search on mobile, next to it on desktop, test from arbri's branch*/}
              <div className="md:absolute md:right-[6px] md:top-[6px] md:w-48 w-full" style={{ zIndex: 1000 }}>
                <Listbox
                  value={selectedCategory}
                  onChange={setSelectedCategory}
                  className="category-selector relative"
                >
                  <div className="relative">
                    <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-6 pl-4 pr-10 text-left text-xl font-light text-gray-500 focus:outline-none border border-white hover:border-gray-300 transition-colors">
                      <span className="block truncate">
                        {
                          categories.find((cat) => cat.id === selectedCategory)
                            ?.name
                        }
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Listbox.Options className="absolute z-[1001] mt-1 max-h-58 w-full md:w-80 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                      {categories.map((category) => (
                        <Listbox.Option
                          key={category.id}
                          value={category.id}
                          className={({ active, selected }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                              active
                                ? "bg-violet-100 text-violet-900"
                                : "text-gray-900"
                            }`
                          }
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? "font-normal" : "font-normal"
                                }`}
                              >
                                {category.name}
                              </span>
                              {selected ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-violet-600">
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </div>
                </Listbox>
              </div>
            </div>
          </div>
        </div>

        {/* Stage selector */}
        <div className="relative">
          <div className="sm:overflow-x-auto sm:pb-2">
            {/* Mobile view: Horizontal scrollable row */}
            <div className="filters-section flex overflow-x-auto hide-scrollbar pb-2 sm:pb-0 -mx-6 px-6 sm:mx-0 sm:px-0">
              <div className="flex flex-row space-x-2 sm:space-x-3 mb-4">
                {stages.map((stage) => (
                  <button
                    key={stage.id}
                    onClick={() => handleStageChange(stage.id)}
                    disabled={stage.deprecated}
                    className={`
                      whitespace-nowrap px-3 py-1.5 rounded-lg shadow-sm flex-shrink-0
                      text-sm sm:text-base sm:px-4 sm:py-2 sm:shadow-md
                      ${
                        selectedStage === stage.id
                          ? "bg-violet-100 text-purple-700"
                          : stage.deprecated
                          ? "bg-gray-50 text-gray-400 opacity-50"
                          : "bg-white text-gray-700 hover:bg-gray-50"
                      }
                    `}
                  >
                    {stage.name}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Automation Cards */}
      <div
        key={`grid-${searchQuery}-${selectedStage}-${selectedCategory}-${Date.now()}`}
        className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4"
      >
        {filteredCards.map((card) => (
          <div
            key={`${card.id}-${card.title}`}
            className="relative h-full min-h-[190px]"
          >
            <div
              onClick={(e) => handleCardClick(e, card)}
              className={`flex flex-col p-6 border-2 rounded-lg h-full min-h-[190px] bg-white shadow-md ${
                card.promptUrl
                  ? "border-purple-200 hover:border-purple-500"
                  : "border-purple-200 hover:border-purple-500"
              } hover:shadow-lg transition-all duration-200 cursor-pointer`}
            >
              <div className="flex gap-2 mb-4 automation-type">
                {card.icons.map((icon, i) => (
                  <img key={i} src={icon} alt="" className="h-6 w-6" />
                ))}
                {card.type && (
                  <span className="text-xs text-purple-600 font-medium px-2 py-1 bg-purple-50 rounded-full">
                    {card.type}
                  </span>
                )}
              </div>

              <div className="space-y-4">
                <div>
                  <h3 className="text-sm text-gray-900">
                    {card.title ? (
                      card.title.split(":").map((part, i) =>
                        i === 0 ? (
                          <span key={i} className="font-bold">
                            {part}
                          </span>
                        ) : (
                          <span key={i} className="font-normal">
                            : {part}
                          </span>
                        )
                      )
                    ) : (
                      <span className="font-normal">Untitled Automation</span>
                    )}
                  </h3>
                  {/* <p className="text-sm text-gray-500 mt-1">
                    {card.tools.join(", ")}
                  </p> */}
                </div>
              </div>
            </div>
            {card.hearts}
            <HeartButton
              className="heart-button"
              card={card}
              isHearted={user?.id && (card.heartedBy || "[]").includes(user.id)}
              onHeart={handleHeartCard}
            />
            <div className="pricing-info absolute bottom-2 left-2 flex items-center gap-1">
              <span className="px-1.5 py-0.5 text-[10px] font-medium rounded-full bg-green-50 text-green-500">
                +${Math.floor(Math.random() * 901) + 100}/mo
              </span>
              <span className="px-1.5 py-0.5 text-[10px] font-medium rounded-full bg-red-50 text-red-500">
                -${((Math.floor(Math.random() * 901) + 100) / 10).toFixed(1)}/mo
              </span>
            </div>

            <div className="mt-10">
              <span className="status-badge absolute bottom-2 right-2 px-2 py-1 text-[10px] font-medium rounded-full bg-gray-100 text-gray-600">
                {card.stage === "coming_soon"
                  ? "Coming Soon"
                  : card.stage === "ready"
                  ? "Ready to Use"
                  : card.stage === "requested"
                  ? "Requested"
                  : "Ready to Use"}
              </span>
            </div>
          </div>
        ))}
      </div>

      {/* Modal for detailed information - buy now modal */}
      <Transition.Root show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={handleModalClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                  <div className="absolute right-0 top-0 pr-4">
                    {/* Edit/Save button */}
                    <button
                      type="button"
                      className={`rounded-md bg-white ${
                        isEditing ? 'text-green-600 hover:text-green-700' : 'text-gray-400 hover:text-gray-500'
                      }`}
                      onClick={() => {
                        if (isEditing) {
                          handleSaveEdits();
                        } else {
                          setEditedCard({...selectedCard});
                          setIsEditing(true);
                        }
                      }}
                    >
                      <span className="sr-only">{isEditing ? 'Save' : 'Edit'}</span>
                      {isEditing ? (
                        <CheckIcon className="h-6 w-6" aria-hidden="true" />
                      ) : (
                        <PencilIcon className="h-6 w-6" aria-hidden="true" />
                      )}
                    </button>
                    
                    {/* Existing share and close buttons */}
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          getShareableLink(selectedCard)
                        );
                        // Optionally show a toast notification
                      }}
                    >
                      <span className="sr-only">Share</span>
                      <ShareIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                      onClick={handleModalClose}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  {selectedCard && (
                    <div className="flex flex-col">
                      <div className="mt-3 w-full">
                        <Dialog.Title
                          as="div"
                          className="space-y-2 text-center"
                        >
                          {isEditing ? (
                            <input
                              type="text"
                              value={editedCard.title}
                              onChange={(e) => handleFieldUpdate('title', e.target.value)}
                              className="text-4xl font-bold tracking-tight text-center w-full border-b border-gray-300 focus:border-blue-500 outline-none"
                            />
                          ) : (
                            selectedCard.title.split(":").map((part, i) =>
                              i === 0 ? (
                                <h3 key={i} className="text-4xl font-bold tracking-tight bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 bg-clip-text text-transparent">
                                  {part}
                                </h3>
                              ) : (
                                <p key={i} className="text-xl text-gray-500 mt-2">
                                  {part}
                                </p>
                              )
                            )
                          )}
                        </Dialog.Title>
                        {selectedCard.exampleOutputs && (
                          <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                            {selectedCard.exampleOutputs.map((output, i) => (
                              <div key={i} className="relative cursor-pointer" onClick={() => setExpandedImage(output)}>
                                <img
                                  src={output.imageUrl}
                                  alt={output.description}
                                  className="w-full h-80 object-cover rounded-lg hover:opacity-90 transition-opacity"
                                />
                              </div>
                            ))}
                          </div>
                        )}
                        {/* Steps  */}
                        <nav aria-label="Progress">
                          <ol
                            role="list"
                            className="mt-4 space-y-4 md:flex md:space-x-8 md:space-y-0"
                          >
                            {buySteps.map((step) => (
                              <li key={step.name} className="md:flex-1">
                                {step.status === "complete" ? (
                                  <a
                                    href={step.href}
                                    className="group flex flex-col border-l-4 border-gray-600 pl-4 hover:border-gray-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-2"
                                  >
                                    <span className="text-sm font-medium text-gray-600 group-hover:text-gray-800">
                                      {step.id}
                                    </span>
                                    <span className="text-sm font-medium">
                                      {step.name}
                                    </span>
                                  </a>
                                ) : step.status === "current" ? (
                                  <a
                                    href={step.href}
                                    aria-current="step"
                                    className="flex flex-col border-l-4 border-gray-200 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-2"
                                  >
                                    <span className="text-sm font-medium text-gray-600">
                                      {step.id}
                                    </span>
                                    <span className="text-sm font-medium">
                                      {step.name}
                                    </span>
                                  </a>
                                ) : (
                                  <a
                                    href={step.href}
                                    className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-2"
                                  >
                                    <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                                      {step.id}
                                    </span>
                                    <span className="text-sm font-medium">
                                      {step.name}
                                    </span>
                                  </a>
                                )}
                              </li>
                            ))}
                          </ol>
                        </nav>

                        {/* Who's This For/Not For Sections */}
                        <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-6">
                          {/* Who's This Best For Section */}
                          <div>
                            <h4 className="text-sm font-semibold text-gray-900 mb-2">
                              Who's This Best For:
                            </h4>
                            <ul className="text-sm text-gray-600 space-y-1">
                              {(isEditing ? editedCard : selectedCard)?.bestFor?.map((item, i) => (
                                <li key={i} className="flex items-start">
                                  <svg className="h-4 w-4 text-green-500 mr-2 mt-0.5 flex-shrink-0">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                  </svg>
                                  {isEditing ? (
                                    <div className="flex-1 flex items-center">
                                      <input
                                        type="text"
                                        value={item}
                                        onChange={(e) => handleFieldUpdate('bestFor', e.target.value, i)}
                                        className="flex-1 bg-transparent border-b border-gray-300 focus:border-blue-500 outline-none"
                                      />
                                      <button
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveItem('bestFor', i);
                                        }}
                                        className="ml-2 text-red-500 hover:text-red-700"
                                      >
                                        <XMarkIcon className="h-4 w-4" />
                                      </button>
                                    </div>
                                  ) : (
                                    <span>{item}</span>
                                  )}
                                </li>
                              ))}
                              {isEditing && (
                                <li>
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleAddItem('bestFor');
                                    }}
                                    className="text-blue-500 hover:text-blue-700 flex items-center"
                                  >
                                    <PlusIcon className="h-4 w-4 mr-1" />
                                    Add item
                                  </button>
                                </li>
                              )}
                            </ul>
                          </div>

                          {/* Who's This Not Good For Section */}
                          <div>
                            <h4 className="text-sm font-semibold text-gray-900 mb-2">
                              Who's This Not Good For:
                            </h4>
                            <ul className="text-sm text-gray-600 space-y-1">
                              {(isEditing ? editedCard : selectedCard)?.notGoodFor?.map((item, i) => (
                                <li key={i} className="flex items-start">
                                  <svg className="h-4 w-4 text-red-500 mr-2 mt-0.5 flex-shrink-0">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                  </svg>
                                  {isEditing ? (
                                    <div className="flex-1 flex items-center">
                                      <input
                                        type="text"
                                        value={item}
                                        onChange={(e) => handleFieldUpdate('notGoodFor', e.target.value, i)}
                                        className="flex-1 bg-transparent border-b border-gray-300 focus:border-blue-500 outline-none"
                                      />
                                      <button
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveItem('notGoodFor', i);
                                        }}
                                        className="ml-2 text-red-500 hover:text-red-700"
                                      >
                                        <XMarkIcon className="h-4 w-4" />
                                      </button>
                                    </div>
                                  ) : (
                                    <span>{item}</span>
                                  )}
                                </li>
                              ))}
                              {isEditing && (
                                <li>
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleAddItem('notGoodFor');
                                    }}
                                    className="text-blue-500 hover:text-blue-700 flex items-center"
                                  >
                                    <PlusIcon className="h-4 w-4 mr-1" />
                                    Add item
                                  </button>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>

                        {/* Benefits and Included Sections Side by Side */}
                        <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-6">
                          {/* Benefits Section */}
                          {selectedCard?.benefits && Array.isArray(selectedCard.benefits) && (
                            <div>
                              <h4 className="text-sm font-semibold text-gray-900 mb-2">
                                Benefits:
                              </h4>
                              <ul className="text-sm text-gray-600 space-y-1">
                                {selectedCard.benefits.map((benefit, i) => (
                                  <li key={i} className="flex items-start">
                                    <svg
                                      className="h-4 w-4 text-green-500"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                      />
                                    </svg>
                                    {isEditing ? (
                                      <div className="flex-1 flex items-center">
                                        <input
                                          type="text"
                                          value={benefit}
                                          onChange={(e) => handleFieldUpdate('benefits', e.target.value, i)}
                                          className="flex-1 bg-transparent border-b border-gray-300 focus:border-blue-500 outline-none"
                                        />
                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleRemoveItem('benefits', i);
                                          }}
                                          className="ml-2 text-red-500 hover:text-red-700"
                                        >
                                          <XMarkIcon className="h-4 w-4" />
                                        </button>
                                      </div>
                                    ) : (
                                      <span>{benefit}</span>
                                    )}
                                  </li>
                                ))}
                                {isEditing && (
                                  <li>
                                    <button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleAddItem('benefits');
                                      }}
                                      className="text-blue-500 hover:text-blue-700 flex items-center"
                                    >
                                      <PlusIcon className="h-4 w-4 mr-1" />
                                      Add item
                                    </button>
                                  </li>
                                )}
                              </ul>
                            </div>
                          )}

                          {/* What's Included Section */}
                          {selectedCard.deliverables && (
                            <div>
                              <h4 className="text-sm font-semibold text-gray-900 mb-2">
                                Included:
                              </h4>
                              <ul className="text-sm text-gray-600 space-y-1">
                                {selectedCard?.deliverables.map((item, i) => (
                                  <li key={i} className="flex items-start">
                                    <svg
                                      className="h-4 w-4 text-green-500"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                      />
                                    </svg>
                                    {isEditing ? (
                                      <div className="flex-1 flex items-center">
                                        <input
                                          type="text"
                                          value={item}
                                          onChange={(e) => handleFieldUpdate('deliverables', e.target.value, i)}
                                          className="flex-1 bg-transparent border-b border-gray-300 focus:border-blue-500 outline-none"
                                        />
                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleRemoveItem('deliverables', i);
                                          }}
                                          className="ml-2 text-red-500 hover:text-red-700"
                                        >
                                          <XMarkIcon className="h-4 w-4" />
                                        </button>
                                      </div>
                                    ) : (
                                      <span>{item}</span>
                                    )}
                                  </li>
                                ))}
                                {isEditing && (
                                  <li>
                                    <button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleAddItem('deliverables');
                                      }}
                                      className="text-blue-500 hover:text-blue-700 flex items-center"
                                    >
                                      <PlusIcon className="h-4 w-4 mr-1" />
                                      Add item
                                    </button>
                                  </li>
                                )}
                              </ul>
                            </div>
                          )}
                        </div>

                        {/* Pricing section */}
                        <div className="mt-4 border-t border-gray-200 pt-4">
                          <div className="flex justify-between items-start mb-2">
                            <div className="space-y-2">
                              {isEditing ? (
                                <div className="flex flex-col gap-2">
                                  <div className="flex items-center gap-2">
                                    <label className="text-sm text-gray-500">Setup Price:</label>
                                    <div className="flex items-center">
                                      <span className="text-sm text-gray-500 mr-1">$</span>
                                      <input
                                        type="number"
                                        value={editedCard.SetupPrice}
                                        onChange={(e) => handleFieldUpdate('SetupPrice', e.target.value)}
                                        className="text-sm font-semibold text-gray-900 w-24 border-b border-gray-300 focus:border-blue-500 outline-none"
                                      />
                                    </div>
                                  </div>
                                  <div className="flex items-center gap-2">
                                    <label className="text-sm text-gray-500">Monthly Price:</label>
                                    <div className="flex items-center">
                                      <span className="text-sm text-gray-500 mr-1">$</span>
                                      <input
                                        type="number"
                                        value={editedCard.MonthlyPrice}
                                        onChange={(e) => handleFieldUpdate('MonthlyPrice', e.target.value)}
                                        className="text-sm text-gray-500 w-24 border-b border-gray-300 focus:border-blue-500 outline-none"
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <p className="text-sm font-semibold text-gray-900 line-through">
                                    ${selectedCard.SetupPrice || 499} setup
                                  </p>
                                  <p className="text-sm text-gray-500">
                                    + ${selectedCard.MonthlyPrice || 100}/month
                                  </p>
                                </>
                              )}
                            </div>
                            <div className="flex items-center gap-2">
                              {isEditing ? (
                                <>
                                  <label className="text-sm text-gray-500">Setup Days:</label>
                                  <input
                                    type="number"
                                    value={editedCard.turnaroundDays}
                                    onChange={(e) => handleFieldUpdate('turnaroundDays', e.target.value)}
                                    className="text-sm text-gray-600 w-20 border-b border-gray-300 focus:border-blue-500 outline-none"
                                  />
                                </>
                              ) : (
                                <div className="text-sm text-gray-600">
                                  {selectedCard.turnaroundDays} day setup
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* Guarantee Section */}
                        <div className="flex items-center justify-between mb-4">
                          <div className="flex items-center gap-2">
                            <ShieldCheckIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            <span className="text-sm text-gray-500 font-medium">
                              30-day money-back guarantee (early access 14-day free trial)
                            </span>
                          </div>
                          <button
                            onClick={() => setShowRequirementsForm(true)}
                            className="text-sm text-orange-600 hover:text-orange-700"
                          >
                            Requirements Form
                          </button>
                        </div>

                        {/* Buy Now Button */}
                        <button
                          onClick={() => handleBuyNow(selectedCard)}
                          className="w-full bg-stone-800 text-white font-medium py-3 px-4 rounded-lg shadow-lg hover:bg-blue-700 active:bg-blue-800 transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        >
                          Buy Now
                        </button>

                        {/* Image Section */}
                        <Dialog.Title as="h3" className="mt-5 text-xl font-semibold leading-6 text-gray-900">
                          {selectedCard.imageUrl && (
                            <div>
                              <div className="text-sm font-semibold text-gray-900 mb-2">Examples:</div>
                              <div className="mt-4">
                                <img
                                  src={selectedCard.imageUrl}
                                  alt={selectedCard.title}
                                  className="w-full h-auto object-contain rounded-lg"
                                />
                              </div>
                            </div>
                          )}
                        </Dialog.Title>
                      </div>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Requirements Form Modal */}
      <Transition.Root show={showRequirementsForm} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={setShowRequirementsForm}
        >
          {console.log("Rendering requirements form modal")}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                  <div className="absolute right-0 top-0 pr-4">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                      onClick={() => setShowRequirementsForm(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-semibold leading-6 text-gray-900 mb-4"
                      >
                        Required Information
                      </Dialog.Title>

                      <form onSubmit={handleFormSubmit} className="space-y-6">
                        {(() => {
                          const requirements = selectedCard?.requirements || {};

                          return (
                            <>
                              {/* Client Requirements Section */}
                              {requirements.fromClient &&
                                requirements.fromClient.length > 0 && (
                                  <div className="mb-8">
                                    <h4 className="text-sm font-semibold text-gray-900 mb-4">
                                      Client Requirements
                                    </h4>
                                    {requirements.fromClient.map(
                                      (requirement) => (
                                        <div
                                          key={requirement.name}
                                          className="space-y-2 mb-4"
                                        >
                                          <label
                                            htmlFor={`client-${requirement.name}`}
                                            className="block text-sm font-medium text-gray-900"
                                          >
                                            {requirement.name}
                                            <span className="text-red-500">
                                              *
                                            </span>
                                          </label>
                                          <div className="relative">
                                            <textarea
                                              id={`client-${requirement.name}`}
                                              name={`client-${requirement.name}`}
                                              value={
                                                formData[
                                                  `client-${requirement.name}`
                                                ] || ""
                                              }
                                              onChange={(e) =>
                                                setFormData((prev) => ({
                                                  ...prev,
                                                  [`client-${requirement.name}`]:
                                                    e.target.value,
                                                }))
                                              }
                                              placeholder={requirement.example}
                                              rows={2}
                                              className="block w-full rounded-md border-gray-300 shadow-sm 
                                                    focus:border-orange-500 focus:ring-orange-500 
                                                    transition-colors placeholder:whitespace-pre-wrap"
                                              required
                                            />
                                            {formErrors[
                                              `client-${requirement.name}`
                                            ] && (
                                              <div className="text-red-500 text-xs mt-1">
                                                {
                                                  formErrors[
                                                    `client-${requirement.name}`
                                                  ]
                                                }
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                )}

                              {/* Technical Requirements Section */}
                              {requirements.technical &&
                                requirements.technical.length > 0 && (
                                  <div>
                                    <h4 className="text-sm font-semibold text-gray-900 mb-4">
                                      Technical Requirements
                                    </h4>
                                    {requirements.technical.map(
                                      (requirement) => (
                                        <div
                                          key={requirement.name}
                                          className="space-y-2 mb-4"
                                        >
                                          <label
                                            htmlFor={`tech-${requirement.name}`}
                                            className="block text-sm font-medium text-gray-900"
                                          >
                                            {requirement.name}
                                            <span className="text-red-500">
                                              *
                                            </span>
                                          </label>
                                          <div className="relative">
                                            <textarea
                                              id={`tech-${requirement.name}`}
                                              name={`tech-${requirement.name}`}
                                              value={
                                                formData[
                                                  `tech-${requirement.name}`
                                                ] || ""
                                              }
                                              onChange={(e) =>
                                                setFormData((prev) => ({
                                                  ...prev,
                                                  [`tech-${requirement.name}`]:
                                                    e.target.value,
                                                }))
                                              }
                                              placeholder={requirement.example}
                                              rows={2}
                                              className="block w-full rounded-md border-gray-300 shadow-sm 
                                                    focus:border-orange-500 focus:ring-orange-500 
                                                    transition-colors placeholder:whitespace-pre-wrap"
                                              required
                                            />
                                            {formErrors[
                                              `tech-${requirement.name}`
                                            ] && (
                                              <div className="text-red-500 text-xs mt-1">
                                                {
                                                  formErrors[
                                                    `tech-${requirement.name}`
                                                  ]
                                                }
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                )}
                            </>
                          );
                        })()}

                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                          <button
                            type="submit"
                            className="inline-flex w-full justify-center rounded-md bg-gradient-to-r from-yellow-400 via-orange-500 to-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:from-yellow-500 hover:via-orange-600 hover:to-purple-700 sm:ml-3 sm:w-auto"
                          >
                            Start Automation
                          </button>
                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            onClick={() => setShowRequirementsForm(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <AuthPromptModal isOpen={showAuthModal} setIsOpen={setShowAuthModal} />

      {/* Add this CSS to your stylesheet */}
      <style jsx>{`
        .hide-scrollbar {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
        .hide-scrollbar::-webkit-scrollbar {
          display: none;
        }
      `}</style>

      {/* Image Expansion Modal */}
      <Transition.Root show={!!expandedImage} as={Fragment}>
        <Dialog as="div" className="relative z-[60]" onClose={() => setExpandedImage(null)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative transform rounded-lg bg-white shadow-xl transition-all max-w-[90vw] max-h-[90vh]">
                  <div className="absolute right-0 top-0 pr-4 pt-4 z-10">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                      onClick={() => setExpandedImage(null)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  {expandedImage && (
                    <img
                      src={expandedImage.imageUrl}
                      alt={expandedImage.description}
                      className="w-full h-full object-contain rounded-lg"
                    />
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default AutomationDirectory;
