import Airtable from 'airtable';

const base = new Airtable({
  apiKey: process.env.REACT_APP_AIR_KEY,
}).base(process.env.REACT_APP_AIR_BASEID);

export const syncAutomationsToAirtable = async (automationCards) => {
  try {
    // Get existing records to avoid duplicates
    const existingRecords = await base('automationsClickMinded')
      .select({
        fields: ['Title']
      })
      .all();
    
    const existingTitles = new Set(existingRecords.map(record => record.get('Title')));
    
    // Prepare records for creation
    const recordsToCreate = automationCards
      .filter(card => !existingTitles.has(card.title))
      .map(card => ({
        fields: {
          Title: card.title,
          Type: card.type || 'automation',
          Stage: card.stage || 'coming_soon',
          Tools: JSON.stringify(card.tools || []),
          HeartedBy: card.heartedBy || '[]',
          StripeLink: card.stripeLink || '',
          TurnaroundDays: card.turnaroundDays || 0,
          
          // Pricing
          SetupPrice: card.pricing?.setup || 0,
          MonthlyPrice: card.pricing?.monthly || 0,
          AdditionalCosts: JSON.stringify(card.pricing || {}),
          
          // Content
          Benefits: JSON.stringify(card.benefits || []),
          IncludedFeatures: JSON.stringify(card.included || []),
          BestPractices: JSON.stringify(card.bestPractices || []),
          
          // Requirements (combined)
          requirements: JSON.stringify(card.requirements || {}),
          
          // Workflow (combined)
          WorkflowDetails: JSON.stringify({
            stages: card.workflow || [],
            maintenance: card.maintenance || {},
            metrics: card.metrics || {}
          }),
          
          // Optional
          ExampleOutputs: JSON.stringify(card.exampleOutputs || []),
          AutomationRecipes: JSON.stringify(card.automationRecipes || {}),
          Deliverables: JSON.stringify(card.deliverables || []),
          SupportDetails: JSON.stringify(card.support || {}),
          Integrations: JSON.stringify(card.integrations || []),
          ComplianceItems: JSON.stringify(card.compliance || [])
        }
      }));

    // Create records in batches of 10
    for (let i = 0; i < recordsToCreate.length; i += 10) {
      const batch = recordsToCreate.slice(i, i + 10);
      await base('automationsClickMinded').create(batch);
    }

    return {
      success: true,
      created: recordsToCreate.length,
      message: `Successfully synced ${recordsToCreate.length} new automations`
    };
  } catch (error) {
    console.error('Error syncing to Airtable:', error);
    return {
      success: false,
      error: error.message
    };
  }
}; 