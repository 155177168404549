import React, { useState, useEffect } from "react";
import Notification from "./Notification";
import useBase from "./useBase";
import { useSignUp, useSignIn } from "@clerk/clerk-react";
import {
	checkValidDomainName,
	checkEmail,
	extractDomain,
	setContents,
	setShowNotif,
	includesSpecialCharacters,
	containsNumber,
} from "./helpers";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { StarIcon } from '@heroicons/react/20/solid'
import { Tooltip } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { Carousel } from 'flowbite';


const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;
const usersTableId = process.env.REACT_APP_AIR_USERS;
const specialCharacters = `\!"#$%&'()*+,-./:;<=>?@[\]^_\{|}~`;



function SignUpPage() {
	const { isLoaded: isSignUpLoaded, signUp, setActive } = useSignUp();
	const { loading: usersLoading, data: users, error: usersError } = useBase("users");
	const [isFocused, setIsFocused] = useState(false);
	const navigate = useNavigate();

	const [userEmail, setUserEmail] = useState("");
	const [userPassword, setUserPassword] = useState("");
	const [confirmUserPassword, setConfirmUserPassword] = useState("");
	const [pendingVerification, setPendingVerification] = useState(false);
	const [showSignUpNotification, setShowSignUpNotification] = useState({
		showNotif: false,
		contents: { type: "", heading: "", message: "" },
	});
	const [passwordRequirements, setPasswordRequirements] = useState({
		minimumLength: {
			message: "Minimum password length of 8",
			fulfilled: false,
		},
		specialChar: {
			message: `At least 1 special character from: ${specialCharacters}`,
			fulfilled: false,
		},
		number: {
			message: "At least 1 number",
			fulfilled: false,
		},
		// confirmPasswordMatches: {
		// 	message: "Passwords match",
		// 	fulfilled: false,
		// },
	});

	const errorInSignUpFields = () => {
		if (checkEmail(userEmail) === false && !userEmail.includes("+clerk_test")) {
			let contents = { type: "error", heading: "Invalid Email Address", message: "Please enter a valid email address." };
			setContents(contents, setShowSignUpNotification);
			setShowNotif(true, setShowSignUpNotification);

			return true;
		}

		const companyDomain = extractDomain(userEmail);
		let output = checkValidDomainName(companyDomain);
		if (output.result !== "Success") {
			let contents = { type: "error", heading: "Invalid Domain", message: output.message };
			setContents(contents, setShowSignUpNotification);
			setShowNotif(true, setShowSignUpNotification);

			return true;
		}

		const invalidServiceProviders = [
			"gmail.com",
			"outlook.com",
			"outlook.live.com",
			"hotmail.com",
			"yahoo.com",
			"yahoo.co.ca",
			"yahoo.ca",
			"myyahoo.com",
		];

		if (invalidServiceProviders.includes(extractDomain(userEmail).toLowerCase())) {
			let contents = {
				type: "error",
				heading: "Invalid Email Address",
				message: `Please use your corporate email address associated with ${companyDomain}.`,
			};
			setContents(contents, setShowSignUpNotification);
			setShowNotif(true, setShowSignUpNotification);

			return true;
		}

		if (!extractDomain(userEmail) || extractDomain(userEmail) !== companyDomain) {
			let contents = {
				type: "error",
				heading: "Invalid Email Address",
				message: `Please use your corporate email address associated with ${companyDomain}.`,
			};
			setContents(contents, setShowSignUpNotification);
			setShowNotif(true, setShowSignUpNotification);

			return true;
		}

		const usersWithSameEmail = users.filter((user) => user?.fields?.Email === userEmail);
		if (usersWithSameEmail.length !== 0) {
			let contents = {
				type: "error",
				heading: "Email is Already Signed Up",
				message: `Please sign-in with ${userEmail} or click forgot password.`,
			};
			setContents(contents, setShowSignUpNotification);
			setShowNotif(true, setShowSignUpNotification);

			return true;
		}

		if (
			Object.keys(passwordRequirements)
				.map((key) => passwordRequirements[key].fulfilled)
				.includes(false)
		) {
			let contents = {
				type: "error",
				heading: "Invalid Password",
				message: `Please create a valid password.`,
			};
			setContents(contents, setShowSignUpNotification);
			setShowNotif(true, setShowSignUpNotification);

			return true;
		}

		return false;
	};

	const handleSignUp = async (event) => {
		event.preventDefault();
		if (!isSignUpLoaded) return;

		if (errorInSignUpFields()) return;

		try {
			await signUp.create({
				email_address: userEmail,
				password: userPassword,
			});

			await signUp.prepareEmailAddressVerification({ strategy: "email_code" });

			setPendingVerification(true);
		} catch (e) {
			let contents = {
				type: "error",
				heading: "Invalid Sign-Up",
				message: `There was an error in signing-up, please try again later.`,
			};
			setContents(contents, setShowSignUpNotification);
			setShowNotif(true, setShowSignUpNotification);
		}
	};

	const onPressVerify = async (code) => {
		if (!isSignUpLoaded) return;

		try {
			const completeSignUp = await signUp.attemptEmailAddressVerification({
				code: code,
			});

			const companyDomain = extractDomain(userEmail);

			if (completeSignUp.status !== "complete") {
				console.log(JSON.stringify(completeSignUp, null, 2));
			} else {
				await fetch("https://api.airtable.com/v0/" + baseId + "/" + usersTableId, {
					method: "POST",
					headers: {
						Authorization: `Bearer ${apiKey}`,
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						fields: {
							Email: userEmail,
							Domain: companyDomain,
						},
					}),
				});

				await setActive({ session: completeSignUp.createdSessionId });
				
				// Get redirect URL from query parameters
				const params = new URLSearchParams(window.location.search);
				const redirectUrl = params.get('redirect');
				
				// Navigate to redirect URL if present, otherwise use default
				navigate(redirectUrl || `/?website=${companyDomain}`);
			}
		} catch (error) {
			console.error("Error in Verifying Email: ", error);
			let contents = {
				type: "error",
				heading: "Unable to Verify",
				message: `Please make sure you're entering the correct code.`,
			};
			setContents(contents, setShowSignUpNotification);
			setShowNotif(true, setShowSignUpNotification);
		}
	};

	const changePasswordFulfilledValue = (field, value) => {
		setPasswordRequirements((prev) => ({ ...prev, [field]: { ...prev[field], fulfilled: value } }));
	};

	const onPasswordChange = (e) => {
		const password = e.target.value;

		setUserPassword(password);
		
		onConfirmPasswordChange(e);

		// Minimum Length Checks
		if (passwordRequirements.minimumLength.fulfilled === false && password.length >= 8) {
			changePasswordFulfilledValue("minimumLength", true);
		} else if (passwordRequirements.minimumLength.fulfilled === true && password.length < 8) {
			changePasswordFulfilledValue("minimumLength", false);
		}

		// Special Char Checks
		if (passwordRequirements.specialChar.fulfilled === false && includesSpecialCharacters(specialCharacters, password)) {
			changePasswordFulfilledValue("specialChar", true);
		} else if (passwordRequirements.specialChar.fulfilled === true && !includesSpecialCharacters(specialCharacters, password)) {
			changePasswordFulfilledValue("specialChar", false);
		}

		// Number Checks
		if (passwordRequirements.number.fulfilled === false && containsNumber(password)) {
			changePasswordFulfilledValue("number", true);
		} else if (passwordRequirements.number.fulfilled === true && !containsNumber(password)) {
			changePasswordFulfilledValue("number", false);
		}

		// // 'Confirm Password' matches 'Password' Checks
		// if (passwordRequirements.confirmPasswordMatches.fulfilled === false && password === confirmUserPassword) {
		// 	changePasswordFulfilledValue("confirmPasswordMatches", true);
		// } else if (passwordRequirements.confirmPasswordMatches.fulfilled === true && password !== confirmUserPassword) {
		// 	changePasswordFulfilledValue("confirmPasswordMatches", false);
		// }

	};

	const onConfirmPasswordChange = (event) => {
		const confirmPassword = event.target.value;

		setConfirmUserPassword(confirmPassword);

		// if (passwordRequirements.confirmPasswordMatches.fulfilled === false && confirmPassword === userPassword) {
		// 	changePasswordFulfilledValue("confirmPasswordMatches", true);
		// } else if (passwordRequirements.confirmPasswordMatches.fulfilled === true && confirmPassword !== userPassword) {
		// 	changePasswordFulfilledValue("confirmPasswordMatches", false);
		// }
	};

	const signUpWith = (strategy) => {
		if (!isSignUpLoaded) return;

		// Get redirect URL from query parameters
		const params = new URLSearchParams(window.location.search);
		const redirectUrl = params.get('redirect');

		signUp.authenticateWithRedirect({
			strategy,
			redirectUrl: '/sso-callback',
			redirectUrlComplete: redirectUrl || '/check_oauth_google',
		});
	};

	  useEffect(() => {
		const carouselElement = document.getElementById('carousel-testimonials');
		const items = [
					{
						position: 0,
						el: document.getElementById('carousel-indicator-0'),
					},
					{
						position: 1,
						el: document.getElementById('carousel-indicator-1'),
					},
					{
						position: 2,
						el: document.getElementById('carousel-indicator-2'),
					},
				];
			
		const options = {
			defaultPosition: 0,
			interval: 5000,

			indicators: {
				activeClasses: 'bg-gray-900 z-20',
				inactiveClasses:
					'bg-gray-900 z-10',
				items: [
					{
						position: 0,
						el: document.getElementById('carousel-indicator-0'),
					},
					{
						position: 1,
						el: document.getElementById('carousel-indicator-1'),
					},
					{
						position: 2,
						el: document.getElementById('carousel-indicator-2'),
					},
				],
			},

			onNext: () => {
			},
			onPrev: () => {
			},
			onChange: () => {
			},
		};

		const instanceOptions = {
			id: 'carousel-testimonials',
			override: true
		};

		const carousel = new Carousel(carouselElement, items, options, instanceOptions);
		carousel.cycle();
			}, []);

	const testimonials = [
		{
			stars: 5,
			text: "“Upgraded found us $64,000/yr in savings on our first payroll cycle - we had no revenue and only 2 employees. Magic”",
			author: "Matt Parson",
			title: "Founder, Benji",
			image: "https://assets-global.website-files.com/655000b5d6f9d81d7029fd25/65f0a34f691a72257fea9cfb_matt-parson-portrait-p-500.jpeg",
		},
		{
			stars: 5,
			text: "“We hired the best CPA in Vancouver to look for credits. Upgraded still blew them out of the water.”",
			author: "Hannah Simmons",
			title: "Founder, ERA Brazil",
			image: "https://assets-global.website-files.com/634f60226f66af42a384d5b7/6369789c70e85f8693c7c324_hannah-simmons-profile.jpeg",
		},
		{
			stars: 5,
			text: "“We raised $100M from the best VCs. Upgraded still found us credits nobody had ever mentioned.”",
			author: "David McMurchy",
			title: "Sales Director, i-Sight",
			image: "https://assets-global.website-files.com/655000b5d6f9d81d7029fd25/65f1325f2d57b8c9b95895ee_david-headshot-1.jpeg",
		},
		// Add more as needed
		];

	return (
		<body>
			<div className="flex min-h-full flex-1 h-screen overflow-hidden">
				<div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
					<div className="mx-auto w-full max-w-sm lg:w-96">
						<div>
							<img
								className="h-10 w-auto"
								src="https://i.imgur.com/PbiW6TE.png"
								alt="Upgraded Logo"
							/>
							<h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">Sign Up For Funding</h2>
							<p className="mt-2 text-sm leading-6 text-gray-500">
								Already a member?{"  "}
								<a href="/sign-in" className="font-semibold text-indigo-600 hover:text-indigo-500">
									Sign In Instead
								</a>
							</p>
						</div>

						<div className="mt-10">
							<div className="grid grid-cols-1 gap-4">
								<button 
									className="flex w-full items-center justify-center gap-3 rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  focus-visible:ring-transparent"
									onClick={() => {
										signUpWith("oauth_google")
									}}
								>
									<svg className="h-5 w-5" aria-hidden="true" viewBox="0 0 24 24">
										<path
											d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
											fill="#EA4335"
										/>
										<path
											d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
											fill="#4285F4"
										/>
										<path
											d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
											fill="#FBBC05"
										/>
										<path
											d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
											fill="#34A853"
										/>
									</svg>
									<span className="text-sm font-semibold leading-6">Connect with Google</span>
								</button>
								{/* </Tooltip> */}
							</div>
						</div>
						<div className="relative mt-3 mb-4">
							<div className="absolute inset-0 flex items-center" aria-hidden="true">
								<div className="w-full border-t border-gray-200" />
							</div>
							<div className="relative flex justify-center text-sm font-medium leading-6">
								<span className="bg-white px-3 text-gray-500">OR</span>
							</div>
						</div>

						<div className="mt-3">
							<div>
								{!pendingVerification ? (
									<div>
										
										<form onSubmit={handleSignUp} className="space-y-6">
											<div>
												<label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
													Work email address
												</label>
												<div className="mt-2">
													<input
														id="email"
														name="email"
														type="email"
														autoComplete="email"
														value={userEmail}
														onChange={(e) => setUserEmail(e.target.value)}
														required
														className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
													/>
												</div>
											</div>

											<div>
												<label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
													Password
												</label>
												<div className="mt-2">
													<input
														id="password"
														name="password"
														type="password"
														required
														value={userPassword}
														onChange={onPasswordChange}
														onFocus={() => setIsFocused(true)}
														className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
													/>
												</div>
											</div>

											{/* <div>
												<label htmlFor="confirmPassword" className="block text-sm font-medium leading-6 text-gray-900">
													Confirm Password
												</label>
												<div className="mt-2">
													<input
														id="confirmPassword"
														name="confirmPassword"
														type="password"
														required
														autoComplete="new-password"
														value={confirmUserPassword}
														onChange={onConfirmPasswordChange}
														onFocus={() => setIsFocused(true)}
														className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
													/>
												</div>
											</div> */}

											{isFocused && <div className="grid grid-rows-3 mt-1 px-1">
												{Object.keys(passwordRequirements).map((key) => (
													<div key={key} className="inline-flex mt-1 space-x-1">
														<div className="flex text-center items-center mb-2 justify-center">
															{passwordRequirements[key].fulfilled ? (
																<CheckCircleIcon className="text-green-400 h-4 w-4" aria-hidden />
															) : (
																<XCircleIcon className="text-gray-400 h-4 w-4" aria-hidden />
															)}
														</div>
														<p className="mt-1 text-xs text-left">{passwordRequirements[key].message}</p>
													</div>
												))}
											</div>}

											<div>
												<button
													type="submit"
													className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
												>
													Create Account
												</button>
											</div>
										</form>
										<br />
										<span className="text-gray-500 text-sm">By signing up, you acknowledge that you have read and understood, and agree to Upgraded’s <a href="https://www.getupgraded.ca/terms-of-service" className="underline text-indigo-700" target="_blank">Terms</a> and <a href="https://www.getupgraded.ca/privacy-policy" className="underline text-indigo-700" target="_blank">Privacy Policy.</a></span>
									</div>
								) : (
									<form
										onSubmit={(e) => {
											e.preventDefault();
											const code = e.target["verificationCode"].value;
											onPressVerify(code);
										}}
									>
										<div>
											<label className="block text-sm font-medium leading-6 text-gray-900">Verify your Email</label>
											<p className="block text-xs font-small leading-6 text-gray-900">Enter the code sent to {userEmail}</p>
											<div className="mt-2">
												<input
													id="verificationCode"
													name="verificationCode"
													type="number"
													required
													autoComplete="one-time-code"
													className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
												/>
											</div>
										</div>
										<button
											className="mt-10 flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
											type="submit"
										>
											Submit
										</button>
									</form>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="relative hidden w-0 flex-1 lg:block my-auto py-auto bg-gray-900 h-screen overflow-hidden">
					<section className="px-6 py-24 sm:py-32 lg:px-8 bg-gray-900">
						<figure className="mx-auto max-w-2xl">
							<div id="carousel-testimonials" className="relative w-full bg-gray-900 mb-8 z-50" data-carousel="slide">
								<div className="relative h-56 bg-gray-900 overflow-hidden rounded-lg sm:h-64 xl:h-80 2xl:h-96">
									{testimonials.map((testimonial, i) => (
										<div key={i} className="hidden bg-gray-900 duration-700 ease-in-out" id={`carousel-indicator-${i}`}>
											<p className="sr-only">{testimonial.stars} out of 5 stars</p>
											<div className="flex gap-x-1 pt-6 pl-6 text-indigo-600">
												{Array.from({ length: testimonial.stars }).map((_, starIndex) => (
													<StarIcon key={starIndex} className="h-5 w-5 flex-none" aria-hidden="true" />
												))}
											</div>
											<blockquote className="mt-10 text-8xl pl-6 leading-8 tracking-tight text-white sm:text-4xl sm:leading-9">
												<p>{testimonial.text}</p>
											</blockquote>
											<figcaption className="mt-10 flex pl-6 items-center gap-x-6">
												<img
													className="h-12 w-12 rounded-full bg-gray-900"
													src={testimonial.image}
													alt={testimonial.author}
												/>
												<div className="text-sm leading-6">
													<div className="font-semibold pl-1 text-white">{testimonial.author}</div>
													<div className="mt-0.5 pl-1 text-gray-200">{testimonial.title}</div>
												</div>
											</figcaption>
										</div>
									))}
								</div>
							</div>
							<div className="flex justify-center pr-3 mt-8 space-x-4 sm:space-x-6 flex-wrap"> 
								<div className="flex flex-col items-center p-4 sm:p-6 rounded-lg shadow-md max-w-sm mx-auto"> {/* Added max-w-sm and mx-auto */}
									<div className="text-2xl sm:text-4xl font-bold text-white">+$104,803</div> 
									<div className="mt-2 text-gray-200 text-center text-sm sm:text-base">Saved on Average</div> 
								</div>
								<div className="flex flex-col items-center p-4 sm:p-6 rounded-lg shadow-md max-w-sm mx-auto"> {/* Added max-w-sm and mx-auto */}
									<div className="text-2xl sm:text-4xl font-bold text-white">+3,600</div> 
									<div className="mt-2 text-gray-200 text-center text-sm sm:text-base">Grants Available</div>
								</div>
								<div className="flex flex-col items-center p-4 sm:p-6 rounded-lg shadow-md max-w-sm mx-auto"> {/* Added max-w-sm and mx-auto */}
									<div className="text-2xl sm:text-4xl font-bold text-white">+$2.9B</div> 
									<div className="mt-2 text-gray-200 text-center text-sm sm:text-base">Funding Available</div> 
								</div>
							</div>
						</figure>
					</section>
				</div>
			</div>
			<Notification
				showNotif={showSignUpNotification.showNotif}
				setShowNotif={(val) => setShowNotif(val, setShowSignUpNotification)}
				contents={showSignUpNotification.contents}
			/>
		</body>
	);
}

export default SignUpPage;
