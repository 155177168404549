"use client";

import { useState, useEffect, Fragment } from "react";
import AutomationDirectory from "../components/AutomationDirectory";
import { useUser } from "@clerk/clerk-react";
import { PlayIcon } from "@heroicons/react/24/solid";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ReactTyped as Typed } from "react-typed";
import Airtable from "airtable";

const base = new Airtable({
  apiKey: process.env.REACT_APP_AIR_KEY,
}).base(process.env.REACT_APP_AIR_BASEID);

const AuthButtons = () => {
  const { isSignedIn, user } = useUser();

  const getDomainFromEmail = (email) => {
    return email?.split("@")[1] || "";
  };

  if (isSignedIn) {
    return (
      <div className="flex flex-row gap-4 max-lg:justify-center max-lg:mb-4">
        <div className="space-y-0.5 -mt-[16px]">
          <div className="text-xs text-gray-500 max-lg:hidden">
            Welcome back!
          </div>
          <div className="flex gap-2">
            <a
              href="/public-leaderboard"
              className="bg-white flex items-center group justify-center w-[42px] rounded-lg border border-gray-200 transition-all duration-150 ease-in-out hover:bg-gray-50"
              title="Leaderboard"
            >
              <span className="text-2xl group-hover:scale-110 duration-150">🏆</span>
            </a>
            <a
              className="bg-white flex items-center group justify-start gap-2 rounded-lg border border-gray-200 w-42 px-3 py-2 transition-all duration-150 ease-in-out hover:bg-gray-50"
              href={`/?website=${getDomainFromEmail(
                user?.primaryEmailAddress?.emailAddress
              )}`}
            >
              <div className="flex items-center space-x-2">
                {user?.imageUrl ? (
                  <img
                    src={user.imageUrl}
                    alt="Profile"
                    className="w-[20px] h-[20px] rounded-full group-hover:scale-125 duration-150 ease-in-out"
                  />
                ) : (
                  <svg
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="w-[20px] h-[20px] text-yellow-400 group-hover:scale-125 duration-150 ease-in-out"
                  >
                    <circle cx="12" cy="8" r="5" />
                    <path d="M20 21a8 8 0 0 0-16 0" />
                  </svg>
                )}
                <span className="font-medium text-gray-900 text-sm">
                  My Dashboard
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-row gap-2 max-lg:justify-center -mt-4">
      <div className="space-y-0.5">
        <div className="text-xs text-gray-500 max-lg:text-center">
          New here?
        </div>
        <div className="flex gap-2">
          <a
            href="/public-leaderboard"
            className="bg-white flex items-center group justify-center w-[42px] rounded-lg border border-gray-200 transition-all duration-150 ease-in-out hover:bg-gray-50"
            title="Leaderboard"
          >
            <span className="text-2xl group-hover:scale-110 duration-150">🏆</span>
          </a>
          <a
            className="bg-white flex items-center group justify-start gap-2 rounded-lg border border-gray-200 w-42 px-3 py-2 transition-all duration-150 ease-in-out hover:bg-gray-50"
            href="/sign-up?redirect=/automate-my-work"
          >
            <div className="flex items-center space-x-2">
              <svg
                className="w-[20px] h-[20px] fill-yellow-400 group-hover:scale-125 group-hover:rotate-3 duration-150 ease-in-out"
                viewBox="0 0 375 509"
              >
                <path d="M249.685 14.125C249.685 11.5046 248.913 8.94218 247.465 6.75675C246.017 4.57133 243.957 2.85951 241.542 1.83453C239.126 0.809546 236.463 0.516683 233.882 0.992419C231.301 1.46815 228.917 2.69147 227.028 4.50999L179.466 50.1812C108.664 118.158 48.8369 196.677 2.11373 282.944C0.964078 284.975 0.367442 287.272 0.38324 289.605C0.399039 291.938 1.02672 294.226 2.20377 296.241C3.38082 298.257 5.06616 299.929 7.09195 301.092C9.11775 302.255 11.4133 302.867 13.75 302.869H129.042V494.875C129.039 497.466 129.791 500.001 131.205 502.173C132.62 504.345 134.637 506.059 137.01 507.106C139.383 508.153 142.01 508.489 144.571 508.072C147.131 507.655 149.516 506.503 151.432 504.757L172.698 485.394C247.19 417.643 310.406 338.487 359.975 250.894L373.136 227.658C374.292 225.626 374.894 223.327 374.882 220.99C374.87 218.653 374.243 216.361 373.065 214.341C371.887 212.322 370.199 210.646 368.17 209.482C366.141 208.318 363.841 207.706 361.5 207.707H249.685V14.125Z" />
              </svg>
              <span className="font-medium text-gray-900 text-sm">
                Get Started
              </span>
            </div>
          </a>
        </div>
      </div>
      <div className="space-y-0.5">
        <div className="text-xs text-gray-500 max-lg:text-center">
          Already member?
        </div>
        <a
          className="bg-white flex items-center group justify-start gap-2 rounded-lg border border-gray-200 w-42 px-3 py-2 transition-all duration-150 ease-in-out hover:bg-gray-50"
          href="/sign-in?redirect=/automate-my-work"
        >
          <svg
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="w-[20px] h-[20px] text-yellow-400 group-hover:scale-125 group-hover:-rotate-3 duration-150 ease-in-out"
          >
            <circle cx="12" cy="8" r="5" />
            <path d="M20 21a8 8 0 0 0-16 0" />
          </svg>
          <span className="font-medium text-gray-900 text-sm">My Profile</span>
        </a>
      </div>
    </div>
  );
};

const GuideModal = ({ isOpen, setIsOpen }) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl sm:p-6">
                <div className="absolute right-0 top-0 pr-4 pt-4">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={() => setIsOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* <div className="prose prose-lg max-w-none mb-12">
                  <h1 className="text-4xl font-bold mb-8 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 bg-clip-text text-transparent">
                    🚀 The Magic of Automation: A Revolutionary Guide
                  </h1>
                  
                  <div className="space-y-8">
                    <section>
                      <h2 className="text-2xl font-bold">The Vision</h2>
                      <p className="text-gray-600">
                        Imagine a world where your boring, repetitive problems vanish in clicks.
                        We <strong>already</strong> live in a world where AI can take away your headache & stress.
                        <br /><br />
                        Let us take the boring+repetitive out of your work, so you can focus on doing what you love.
                        <br /><br />
                        Automating your work unlocks more than just time - it's about freedom & actualization.
                      </p>
                    </section>
                  </div>
                </div> */}
                <div className="bg-white py-4">
                  <div className="mx-auto max-w-3xl px-6 lg:max-w-7xl lg:px-4">
                    <h2 className="text-base/7 font-semibold text-indigo-600">
                      How It Works
                    </h2>
                    <p className="mt-2 max-w-4xl text-pretty text-4xl font-semibold tracking-tight text-gray-950 sm:text-5xl">
                      The Magic of AI Automation
                      <span className="block mt-4 text-2xl text-gray-400 font-normal">
                        Everything you need to automate your boring + repetitive work
                      </span>
                    </p>
                    <div className="mt-4 grid grid-cols-1 gap-4 sm:mt-8 lg:grid-cols-6 lg:grid-rows-2">
                      <div className="relative lg:col-span-3">
                        <div className="absolute inset-px rounded-lg bg-white max-lg:rounded-t-[2rem] lg:rounded-tl-[2rem]" />
                        <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-t-[calc(2rem+1px)] lg:rounded-tl-[calc(2rem+1px)]">
                          <video
                            autoPlay
                            muted
                            loop
                            playsInline
                            className="h-80 object-cover object-left"
                          >
                            <source
                              src="https://cdn.dribbble.com/userupload/10156529/file/original-bc4415c45f504ffac6668e1b3fa8d070.mp4"
                              type="video/mp4"
                            />
                          </video>
                          <div className="p-10 pt-4">
                            <h3 className="text-sm/4 font-semibold text-indigo-600">
                              Smart Search
                            </h3>
                            <p className="mt-2 text-lg font-medium tracking-tight text-gray-950">
                              Intelligent Discovery
                            </p>
                            <p className="mt-2 max-w-lg text-sm/6 text-gray-600">
                              Describe what you want to automate in plain
                              English to match you with the perfect automation
                              solutions.
                            </p>
                          </div>
                        </div>
                        <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-t-[2rem] lg:rounded-tl-[2rem]" />
                      </div>
                      <div className="relative lg:col-span-3">
                        <div className="absolute inset-px rounded-lg bg-white lg:rounded-tr-[2rem]" />
                        <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-tr-[calc(2rem+1px)]">
                          <img
                            alt="ROI and metrics visualization"
                            src="https://cdn.dribbble.com/userupload/16355619/file/original-109fa73f8369e2c7d80c952b64af2a93.jpg?resize=1024x768&vertical=center"
                            className="h-80 object-cover object-left lg:object-right"
                          />
                          <div className="p-10 pt-4">
                            <h3 className="text-sm/4 font-semibold text-indigo-600">
                              Value Metrics
                            </h3>
                            <p className="mt-2 text-lg font-medium tracking-tight text-gray-950">
                              Clear ROI & Impact
                            </p>
                            <p className="mt-2 max-w-lg text-sm/6 text-gray-600">
                              See the exact time and money saved with each
                              automation. Real-time metrics show monthly cost
                              savings and efficiency gains.
                            </p>
                          </div>
                        </div>
                        <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-tr-[2rem]" />
                      </div>
                      <div className="relative lg:col-span-2">
                        <div className="absolute inset-px rounded-lg bg-white lg:rounded-bl-[2rem]" />
                        <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-bl-[calc(2rem+1px)]">
                          <img
                            alt="Quick setup process"
                            src="https://cdn.dribbble.com/userupload/18075232/file/original-e9aff1276d2ac41e257692c2e31dd178.png?resize=1024x768&vertical=center"
                            className="h-80 object-cover object-left"
                          />
                          <div className="p-10 pt-4">
                            <h3 className="text-sm/4 font-semibold text-indigo-600">
                              Quick Setup
                            </h3>
                            <p className="mt-2 text-lg font-medium tracking-tight text-gray-950">
                              2 - 7 Day Delivery
                            </p>
                            <p className="mt-2 max-w-lg text-sm/6 text-gray-600">
                              Get your automation up and running in days, not
                              weeks. Simple requirements form and rapid
                              deployment process.
                            </p>
                          </div>
                        </div>
                        <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-bl-[2rem]" />
                      </div>
                      <div className="relative lg:col-span-2">
                        <div className="absolute inset-px rounded-lg bg-white" />
                        <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)]">
                          <img
                            alt="Tool integration showcase"
                            src="https://tailwindui.com/plus/img/component-images/bento-01-integrations.png"
                            className="h-80 object-cover"
                          />
                          <div className="p-10 pt-4">
                            <h3 className="text-sm/4 font-semibold text-indigo-600">
                              Integrations (Coming Soon)
                            </h3>
                            <p className="mt-2 text-lg font-medium tracking-tight text-gray-950">
                              Works with Your Stack
                            </p>
                            <p className="mt-2 max-w-lg text-sm/6 text-gray-600">
                              Seamlessly connects with your existing tools.
                              Support for 100+ popular business applications and
                              platforms.
                            </p>
                          </div>
                        </div>
                        <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5" />
                      </div>
                      <div className="relative lg:col-span-2">
                        <div className="absolute inset-px rounded-lg bg-white max-lg:rounded-b-[2rem] lg:rounded-br-[2rem]" />
                        <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-b-[calc(2rem+1px)] lg:rounded-br-[calc(2rem+1px)]">
                          <img
                            alt="Money-back guarantee"
                            src="https://cdn.dribbble.com/users/458944/screenshots/5058685/30day-01_4x.png?resize=1000x750&vertical=center"
                            className="h-80 object-cover"
                          />
                          <div className="p-10 pt-4">
                            <h3 className="text-sm/4 font-semibold text-indigo-600">
                              Risk-Free Trial
                            </h3>
                            <p className="mt-2 text-lg font-medium tracking-tight text-gray-950">
                              30-Day Guarantee
                            </p>
                            <p className="mt-2 max-w-lg text-sm/6 text-gray-600">
                              Try any automation risk-free with our 30-day
                              money-back guarantee. Early access members get a
                              14-day free trial.
                            </p>
                          </div>
                        </div>
                        <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-b-[2rem] lg:rounded-br-[2rem]" />
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const AutomateForm = ({ isOpen, setIsOpen }) => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    tools: "",
    expectedBenefit: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Create record in automationRequests table
      await base("automationRequests").create([
        {
          fields: {
            AutomationDescription: formData.title,
            Requirements: formData.description,
            TechnologiesUsed: formData.tools,
            Savings: formData.expectedBenefit,
            Status: "Requested",
            Date: new Date().toISOString().split("T")[0],
            Type: "Custom",
          },
        },
      ]);

      // Create record in automationsClickMinded table with additional fields
      await base("automationsClickMinded").create([
        {
          fields: {
            Title: formData.title,
            IncludedFeatures: formData.description,
            Tools: formData.tools,
            Benefits: formData.expectedBenefit, // Benefits appears to be an array in the schema
            Stage: "requested",
            Type: "Custom",
            SetupPrice: 499, // Default setup price
            MonthlyPrice: 99, // Default monthly price
            TurnaroundDays: 3, // Default turnaround days
            // LastUpdated: new Date().toISOString(),
            // LastModified: new Date().toISOString(),
            requirements: JSON.stringify({
              fromClient: [
                {
                  name: "Project Requirements",
                  example: formData.description
                }
              ]
            }),
            WorkflowDetails: JSON.stringify({
              stages: [
                {
                  name: "Setup",
                  duration: "3-5 days",
                  description: "Initial configuration and setup"
                }
              ]
            })
          },
        },
      ]);

      setIsOpen(false);
      // Optionally add success toast/notification
    } catch (error) {
      console.error("Error creating automation request:", error);
      // Optionally add error toast/notification
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 pr-4 pt-4">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={() => setIsOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-base font-semibold leading-6 text-gray-900"
                  >
                    Request New Automation
                  </Dialog.Title>

                  <form
                    onSubmit={handleSubmit}
                    className="mt-6 space-y-6 text-left"
                  >
                    <div>
                      <label
                        htmlFor="title"
                        className="block text-sm font-medium text-gray-700"
                      >
                        What would you like to automate?
                      </label>
                      <input
                        type="text"
                        name="title"
                        id="title"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-stone-500 focus:ring-stone-500 sm:text-sm"
                        value={formData.title}
                        onChange={(e) =>
                          setFormData({ ...formData, title: e.target.value })
                        }
                        required
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="description"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Describe the process in detail
                      </label>
                      <textarea
                        id="description"
                        name="description"
                        rows={4}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-stone-500 focus:ring-stone-500 sm:text-sm"
                        value={formData.description}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            description: e.target.value,
                          })
                        }
                        required
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="tools"
                        className="block text-sm font-medium text-gray-700"
                      >
                        What tools/software do you use for this process?
                      </label>
                      <input
                        type="text"
                        name="tools"
                        id="tools"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-stone-500 focus:ring-stone-500 sm:text-sm"
                        value={formData.tools}
                        onChange={(e) =>
                          setFormData({ ...formData, tools: e.target.value })
                        }
                        required
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="expectedBenefit"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Expected time/money saved per month
                      </label>
                      <input
                        type="text"
                        name="expectedBenefit"
                        id="expectedBenefit"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-stone-500 focus:ring-stone-500 sm:text-sm"
                        value={formData.expectedBenefit}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            expectedBenefit: e.target.value,
                          })
                        }
                        required
                      />
                    </div>

                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                      <button
                        type="submit"
                        className="inline-flex w-full justify-center rounded-md bg-stone-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-stone-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600 sm:col-start-2"
                      >
                        Submit Request
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                        onClick={() => setIsOpen(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const Header = ({ runTour, setRunTour }) => {
  const [showGuide, setShowGuide] = useState(false);
  const [showAutomateForm, setShowAutomateForm] = useState(false);

  return (
    <header className="flex max-lg:flex-col justify-between p-2">
      <div className="space-y-4 pl-4 pr-2 relative flex-1">
        <div className="flex items-center justify-between">
          <h1 className="text-3xl md:text-4xl font-extrabold text-gray-900">
            Automate My{" "}
            <Typed
              strings={[
                "Work",
                "Boring + Repetitive",
                "Data Entry",
                "Technical Set Up",
                "Marketing",
                "HR",
                "Finance",
                "Onboarding",
                "Customer Success",
                "Project Mgmt",
                "Sales",
                "Recruiting",
                "Accounting",
              ]}
              typeSpeed={40}
              backSpeed={50}
              loop
              backDelay={1000}
            />
          </h1>
          <div className="flex items-center ml-auto max-lg:hidden">
            <button
              onClick={() => setShowAutomateForm(true)}
              className="group inline-flex items-center px-4 py-2.5 mr-1 text-sm font-medium text-stone-700 bg-transparent rounded-md hover:bg-stone-50 transition-colors duration-200 drop-shadow-sm w-[190px]"
            >
              <svg
                className="w-4 h-4 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 4v16m8-8H4"
                />
              </svg>
              <span className="drop-shadow-sm whitespace-nowrap">
                Request Automation
              </span>
            </button>
            <button
              onClick={() => setShowGuide(true)}
              className="group inline-flex items-center px-4 py-2.5 text-sm font-medium text-stone-700 bg-transparent rounded-md hover:bg-stone-50 transition-colors duration-200 drop-shadow-md border-2 border-stone-700"
            >
              <PlayIcon className="h-4 w-4 text-stone-700 group-hover:scale-110 transition-transform duration-200" />
            </button>
            <button
              onClick={() => setRunTour(true)}
              className="inline-flex items-center ml-1 px-4 py-2 text-sm font-medium text-stone-700 bg-transparent rounded-md hover:bg-stone-50 transition-colors duration-200 drop-shadow-md border-2 border-stone-700"
            >
              <svg
                className="w-4 h-4 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span className="drop-shadow-sm">Take a Tour</span>
            </button>
          </div>
        </div>
      </div>
      <div className="max-lg:order-first">
        <AuthButtons />
      </div>

      <GuideModal isOpen={showGuide} setIsOpen={setShowGuide} />
      <AutomateForm isOpen={showAutomateForm} setIsOpen={setShowAutomateForm} />

      {/* Mobile navigation */}
      <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 lg:hidden z-40">
        <div className="flex justify-around p-4">
          <button
            onClick={() => setShowAutomateForm(true)}
            className="flex flex-col items-center px-3 py-2"
          >
            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
            </svg>
            <span className="text-xs mt-1">Automate</span>
          </button>
          
          <button
            onClick={() => setShowGuide(true)}
            className="flex flex-col items-center px-3 py-2"
          >
            <PlayIcon className="h-6 w-6 text-stone-700" />
            <span className="text-xs mt-1">Guide</span>
          </button>
          
          <button
            onClick={() => setRunTour(true)}
            className="flex flex-col items-center px-3 py-2"
          >
            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span className="text-xs mt-1">Tour</span>
          </button>
        </div>
      </div>
    </header>
  );
};

export default function Automate({ company }) {
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [runTour, setRunTour] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="bg-[#F5F0EA]">
      <main className="min-h-screen max-lg:pb-16 max-w-7xl mx-auto max-lg:p-6 md:py-12 space-y-4 flex flex-col px-8">
        <Header runTour={runTour} setRunTour={setRunTour} />
        <div className="mt-6 drop-shadow-sm">
          <AutomationDirectory
            company={company}
            runTour={runTour}
            setRunTour={setRunTour}
          />
        </div>
      </main>

      {/* Scroll to top button */}
      {showScrollTop && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-8 left-8 p-2 bg-white rounded-full shadow-lg hover:shadow-xl transition-all duration-300 ease-in-out z-50 group"
        >
          <img
            src="/images/cloud-up.png"
            alt="Scroll to top"
            className="h-6 w-6 group-hover:scale-110 transition-transform duration-200"
          />
        </button>
      )}
    </div>
  );
}
