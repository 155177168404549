// Import the icon mapping
import { iconMap } from './iconMap';
import { base } from '../airtable-config'; // Adjust the import path as needed

const transformAirtableRecord = (record) => {
  // Get all fields from the record first
  const fields = record.fields;

  // Helper function to safely parse JSON with fallback
  const safeJsonParse = (value, fallback) => {
    if (!value) return fallback;
    if (Array.isArray(value)) return value;
    if (typeof value === 'object') return value;
    
    try {
      return typeof value === 'string' ? JSON.parse(value) : value;
    } catch (e) {
      console.error('Failed to parse JSON:', e);
      if (typeof value === 'string') {
        return [value];
      }
      return fallback;
    }
  };

  // Parse tools and map to icons
  const tools = safeJsonParse(fields.Tools, ['AI Assistant']);
  const icons = tools.map(tool => {
    const normalizedTool = tool.toLowerCase().replace(/\s+/g, '');
    if (normalizedTool === 'aiassistant') return iconMap['ai'];
    if (normalizedTool.startsWith('google-')) return iconMap[normalizedTool];
    return iconMap[normalizedTool] || iconMap['ai'];
  });

  // Get image URL from attachment
  const imageAttachment = fields.Image;
  const imageUrl = imageAttachment && imageAttachment.length > 0 ? imageAttachment[0].url : null;

  // Return object with all fields and transformations
  return {
    // Include all raw fields first
    ...fields,
    
    // Add record ID
    id: record.id,
    
    // Ensure title is properly set - check both common field names
    title: fields.Title || fields.title || fields.Name || fields.name || "Untitled Automation",
    
    // Add transformed fields
    tools,
    icons,
    imageUrl,
    
    // Transform JSON fields only where needed
    requirements: safeJsonParse(fields.requirements || fields.Requirements, {}),
    heartedBy: safeJsonParse(fields.HeartedBy, '[]'),
    benefits: safeJsonParse(fields.Benefits, []),
    included: safeJsonParse(fields.Included, []),
    exampleOutputs: safeJsonParse(fields.ExampleOutputs, []),
    integrations: safeJsonParse(fields.Integrations, []),
    compliance: safeJsonParse(fields.Compliance, []),
    deliverables: safeJsonParse(fields.Deliverables, []),
    
    // Add computed or default values
    stage: fields.Stage || 'coming_soon',
    type: fields.Type || 'automation',
    usedBy: fields.UsedBy || 'New',
    partnerEmail: fields.PartnerEmail || 'spark@merchantgrowth.com',
    turnaroundDays: fields.TurnaroundDays || 3,
    stripeLink: fields.StripeLink || process.env.REACT_APP_DEFAULT_STRIPE_LINK
  };
};

// Export function to get combined cards
export const getAutomationCards = async () => {
  try {
    const records = await base('automationsClickMinded').select().all();
    const airtableCards = records.map(transformAirtableRecord);
    
    // IF WE WANT TO USE STATIC CARDS
    // const allCards = [...automationCards, ...airtableCards];

    // Combine arrays
    const allCards = [...airtableCards];
    
    // Sort cards by heart count (descending)
    const sortedCards = allCards.sort((a, b) => {
      const aHearts = Array.isArray(a.heartedBy) ? a.heartedBy.length : 
                     (typeof a.heartedBy === 'string' ? JSON.parse(a.heartedBy).length : 0);
      const bHearts = Array.isArray(b.heartedBy) ? b.heartedBy.length : 
                     (typeof b.heartedBy === 'string' ? JSON.parse(b.heartedBy).length : 0);
      return bHearts - aHearts;
    });
    
    return sortedCards;
  } catch (error) {
    console.error('Error fetching Airtable automations:', error);
    return automationCards; // Fallback to static cards only
  }
};

export const automationCards = [
    // Sales & Marketing (15 items)
    {
      title: 'AI-Powered Email Marketing: Weekly niche newsletter',
      tools: ['Mailchimp', 'CRM'],
      usedBy: '25.3k users',
      icons: [iconMap.mailchimp, iconMap.crm],
      stage: 'ready',
      name: 'ready',
      benefits: [
        'Expected revenue: $2-5k/mo per 1k subscribers*',
        'Average deal size conversion: 3.2% for $500+ purchases',
        'Save 80+ hours (& $1,600) per month',
        'Better lead nurturing',
        'Increase customer lifetime value by 25%'
      ],
      pricing: {
        setup: 599,
        monthly: 119,
        perThousandContacts: 10,
        additionalTemplates: 49,
        customIntegration: 299
      },
      included: [
        'A/B testing',
        'Campaign automation',
        'List segmentation',
        'Analytics dashboard',
        'Template library',
        'CRM integration'
      ],
      deliverables: [
        'Initial strategy document',
        '5 custom email templates',
        '3 automated workflows',
        'Welcome series setup',
        'Re-engagement campaign',
        'Monthly performance reports',
        'Quarterly strategy review'
      ],
      workflow: [
        {
          stage: 'Setup',
          duration: '3-5 days',
          tasks: [
            'Account configuration',
            'Domain verification',
            'Integration setup',
            'Template creation',
            'List import and segmentation'
          ]
        },
        {
          stage: 'Campaign Creation',
          duration: '4-7 days',
          tasks: [
            'Content strategy',
            'Template customization',
            'Automation workflow setup',
            'A/B test planning',
            'Trigger definition'
          ]
        },
        {
          stage: 'Testing',
          duration: '2-3 days',
          tasks: [
            'Spam testing',
            'Device testing',
            'Integration testing',
            'Workflow validation',
            'Load testing'
          ]
        }
      ],
      maintenance: {
        daily: [
          'Monitor deliverability',
          'Check engagement metrics',
          'Review automation triggers'
        ],
        weekly: [
          'List cleanup',
          'Performance reporting',
          'A/B test analysis'
        ],
        monthly: [
          'Strategy review',
          'Content optimization',
          'Database maintenance',
          'Compliance check'
        ]
      },
      metrics: {
        primary: [
          'Open rate',
          'Click-through rate',
          'Conversion rate',
          'Revenue attribution',
          'List growth rate'
        ],
        secondary: [
          'Bounce rate',
          'Unsubscribe rate',
          'Spam complaints',
          'Email client distribution',
          'Geographic engagement'
        ]
      },
      bestPractices: [
        'Segment lists based on behavior and preferences',
        'Personalize beyond first name',
        'Test subject lines and preview text',
        'Optimize send times based on engagement',
        'Regular list cleaning and validation',
        'Mobile-first design approach',
        'Clear call-to-actions',
        'Compliance with GDPR/CCPA'
      ],
      turnaroundDays: 14,
      stripeLink: 'https://buy.stripe.com/7sIeYEb3Q7SI5HObIY',
      support: {
        included: [
          'Email support (24/7)',
          'Phone support (business hours)',
          'Monthly strategy calls',
          'Emergency response (< 4 hours)',
          'Training sessions'
        ],
        sla: {
          responseTime: '< 4 hours',
          uptime: '99.9%',
          issueResolution: '< 24 hours'
        }
      },
      exampleOutputs: [
        {
          type: 'email_template',
          description: 'Welcome Series - Email 1',
          imageUrl: 'https://files.reallygoodemails.com/emails/introducing-the-north-face-x-skims.png',
          specs: {
            size: '600x800px',
            format: 'Responsive HTML',
            preview: 'Welcome to [Company]! Here\'s your getting started guide...'
          }
        },
        {
          type: 'dashboard',
          description: 'Campaign Performance Dashboard',
          imageUrl: 'https://reallygoodemails.com/_next/image?url=https%3A%2F%2Ffiles.reallygoodemails.com%2Femails%2Fwork-management-monthly-update.png&w=384&q=75',
          specs: {
            metrics: ['Opens', 'Clicks', 'Conversions', 'Revenue'],
            timeRange: 'Last 30 days',
            exportFormats: ['PDF', 'CSV']
          }
        },
        {
          type: 'workflow',
          description: 'Abandoned Cart Recovery Flow',
          imageUrl: 'https://reallygoodemails.com/_next/image?url=https%3A%2F%2Ffiles.reallygoodemails.com%2Femails%2F20-off-a-perfect-finish-every-time.png&w=384&q=75',
          specs: {
            steps: 5,
            duration: '7 days',
            triggerEvent: 'Cart abandonment'
          }
        }
      ],
      automationRecipes: {
        make: {
          name: 'Email Marketing Automation',
          url: 'https://www.make.com/en/templates/12345-email-marketing-automation',
          description: 'Automated email campaign workflow with CRM integration',
          modules: [
            'Gmail',
            'Mailchimp',
            'CRM Integration',
            'Google Sheets',
            'HTTP'
          ],
          complexity: 'Medium',
          estimatedSetupTime: '45 minutes'
        },
        n8n: {
          name: 'Email Campaign Manager',
          url: 'https://n8n.io/workflows/1234-email-campaign-manager',
          description: 'End-to-end email marketing automation workflow',
          nodes: [
            'Mailchimp Trigger',
            'CRM Node',
            'Function Node',
            'HTTP Request',
            'Send Email'
          ],
          complexity: 'Advanced',
          estimatedSetupTime: '60 minutes'
        }
      },
      requirements: {
        fromClient: [
          {
            name: "Target audience description",
            example: "Describe your ideal subscriber demographics, interests, and pain points"
          },
          {
            name: "Newsletter topics",
            example: "List 3-5 main content themes or topics you want to cover in your newsletter"
          },
          {
            name: "Existing email templates",
            example: "Share any existing email templates or brand guidelines you want us to follow"
          },
          {
            name: "Content preferences",
            example: "Describe your preferred content style, tone of voice, and any specific requirements"
          }
        ],
        technical: [
          {
            name: "Mailchimp credentials",
            example: "Provide API keys or admin access to your Mailchimp account"
          },
          {
            name: "CRM access",
            example: "Share necessary login credentials for your CRM system"
          }
        ]
      },
    },
    {
      title: 'AI-Powered Social Media Management: Schedule and analyze posts across platforms',
      tools: ['Buffer', 'Instagram'],
      usedBy: '22.1k users',
      icons: [iconMap.buffer, iconMap.instagram],
      stage: 'requested',
      name: 'Requested',
      benefits: [
        'Save 20+ hours per week on social media management',
        'Increase engagement rates by 40%',
        'Maintain consistent posting schedule across platforms',
        'Improve brand awareness by 55%',
        'Boost follower growth by 35%',
        'Higher ROI on social media campaigns'
      ],
      pricing: {
        setup: 449,
        monthly: 89,
        additionalPlatforms: 29,
        contentCreation: 199,
        adManagement: 299,
        analyticsReports: 79
      },
      included: [
        'Multi-platform post scheduling',
        'Analytics dashboard',
        'Content calendar',
        'Hashtag management',
        'Engagement tracking',
        'Competitor analysis',
        // 'Audience insights',
        // 'Visual planning tools',
        // 'Comment moderation',
        // 'Team collaboration',
      ],
      deliverables: [
        'Social media strategy document',
        '30-day content calendar',
        '20 custom graphics per month',
        'Weekly performance reports',
        'Monthly strategy review',
        'Hashtag research',
        'Competitor analysis report'
      ],
      requirements: {
        fromClient: [
          {
            name: "Content themes",
            example: "List your main content themes and topics (e.g., industry news, product updates, tips)"
          },
          {
            name: "Brand voice guidelines",
            example: "Describe your brand's tone, style, and any specific language preferences"
          },
          {
            name: "Posting schedule",
            example: "Specify preferred posting times and frequency for each platform"
          },
          {
            name: "Competitor accounts",
            example: "List 3-5 competitor social media accounts you'd like us to monitor"
          }
        ],
        technical: [
          {
            name: "Social media credentials",
            example: "Provide admin access to your social media accounts (Instagram, Facebook, etc.)"
          },
          {
            name: "Buffer access",
            example: "Share your Buffer account credentials or API keys"
          }
        ]
      },
      workflow: [
        {
          stage: 'Setup',
          duration: '2-3 days',
          tasks: [
            'Platform audit',
            'Account configuration',
            'Tool integration',
            'Team training',
            'Content template setup'
          ]
        },
        {
          stage: 'Content Planning',
          duration: '3-4 days',
          tasks: [
            'Content strategy development',
            'Editorial calendar creation',
            'Hashtag research',
            'Asset preparation',
            'Approval workflow setup'
          ]
        },
        {
          stage: 'Execution',
          duration: 'Ongoing',
          tasks: [
            'Content scheduling',
            'Community management',
            'Performance monitoring',
            'Engagement responses',
            'Crisis management'
          ]
        }
      ],
      maintenance: {
        daily: [
          'Monitor engagement',
          'Respond to comments',
          'Check scheduled content',
          'Track trending topics'
        ],
        weekly: [
          'Performance analysis',
          'Content calendar updates',
          'Hashtag performance review',
          'Competitor monitoring'
        ],
        monthly: [
          'Strategy review',
          'Analytics reporting',
          'Content audit',
          'Trend analysis'
        ]
      },
      metrics: {
        primary: [
          'Engagement rate',
          'Follower growth',
          'Reach and impressions',
          'Click-through rate',
          'Conversion rate'
        ],
        secondary: [
          'Share of voice',
          'Sentiment analysis',
          'Response time',
          'Brand mentions',
          'Hashtag performance'
        ]
      },
      bestPractices: [
        'Maintain consistent posting schedule',
        'Engage with audience regularly',
        'Use platform-specific content formats',
        'Monitor competitor activities',
        'Leverage user-generated content',
        'Optimize posting times',
        'Use high-quality visuals',
        'Track and adapt to trends'
      ],
      turnaroundDays: 7,
      stripeLink: 'https://buy.stripe.com/test_yourLinkHere2',
      support: {
        included: [
          'Email support (24/7)',
          'Chat support',
          'Monthly strategy calls',
          'Emergency response (< 2 hours)',
          'Training sessions'
        ],
        sla: {
          responseTime: '< 2 hours',
          postingAccuracy: '99.9%',
          issueResolution: '< 12 hours'
        }
      },
      exampleOutputs: [
        {
          type: 'social_post',
          description: 'Instagram Carousel Post',
          imageUrl: 'https://miro.medium.com/v2/resize:fit:720/format:webp/1*HjQfsq7S61V17ZpHzra3VQ.png',
          specs: {
            format: 'Carousel (up to 10 images)',
            size: '1080x1080px',
            caption: 'Engaging product showcase with call-to-action'
          }
        },
        {
          type: 'analytics',
          description: 'Cross-Platform Performance Dashboard',
          imageUrl: 'https://images.ctfassets.net/az3stxsro5h5/V5dz4Po6bHT9IzUGJvSFY/2998177da281744206492fb2105b73b2/https___later.com_blog_wp-content_uploads_2018_11_4-2-IGanalytics-postperformance-metrics-updated-1024x683.gif',
          specs: {
            metrics: ['Engagement', 'Reach', 'Growth', 'Conversions'],
            timeRange: 'Last 30 days',
            platforms: ['Instagram', 'Facebook', 'Twitter', 'LinkedIn']
          }
        },
        {
          type: 'content_calendar',
          description: 'Monthly Content Schedule',
          imageUrl: 'https://s3.us-west-2.amazonaws.com/public.notion-static.com/template/1943e958-5c25-4bf4-97cc-767b436f5d31/1719399498913/desktop.jpg',
          specs: {
            posts: 30,
            platforms: 4,
            contentTypes: ['Images', 'Videos', 'Stories', 'Reels']
          }
        }
      ],
      automationRecipes: {
        make: {
          name: 'Social Media Automation',
          url: 'https://www.make.com/en/templates/social-media-automation',
          description: 'Cross-platform posting and engagement tracking',
          modules: [
            'Buffer',
            'Instagram Business',
            'Facebook Pages',
            'Twitter',
            'LinkedIn'
          ],
          complexity: 'Medium',
          estimatedSetupTime: '30 minutes'
        },
        n8n: {
          name: 'Social Media Manager',
          url: 'https://n8n.io/workflows/social-media-manager',
          description: 'Automated social media workflow with analytics',
          nodes: [
            'Social Trigger',
            'Content Filter',
            'Scheduler',
            'Platform Poster',
            'Analytics Tracker'
          ],
          complexity: 'Intermediate',
          estimatedSetupTime: '45 minutes'
        }
      }
    },
    {
      title: 'AI-Powered Lead Generation: Capture and qualify leads from multiple sources',
      tools: ['LinkedIn', 'HubSpot'],
      usedBy: '20.8k users',
      icons: [iconMap.linkedin, iconMap.hubspot],
      stage: 'coming_soon',
      name: 'coming soon',
      benefits: [
        'Generate 3x more qualified leads',
        'Reduce lead qualification time by 65%',
        'Improve lead conversion rate by 45%',
        'Decrease cost per lead by 40%',
        'Increase sales pipeline velocity by 50%',
        'Better ROI on marketing spend'
      ],
      pricing: {
        setup: 599,
        monthly: 119,
        additionalSources: 49,
        customIntegration: 299,
        leadEnrichment: 199,
        automatedNurturing: 249
      },
      included: [
        'Multi-channel lead capture',
        'Lead scoring system',
        'Automated qualification workflows',
        'Integration with your CRM',
        'Lead nurturing sequences',
        'Lead enrichment',
        'Pipeline analytics',
        'A/B testing tools',
        'Custom reporting',
        'Lead source tracking',
        'ROI analytics'
      ],
      deliverables: [
        'Lead generation strategy',
        '5 custom landing pages',
        '3 lead magnets',
        'Email nurture sequence',
        'Lead scoring model',
        'Monthly performance reports',
        'Pipeline analysis'
      ],
      requirements: {
        fromClient: [
          {
            name: "Ideal customer profile",
            example: "Define your ideal customer characteristics, including industry, company size, and pain points"
          },
          {
            name: "Qualification criteria",
            example: "List the criteria that makes a lead 'qualified' for your business"
          },
          {
            name: "Lead scoring rules",
            example: "Specify how different lead attributes should be weighted in scoring"
          },
          {
            name: "Sales process details",
            example: "Describe your current sales process and how leads should be handled"
          }
        ],
        technical: [
          {
            name: "LinkedIn credentials",
            example: "Provide access to LinkedIn Sales Navigator or LinkedIn account"
          },
          {
            name: "HubSpot access",
            example: "Share HubSpot admin credentials or API keys"
          }
        ]
      },
      workflow: [
        {
          stage: 'Strategy',
          duration: '3-4 days',
          tasks: [
            'Market research',
            'Channel selection',
            'Lead magnet planning',
            'Conversion path design',
            'Integration planning'
          ]
        },
        {
          stage: 'Setup',
          duration: '4-5 days',
          tasks: [
            'Form creation',
            'Landing page setup',
            'CRM integration',
            'Automation workflow setup',
            'Lead scoring implementation'
          ]
        },
        {
          stage: 'Optimization',
          duration: 'Ongoing',
          tasks: [
            'A/B testing',
            'Conversion optimization',
            'Channel performance review',
            'Lead quality analysis',
            'Process refinement'
          ]
        }
      ],
      maintenance: {
        daily: [
          'Monitor lead flow',
          'Check form submissions',
          'Review lead quality',
          'Update lead scores'
        ],
        weekly: [
          'Performance analysis',
          'Lead source optimization',
          'Nurture sequence review',
          'Pipeline analysis'
        ],
        monthly: [
          'Strategy review',
          'Channel optimization',
          'Process automation audit',
          'ROI analysis'
        ]
      },
      metrics: {
        primary: [
          'Lead volume',
          'Conversion rate',
          'Cost per lead',
          'Lead quality score',
          'Sales qualified leads'
        ],
        secondary: [
          'Form completion rate',
          'Landing page performance',
          'Channel attribution',
          'Lead response time',
          'Nurture engagement'
        ]
      },
      bestPractices: [
        'Multi-channel approach',
        'Clear value proposition',
        'Progressive profiling',
        'Regular lead scoring updates',
        'Automated nurture workflows',
        'A/B testing everything',
        'Regular process optimization',
        'Data quality maintenance'
      ],
      turnaroundDays: 4,
      stripeLink: 'https://buy.stripe.com/test_yourLinkHere3',
      support: {
        included: [
          'Email support (24/7)',
          'Phone support',
          'Bi-weekly strategy calls',
          'Emergency response (< 3 hours)',
          'Training sessions'
        ],
        sla: {
          responseTime: '< 3 hours',
          leadDelivery: '99.9%',
          issueResolution: '< 24 hours'
        }
      }
    },
    {
      title: 'AI-Powered Content Creation & Distribution: Automate blogs, social media, newsletters, and ads',
      tools: ['AI Assistant', 'Buffer'],
      usedBy: '19.5k users',
      icons: [iconMap.ai, iconMap.buffer],
      stage: 'coming_soon',
      name: 'coming soon',
      benefits: [
        'Create content 5x faster',
        'Maintain consistent brand voice',
        'Generate ideas automatically',
        'Scale content production'
      ],
      pricing: {
        setup: 699,
        monthly: 149
      },
      included: [
        'AI content generation',
        'Editorial calendar',
        'Content distribution',
        'SEO optimization',
        'Performance analytics'
      ],
      turnaroundDays: 3,
      stripeLink: 'https://buy.stripe.com/test_yourLinkHere4',
      requirements: {
        fromClient: [
          {
            name: "Content strategy",
            example: "Outline your content goals, target audience, and preferred content types"
          },
          {
            name: "Key topics",
            example: "List main topics and themes you want to cover in your content"
          },
          {
            name: "Brand voice guidelines",
            example: "Describe your brand's tone, style, and writing preferences"
          },
          {
            name: "Distribution channels",
            example: "Specify which platforms and channels you want to publish content to"
          }
        ],
        technical: [
          {
            name: "CMS access",
            example: "Provide credentials for your content management system"
          },
          {
            name: "Buffer/social credentials",
            example: "Share access to your social media scheduling tools"
          }
        ]
      },
    },
    {
      title: 'AI-Powered Analytics Reporting: Transform Data into Beautiful, Actionable Insights',
      tools: ['Mixpanel', 'Sheets'],
      usedBy: '16.5k users',
      icons: [iconMap.mixpanel, iconMap.sheets],
      stage: 'coming_soon',
      name: 'coming soon',
      benefits: [
        'Save 25+ hours on monthly reporting',
        'Real-time performance insights',
        'Automated data visualization',
        'Better decision making'
      ],
      pricing: {
        setup: 599,
        monthly: 119
      },
      included: [
        'Custom report templates',
        'Automated data pulls',
        'KPI tracking',
        'Data visualization',
        'Scheduled reporting',
        'Multi-channel analytics'
      ],
      turnaroundDays: 3,
      stripeLink: 'https://buy.stripe.com/test_yourLinkHere8',
      requirements: {
        fromClient: [
          {
            name: "Key metrics",
            example: "List the most important metrics you want to track and report on"
          },
          {
            name: "Report frequency",
            example: "Specify how often you need reports (daily, weekly, monthly)"
          },
          {
            name: "Dashboard preferences",
            example: "Describe your preferred dashboard layout and visualization types"
          },
          {
            name: "Stakeholder needs",
            example: "List who will be using these reports and what insights they need"
          }
        ],
        technical: [
          {
            name: "Mixpanel access",
            example: "Provide Mixpanel admin credentials or API keys"
          },
          {
            name: "Google Sheets access",
            example: "Share access to relevant Google Sheets or spreadsheets"
          }
        ]
      },
    },
    {
      title: 'AI-Powered Lead Nurturing: Automated drip campaigns and content delivery',
      tools: ['Mailchimp', 'HubSpot'],
      usedBy: '15.8k users',
      icons: [iconMap.mailchimp, iconMap.hubspot],
      stage: 'coming_soon',
      name: 'coming soon',
      requirements: {
        fromClient: [
          {
            name: "Brand guidelines",
            example: "Share your brand colors, fonts, tone of voice, and any specific style requirements"
          },
          {
            name: "Access to social accounts",
            example: "Provide admin access credentials for each social media platform"
          },
          {
            name: "Content preferences",
            example: "Describe your preferred content types, topics, and any content restrictions"
          },
          {
            name: "Target audience info",
            example: "Detail your ideal customer demographics, interests, and pain points"
          },
          {
            name: "Key messaging points",
            example: "List your main value propositions and key messages to communicate"
          },
          {
            name: "Product/service details",
            example: "Provide information about your offerings, features, and benefits"
          }
        ],
        technical: [
          {
            name: "Admin access to platforms",
            example: "Provide necessary login credentials and permission levels"
          },
          {
            name: "Brand assets and logos",
            example: "Share high-resolution logos, images, and other brand assets"
          },
          {
            name: "Website access for tracking",
            example: "Grant access to analytics and tracking tools"
          },
          {
            name: "Previous analytics data",
            example: "Share historical performance data and metrics if available"
          }
        ]
      }
    },
    {
      title: 'AI-Powered Lead enrichment and scoring: Automate lead enrichment and scoring',
      tools: ['Salesforce', 'LinkedIn'],
      usedBy: '17.9k users',
      icons: [iconMap.salesforce, iconMap.linkedin],
      stage: 'coming_soon',
      name: 'coming soon',
      benefits: [
        'Increase pipeline velocity by 55%',
        'Improve lead qualification accuracy',
        'Automate opportunity updates',
        'Better sales forecasting'
      ],
      pricing: {
        setup: 749,
        monthly: 149
      },
      included: [
        'Lead scoring engine',
        'Pipeline analytics',
        'Opportunity tracking',
        'Sales forecasting',
        'Team performance metrics',
        'Deal progress automation'
      ],
      turnaroundDays: 4,
      stripeLink: 'https://buy.stripe.com/test_yourLinkHere6'
    },    
    {
      title: 'AI-Powered Customer Communication: Automated updates and notifications',
      tools: ['Intercom', 'Mailchimp'],
      usedBy: '17.6k users',
      icons: [iconMap.intercom, iconMap.mailchimp],
      stage: 'coming_soon',
      name: 'coming soon',
      benefits: [
        'Increase customer engagement by 40%',
        'Reduce support inquiries by 30%',
        'Improve information delivery',
        'Better customer experience'
      ],
      pricing: {
        setup: 549,
        monthly: 109,
        perThousandUsers: 20
      },
      included: [
        'Multi-channel messaging',
        'Automated workflows',
        'Message personalization',
        'Engagement tracking',
        'A/B testing',
        'Campaign analytics',
        'Customer segmentation',
        'Template management'
      ],
      turnaroundDays: 3,
      stripeLink: 'https://buy.stripe.com/test_yourLinkHere54'
    },
    {
      title: 'Meeting Scheduling: Streamline appointment booking process',
      tools: ['Calendar', 'Zoom'],
      usedBy: '17.2k users',
      icons: [iconMap.calendar, iconMap.zoom],
      stage: 'coming_soon',
      name: 'coming soon',
      benefits: [
        'Eliminate scheduling back-and-forth',
        'Reduce no-shows by 80%',
        'Save 15+ hours per month',
        'Improve meeting preparation'
      ],
      pricing: {
        setup: 449,
        monthly: 89
      },
      included: [
        'Automated scheduling',
        'Calendar sync',
        'Reminder system',
        'Meeting link generation',
        'Timezone handling',
        'Booking page customization'
      ],
      turnaroundDays: 2,
      stripeLink: 'https://buy.stripe.com/test_yourLinkHere7'
    },
    {
      title: 'Contract Management: Automate contract creation and signing',
      tools: ['DocuSign', 'CRM'],
      usedBy: '13.7k users',
      icons: [iconMap.docusign, iconMap.crm],
      stage: 'coming_soon',
      name: 'coming soon',
      benefits: [
        'Reduce contract cycle time by 80%',
        'Improve compliance tracking',
        'Eliminate manual errors',
        'Better contract visibility'
      ],
      pricing: {
        setup: 699,
        monthly: 139,
        perContract: 5
      },
      included: [
        'Template automation',
        'E-signature workflow',
        'Version control',
        'Approval routing',
        'Contract tracking',
        'Renewal management',
        'Audit trail',
        'Integration with CRM'
      ],
      turnaroundDays: 4,
      stripeLink: 'https://buy.stripe.com/test_yourLinkHere52'
    },
    {
      title: 'Website Analytics: Track and report on visitor behavior',
      tools: ['Mixpanel', 'Sheets'],
      usedBy: '15.1k users',
      icons: [iconMap.mixpanel, iconMap.sheets],
      stage: 'coming_soon',
      name: 'coming soon'
    },
    {
      title: 'Sales Forecasting: Automated pipeline analysis and predictions',
      tools: ['Salesforce', 'Sheets'],
      usedBy: '14.4k users',
      icons: [iconMap.salesforce, iconMap.sheets],
      stage: 'coming_soon',
      name: 'coming soon'
    },
    {
      title: 'Customer Relationship Magic: Sync customer data across all platforms',
      tools: ['Salesforce', 'HubSpot'],
      usedBy: '18.7k users',
      icons: [iconMap.salesforce, iconMap.hubspot],
      stage: 'coming_soon',
      name: 'coming soon',
      benefits: [
        'Eliminate manual data entry',
        'Reduce data errors by 95%',
        'Improve customer data accuracy',
        'Real-time data synchronization'
      ],
      pricing: {
        setup: 799,
        monthly: 159
      },
      included: [
        'Bi-directional sync',
        'Custom field mapping',
        'Data deduplication',
        'Automated data cleanup',
        'Error monitoring'
      ],
      turnaroundDays: 5,
      stripeLink: 'https://buy.stripe.com/test_yourLinkHere5'
    },
    {
      title: 'Competitor Analysis: Automated market and competitor tracking',
      tools: ['AI Assistant', 'Sheets'],
      usedBy: '13.0k users',
      icons: [iconMap.ai, iconMap.sheets],
      stage: 'coming_soon'
    },
    {
      title: 'Event Marketing: Automate event promotion and registration',
      tools: ['Mailchimp', 'Calendar'],
      usedBy: '12.3k users',
      icons: [iconMap.mailchimp, iconMap.calendar],
      stage: 'coming_soon'
    },
    {
      title: 'Sales Territory Management: Automated lead assignment and tracking',
      tools: ['Salesforce', 'Sheets'],
      usedBy: '11.6k users',
      icons: [iconMap.salesforce, iconMap.sheets],
      stage: 'coming_soon'
    },

    // Customer Success (15 items)
    {
      title: 'Customer Onboarding: Automated welcome and setup processes',
      tools: ['Intercom', 'CRM'],
      usedBy: '24.5k users',
      icons: [iconMap.intercom, iconMap.crm],
      stage: 'coming_soon',
      name: 'coming soon',
      benefits: [
        'Reduce onboarding time by 75%',
        'Increase product adoption by 65%',
        'Save 40+ hours per month on setup',
        'Improve customer satisfaction by 45%',
        'Decrease support tickets by 60%'
      ],
      pricing: {
        setup: 649,
        monthly: 129,
        perUser: 15,
        additionalWorkflows: 49,
        customIntegration: 299
      },
      included: [
        'Welcome sequence automation',
        'Product tour creation',
        'Progress tracking',
        'Customer segmentation',
        'Milestone tracking',
        'Integration setup'
      ],
      deliverables: [
        'Onboarding strategy document',
        '3 custom welcome sequences',
        '5 automated workflows',
        'Product tour setup',
        'Progress tracking dashboard',
        'Monthly performance reports',
        'Quarterly strategy review'
      ],
      workflow: [
        {
          stage: 'Setup',
          duration: '3-4 days',
          tasks: [
            'Account configuration',
            'Integration setup',
            'Workflow mapping',
            'Template creation',
            'Customer segmentation setup'
          ]
        },
        {
          stage: 'Content Creation',
          duration: '4-6 days',
          tasks: [
            'Welcome message creation',
            'Product tour design',
            'Help documentation setup',
            'Video tutorial creation',
            'Milestone definition'
          ]
        },
        {
          stage: 'Testing',
          duration: '2-3 days',
          tasks: [
            'Workflow testing',
            'Integration testing',
            'User path validation',
            'Milestone tracking verification',
            'Analytics setup'
          ]
        }
      ],
      maintenance: {
        daily: [
          'Monitor onboarding progress',
          'Check completion rates',
          'Review support requests',
          'Track engagement metrics'
        ],
        weekly: [
          'Workflow optimization',
          'Content updates',
          'Performance reporting',
          'Bottleneck analysis'
        ],
        monthly: [
          'Strategy review',
          'Content optimization',
          'Process refinement',
          'Success metrics analysis'
        ]
      },
      metrics: {
        primary: [
          'Time to value',
          'Completion rate',
          'Product adoption rate',
          'Customer satisfaction score',
          'Support ticket volume'
        ],
        secondary: [
          'Feature adoption rate',
          'Drop-off points',
          'Resource usage',
          'Engagement scores',
          'Customer feedback ratings'
        ]
      },
      bestPractices: [
        'Personalize onboarding paths',
        'Set clear milestones',
        'Provide self-service resources',
        'Monitor engagement signals',
        'Regular feedback collection',
        'Proactive support triggers',
        'Clear success metrics',
        'Continuous optimization'
      ],
      turnaroundDays: 10,
      stripeLink: 'https://buy.stripe.com/test_yourLinkHere64',
      support: {
        included: [
          'Email support (24/7)',
          'Chat support (business hours)',
          'Monthly strategy calls',
          'Emergency response (< 4 hours)',
          'Training sessions'
        ],
        sla: {
          responseTime: '< 4 hours',
          uptime: '99.9%',
          issueResolution: '< 24 hours'
        }
      },
      exampleOutputs: [
        {
          type: 'welcome_sequence',
          description: 'Product Welcome Flow',
          imageUrl: 'https://files.reallygoodemails.com/emails/welcome-to-superhuman.png',
          specs: {
            format: 'Multi-channel',
            duration: '14 days',
            touchpoints: 'Email, In-app, Chat'
          }
        },
        {
          type: 'dashboard',
          description: 'Onboarding Progress Dashboard',
          imageUrl: 'https://reallygoodemails.com/_next/image?url=https%3A%2F%2Ffiles.reallygoodemails.com%2Femails%2Fwelcome-to-notion.png&w=384&q=75',
          specs: {
            metrics: ['Completion', 'Engagement', 'Support needs'],
            timeRange: 'Real-time',
            exportFormats: ['PDF', 'CSV']
          }
        },
        {
          type: 'product_tour',
          description: 'Interactive Product Tour',
          imageUrl: 'https://reallygoodemails.com/_next/image?url=https%3A%2F%2Ffiles.reallygoodemails.com%2Femails%2Fwelcome-to-slack.png&w=384&q=75',
          specs: {
            format: 'Interactive guide',
            steps: 8,
            customization: 'Per user segment'
          }
        }
      ],
      automationRecipes: {
        make: {
          name: 'Customer Onboarding Automation',
          url: 'https://www.make.com/en/templates/customer-onboarding',
          description: 'End-to-end onboarding workflow automation',
          modules: [
            'Intercom',
            'CRM Integration',
            'Email',
            'Slack',
            'Analytics'
          ],
          complexity: 'Medium',
          estimatedSetupTime: '60 minutes'
        },
        n8n: {
          name: 'Onboarding Workflow Manager',
          url: 'https://n8n.io/workflows/onboarding-manager',
          description: 'Automated customer onboarding process',
          nodes: [
            'Intercom Trigger',
            'CRM Node',
            'Email Node',
            'Progress Tracker',
            'Notification Node'
          ],
          complexity: 'Advanced',
          estimatedSetupTime: '90 minutes'
        }
      },
      requirements: {
        fromClient: [
          {
            name: "Onboarding goals",
            example: "Define key milestones and success criteria for customer onboarding"
          },
          {
            name: "Product details",
            example: "Provide key features, benefits, and common usage patterns"
          },
          {
            name: "Customer segments",
            example: "Define different user types and their specific needs"
          },
          {
            name: "Success metrics",
            example: "Specify how you measure successful customer onboarding"
          }
        ],
        technical: [
          {
            name: "Intercom credentials",
            example: "Provide API keys or admin access to your Intercom account"
          },
          {
            name: "CRM access",
            example: "Share necessary login credentials for your CRM system"
          }
        ]
      }
    },
    {
      title: 'Support Ticket Management: Automate ticket routing and prioritization',
      tools: ['Zendesk', 'Slack'],
      usedBy: '23.2k users',
      icons: [iconMap.zendesk, iconMap.slack],
      stage: 'coming_soon',
      benefits: [
        'Reduce response time by 65%',
        'Improve first-contact resolution',
        'Better workload distribution',
        'Enhanced customer satisfaction'
      ],
      pricing: {
        setup: 649,
        monthly: 129,
        perAgent: 15
      },
      included: [
        'Smart ticket routing',
        'Priority scoring',
        'SLA management',
        'Auto-categorization',
        'Response templates',
        'Performance analytics',
        'Team collaboration tools',
        'Customer history tracking'
      ],
      turnaroundDays: 4,
      stripeLink: 'https://buy.stripe.com/test_yourLinkHere53'
    },
    {
      title: 'Customer Feedback: Automated surveys and feedback collection',
      tools: ['Typeform', 'CRM'],
      usedBy: '21.8k users',
      icons: [iconMap.typeform, iconMap.crm],
      stage: 'coming_soon',
    },
    {
      title: 'Usage Analytics: Track and report on product usage patterns',
      tools: ['Mixpanel', 'Sheets'],
      usedBy: '20.5k users',
      icons: [iconMap.mixpanel, iconMap.sheets],
      stage: 'coming_soon',
    },
    {
      title: 'Customer Health Monitoring: Automated alerts and interventions',
      tools: ['Intercom', 'Slack'],
      usedBy: '19.2k users',
      icons: [iconMap.intercom, iconMap.slack],
      stage: 'coming_soon',
    },
    {
      title: 'Knowledge Base Management: Automate content updates and suggestions',
      tools: ['Zendesk', 'AI Assistant'],
      usedBy: '18.4k users',
      icons: [iconMap.zendesk, iconMap.ai],
      stage: 'coming_soon',
    },
    {
      title: 'QBR Preparation: Automated quarterly business review reports',
      tools: ['CRM', 'Sheets'],
      usedBy: '16.8k users',
      icons: [iconMap.crm, iconMap.sheets],
      stage: 'coming_soon',
      benefits: [
        'Save 15+ hours per QBR cycle',
        'Improve data accuracy by 95%',
        'Automate data collection and visualization',
        'Better strategic insights'
      ],
      pricing: {
        setup: 599,
        monthly: 119,
        perReport: 25
      },
      included: [
        'Data aggregation automation',
        'Custom report templates',
        'Historical trend analysis',
        'Goal tracking dashboard',
        'Executive summary generation',
        'Recommendation engine',
        'Stakeholder collaboration tools',
        'Presentation builder'
      ],
      turnaroundDays: 4,
      stripeLink: 'https://buy.stripe.com/test_yourLinkHere56'
    },
    {
      title: 'Churn Prevention: Early warning system and intervention workflows',
      tools: ['Intercom', 'Mixpanel'],
      usedBy: '16.1k users',
      icons: [iconMap.intercom, iconMap.mixpanel],
      stage: 'coming_soon',
      benefits: [
        'Reduce churn rate by 45%',
        'Identify at-risk customers 30 days earlier',
        'Automate intervention workflows',
        'Increase customer retention'
      ],
      pricing: {
        setup: 749,
        monthly: 149,
        perThousandUsers: 15
      },
      included: [
        'Risk scoring algorithm',
        'Behavioral analysis',
        'Automated alerts',
        'Intervention playbooks',
        'Usage pattern monitoring',
        'Customer health dashboard',
        'Success team notifications',
        'ROI tracking'
      ],
      turnaroundDays: 5,
      stripeLink: 'https://buy.stripe.com/test_yourLinkHere57'
    },
    {
      title: 'SLA Monitoring: Track and report on service level agreements',
      tools: ['Zendesk', 'Sheets'],
      usedBy: '14.5k users',
      icons: [iconMap.zendesk, iconMap.sheets],
      stage: 'coming_soon'
    },
    {
      title: 'Customer Advocacy: Manage and track customer references',
      tools: ['CRM', 'Airtable'],
      usedBy: '13.7k users',
      icons: [iconMap.crm, iconMap.airtable],
      stage: 'coming_soon'
    },
    {
      title: 'Feature Requests: Track and prioritize customer feedback',
      tools: ['Typeform', 'Airtable'],
      usedBy: '12.9k users',
      icons: [iconMap.typeform, iconMap.airtable],
      stage: 'coming_soon'
    },
    {
      title: 'Customer Segmentation: Automated customer grouping and targeting',
      tools: ['CRM', 'Mixpanel'],
      usedBy: '12.1k users',
      icons: [iconMap.crm, iconMap.mixpanel],
      stage: 'coming_soon'
    },
    {
      title: 'Success Planning: Automated success plan creation and tracking',
      tools: ['CRM', 'Sheets'],
      usedBy: '11.3k users',
      icons: [iconMap.crm, iconMap.sheets],
      stage: 'coming_soon'
    },

    // Project Management (15 items)
    {
      title: 'Task Automation: Automated task creation and assignment',
      tools: ['Asana', 'Slack'],
      usedBy: '22.7k users',
      icons: [iconMap.asana, iconMap.slack],
      stage: 'coming_soon'
    },
    {
      title: 'Project Timeline: Automated timeline updates and notifications',
      tools: ['Jira', 'Slack'],
      usedBy: '21.4k users',
      icons: [iconMap.jira, iconMap.slack],
      stage: 'coming_soon'
    },
    {
      title: 'Resource Management: Automated resource allocation and tracking',
      tools: ['Asana', 'Sheets'],
      usedBy: '20.1k users',
      icons: [iconMap.asana, iconMap.sheets],
      stage: 'coming_soon'
    },
    {
      title: 'Project Reporting: Automated status reports and dashboards',
      tools: ['Jira', 'Sheets'],
      usedBy: '19.3k users',
      icons: [iconMap.jira, iconMap.sheets],
      stage: 'coming_soon'
    },
    {
      title: 'Time Tracking: Automated time logging and reporting',
      tools: ['Jira', 'Sheets'],
      usedBy: '18.5k users',
      icons: [iconMap.jira, iconMap.sheets],
      stage: 'coming_soon'
    },
    {
      title: 'Document Management: Automated file organization and sharing',
      tools: ['GDrive', 'Slack'],
      usedBy: '17.7k users',
      icons: [iconMap.gdrive, iconMap.slack],
      stage: 'coming_soon'
    },
    {
      title: 'Meeting Management: Automated agenda and minutes',
      tools: ['Calendar', 'Notion'],
      usedBy: '16.9k users',
      icons: [iconMap.calendar, iconMap.notion],
      stage: 'coming_soon'
    },
    {
      title: 'Risk Management: Automated risk tracking and alerts',
      tools: ['Jira', 'Slack'],
      usedBy: '16.1k users',
      icons: [iconMap.jira, iconMap.slack],
      stage: 'coming_soon'
    },
    {
      title: 'Budget Tracking: Automated expense tracking and reporting',
      tools: ['Sheets', 'Slack'],
      usedBy: '15.3k users',
      icons: [iconMap.sheets, iconMap.slack],
      stage: 'coming_soon'
    },
    {
      title: 'Team Collaboration: Automated team updates and coordination',
      tools: ['Slack', 'Asana'],
      usedBy: '14.5k users',
      icons: [iconMap.slack, iconMap.asana],
      stage: 'coming_soon'
    },
    {
      title: 'Quality Assurance: Automated testing and bug tracking',
      tools: ['Jira', 'GitHub'],
      usedBy: '13.7k users',
      icons: [iconMap.jira, iconMap.github],
      stage: 'coming_soon'
    },
    {
      title: 'Stakeholder Communication: Automated updates and reports',
      tools: ['Slack', 'Sheets'],
      usedBy: '12.9k users',
      icons: [iconMap.slack, iconMap.sheets],
      stage: 'coming_soon'
    },
    {
      title: 'Project Templates: Automated project setup and planning',
      tools: ['Asana', 'Notion'],
      usedBy: '12.1k users',
      icons: [iconMap.asana, iconMap.notion],
      stage: 'coming_soon'
    },
    {
      title: 'Dependencies Management: Track and manage project dependencies',
      tools: ['Jira', 'Slack'],
      usedBy: '11.3k users',
      icons: [iconMap.jira, iconMap.slack],
      stage: 'coming_soon'
    },
    {
      title: 'Project Analytics: Automated metrics and insights',
      tools: ['Jira', 'Sheets'],
      usedBy: '10.5k users',
      icons: [iconMap.jira, iconMap.sheets],
      stage: 'coming_soon'
    },

    // HR & Recruiting (15 items)
    {
      title: 'Applicant Tracking: Automated candidate pipeline management',
      tools: ['Greenhouse', 'Gmail'],
      usedBy: '21.6k users',
      icons: [iconMap.greenhouse, iconMap.gmail],
      stage: 'coming_soon'
    },
    {
      title: 'Interview Scheduling: Automated interview coordination',
      tools: ['Greenhouse', 'Calendar'],
      usedBy: '20.3k users',
      icons: [iconMap.greenhouse, iconMap.calendar],
      stage: 'coming_soon'
    },
    {
      title: 'Onboarding Process: Automated employee onboarding workflows',
      tools: ['BambooHR', 'Slack'],
      usedBy: '19.5k users',
      icons: [iconMap.bamboohr, iconMap.slack],
      stage: 'coming_soon'
    },
    {
      title: 'Performance Reviews: Automated review cycles and feedback',
      tools: ['BambooHR', 'Forms'],
      usedBy: '18.7k users',
      icons: [iconMap.bamboohr, iconMap.forms],
      stage: 'coming_soon'
    },
    {
      title: 'Time Off Management: Automated leave requests and tracking',
      tools: ['BambooHR', 'Calendar'],
      usedBy: '17.9k users',
      icons: [iconMap.bamboohr, iconMap.calendar],
      stage: 'coming_soon'
    },
    {
      title: 'Employee Documentation: Automated document management',
      tools: ['BambooHR', 'DocuSign'],
      usedBy: '17.1k users',
      icons: [iconMap.bamboohr, iconMap.docusign],
      stage: 'coming_soon'
    },
    {
      title: 'Recruitment Marketing: Automated job posting and promotion',
      tools: ['Greenhouse', 'LinkedIn'],
      usedBy: '16.3k users',
      icons: [iconMap.greenhouse, iconMap.linkedin],
      stage: 'coming_soon'
    },
    {
      title: 'Background Checks: Automated verification processes',
      tools: ['Greenhouse', 'Workday'],
      usedBy: '15.5k users',
      icons: [iconMap.greenhouse, iconMap.workday],
      stage: 'coming_soon'
    },
    {
      title: 'Employee Surveys: Automated feedback collection',
      tools: ['Forms', 'Slack'],
      usedBy: '14.7k users',
      icons: [iconMap.forms, iconMap.slack],
      stage: 'coming_soon'
    },
    {
      title: 'Benefits Administration: Automated benefits management',
      tools: ['Workday', 'BambooHR'],
      usedBy: '13.9k users',
      icons: [iconMap.workday, iconMap.bamboohr],
      stage: 'coming_soon'
    },
    {
      title: 'Training Management: Automated learning and development',
      tools: ['Workday', 'Calendar'],
      usedBy: '13.1k users',
      icons: [iconMap.workday, iconMap.calendar],
      stage: 'coming_soon'
    },
    {
      title: 'Compliance Tracking: Automated compliance monitoring',
      tools: ['BambooHR', 'Sheets'],
      usedBy: '12.3k users',
      icons: [iconMap.bamboohr, iconMap.sheets],
      stage: 'coming_soon'
    },
    {
      title: 'Employee Offboarding: Automated exit processes',
      tools: ['BambooHR', 'Slack'],
      usedBy: '11.5k users',
      icons: [iconMap.bamboohr, iconMap.slack],
      stage: 'coming_soon'
    },
    {
      title: 'Payroll Processing: Automated salary and tax calculations',
      tools: ['QuickBooks', 'Workday'],
      usedBy: '17.3k users',
      icons: [iconMap.quickbooks, iconMap.workday],
      stage: 'coming_soon',
      benefits: [
        'Cut payroll processing time by 85%',
        'Eliminate calculation errors',
        'Improve tax compliance by 99%',
        'Reduce payroll inquiries by 75%',
        'Save 20+ hours per payroll cycle'
      ],
      pricing: {
        setup: 799,
        monthly: 159,
        perEmployee: 8
      },
      included: [
        'Salary calculation engine',
        'Tax withholding automation',
        'Benefits deduction management',
        'Direct deposit automation',
        'Compliance reporting',
        'Employee self-service portal',
        'Time tracking integration',
        'Multi-state tax handling',
        'Year-end tax form automation',
        'Garnishment processing',
        'Leave accrual tracking',
        'Overtime calculations',
        'Custom payment schedules',
        'Audit trail maintenance'
      ],
      integrations: [
        'Time tracking systems',
        'Accounting software',
        'HR management platforms',
        'Benefits providers',
        'Banking systems'
      ],
      compliance: [
        'FLSA compliance',
        'State tax regulations',
        'Federal tax requirements',
        'ACA reporting',
        'Workers comp reporting'
      ],
      turnaroundDays: 5,
      stripeLink: 'https://buy.stripe.com/test_yourLinkHere41'
    },
    {
      title: 'HR Analytics: Automated HR metrics and reporting',
      tools: ['BambooHR', 'Sheets'],
      usedBy: '9.9k users',
      icons: [iconMap.bamboohr, iconMap.sheets],
      stage: 'coming_soon'
    },

    // Finance & Accounting (15 items)
    {
      title: 'Invoice Processing: Automated invoice creation and sending',
      tools: ['QuickBooks', 'Stripe'],
      usedBy: '23.4k users',
      icons: [iconMap.quickbooks, iconMap.stripe],
      stage: 'coming_soon',
      benefits: [
        'Reduce invoice processing time by 85%',
        'Get paid 12 days faster on average',
        'Eliminate manual billing errors'
      ],
      pricing: {
        setup: 649,
        monthly: 129
      },
      included: [
        'Smart invoice generation',
        'Payment reminder automation',
        'Late payment detection',
        'Multi-currency support',
        'Payment reconciliation'
      ],
      turnaroundDays: 4,
      stripeLink: 'https://buy.stripe.com/test_yourLinkHere34'
    },
    {
      title: 'Expense Management: Automated expense tracking and reporting',
      tools: ['Expensify', 'QuickBooks'],
      usedBy: '22.1k users',
      icons: [iconMap.expensify, iconMap.quickbooks],
      stage: 'coming_soon',
      benefits: [
        'Cut expense processing time by 80%',
        'Improve policy compliance by 95%',
        'Reduce reimbursement time by 70%'
      ],
      pricing: {
        setup: 549,
        monthly: 109
      },
      included: [
        'Receipt scanning & OCR',
        'Expense categorization',
        'Policy enforcement',
        'Approval workflows',
        'Expense analytics'
      ],
      turnaroundDays: 3,
      stripeLink: 'https://buy.stripe.com/test_yourLinkHere35'
    },
    {
      title: 'Financial Reporting: Automated financial statement generation',
      tools: ['QuickBooks', 'Sheets'],
      usedBy: '21.3k users',
      icons: [iconMap.quickbooks, iconMap.sheets],
      stage: 'coming_soon',
      benefits: [
        'Save 40+ hours per month on reporting',
        'Improve reporting accuracy by 99%',
        'Real-time financial insights'
      ],
      pricing: {
        setup: 799,
        monthly: 159
      },
      included: [
        'Custom report templates',
        'Multi-entity consolidation',
        'GAAP compliance checks',
        'Automated reconciliation',
        'Board report generation'
      ],
      turnaroundDays: 5,
      stripeLink: 'https://buy.stripe.com/test_yourLinkHere36'
    },
    {
      title: 'Bank Reconciliation: Automated transaction matching',
      tools: ['QuickBooks', 'Xero'],
      usedBy: '20.5k users',
      icons: [iconMap.quickbooks, iconMap.xero],
      stage: 'coming_soon',
      benefits: [
        'Reduce reconciliation time by 90%',
        'Improve accuracy to 99.9%',
        'Real-time cash position tracking'
      ],
      pricing: {
        setup: 599,
        monthly: 119
      },
      included: [
        'Smart transaction matching',
        'Bank feed automation',
        'Exception handling',
        'Audit trail creation',
        'Cash flow reporting'
      ],
      turnaroundDays: 4,
      stripeLink: 'https://buy.stripe.com/test_yourLinkHere37'
    },
    {
      title: 'Tax Preparation: Automated tax document collection',
      tools: ['QuickBooks', 'TaxJar'],
      usedBy: '19.7k users',
      icons: [iconMap.quickbooks, iconMap.taxjar],
      stage: 'coming_soon',
      benefits: [
        'Reduce tax prep time by 75%',
        'Improve tax compliance by 95%',
        'Minimize audit risks'
      ],
      pricing: {
        setup: 749,
        monthly: 149
      },
      included: [
        'Document collection automation',
        'Tax calculation engine',
        'Multi-state compliance',
        'Deadline management',
        'Audit support package'
      ],
      turnaroundDays: 5,
      stripeLink: 'https://buy.stripe.com/test_yourLinkHere38'
    },
    {
      title: 'Accounts Payable: Automated bill payment and tracking',
      tools: ['QuickBooks', 'Stripe'],
      usedBy: '18.9k users',
      icons: [iconMap.quickbooks, iconMap.stripe],
      stage: 'coming_soon',
      benefits: [
        'Reduce processing costs by 80%',
        'Eliminate duplicate payments',
        'Improve vendor relationships'
      ],
      pricing: {
        setup: 699,
        monthly: 139
      },
      included: [
        'Invoice capture & OCR',
        'Approval workflows',
        'Payment scheduling',
        'Vendor portal',
        'Cash flow optimization'
      ],
      turnaroundDays: 4,
      stripeLink: 'https://buy.stripe.com/test_yourLinkHere39'
    },
    {
      title: 'Accounts Receivable: Automated payment collection',
      tools: ['QuickBooks', 'Stripe'],
      usedBy: '18.1k users',
      icons: [iconMap.quickbooks, iconMap.stripe],
      stage: 'coming_soon',
      benefits: [
        'Reduce DSO by 45%',
        'Improve collection rate by 60%',
        'Automate 95% of follow-ups'
      ],
      pricing: {
        setup: 649,
        monthly: 129
      },
      included: [
        'Payment reminder system',
        'Customer payment portal',
        'Aging analysis',
        'Collection automation',
        'Credit risk monitoring'
      ],
      turnaroundDays: 4,
      stripeLink: 'https://buy.stripe.com/test_yourLinkHere40'
    },
    {
      title: 'Financial Planning: Automated forecasting and analysis',
      tools: ['QuickBooks', 'Sheets'],
      usedBy: '14.1k users',
      icons: [iconMap.quickbooks, iconMap.sheets],
      stage: 'coming_soon',
      benefits: [
        'Improve forecast accuracy by 65%',
        'Reduce planning time by 70%',
        'Better decision making'
      ],
      pricing: {
        setup: 749,
        monthly: 149
      },
      included: [
        'Scenario modeling',
        'Budget vs actual analysis',
        'Trend identification',
        'KPI tracking',
        'Executive dashboards'
      ],
      turnaroundDays: 5,
      stripeLink: 'https://buy.stripe.com/test_yourLinkHere42'
    },
    {
      title: 'Audit Trail: Automated transaction logging and tracking',
      tools: ['QuickBooks', 'Sheets'],
      usedBy: '13.3k users',
      icons: [iconMap.quickbooks, iconMap.sheets],
      stage: 'coming_soon',
      benefits: [
        'Achieve 100% transaction tracking',
        'Reduce audit prep time by 80%',
        'Enhance fraud detection'
      ],
      pricing: {
        setup: 599,
        monthly: 119
      },
      included: [
        'Transaction logging',
        'Change tracking',
        'Access control monitoring',
        'Audit report generation',
        'Compliance documentation'
      ],
      turnaroundDays: 4,
      stripeLink: 'https://buy.stripe.com/test_yourLinkHere43'
    },
    {
      title: 'Revenue Recognition: Automated revenue tracking',
      tools: ['QuickBooks', 'Sheets'],
      usedBy: '12.5k users',
      icons: [iconMap.quickbooks, iconMap.sheets],
      stage: 'coming_soon',
      benefits: [
        'Ensure ASC 606 compliance',
        'Reduce recognition errors by 95%',
        'Save 30+ hours monthly'
      ],
      pricing: {
        setup: 849,
        monthly: 169
      },
      included: [
        'Revenue schedule automation',
        'Performance obligation tracking',
        'Contract modification handling',
        'Deferred revenue management',
        'Recognition rules engine'
      ],
      turnaroundDays: 6,
      stripeLink: 'https://buy.stripe.com/test_yourLinkHere44'
    },
    {
      title: 'Financial Compliance: Automated compliance monitoring',
      tools: ['QuickBooks', 'Sheets'],
      usedBy: '11.7k users',
      icons: [iconMap.quickbooks, iconMap.sheets],
      stage: 'coming_soon',
      benefits: [
        'Reduce compliance risks by 85%',
        'Cut monitoring time by 70%',
        'Real-time violation alerts'
      ],
      pricing: {
        setup: 749,
        monthly: 149
      },
      included: [
        'Compliance rule engine',
        'Regulatory update tracking',
        'Violation detection',
        'Audit preparation',
        'Compliance reporting'
      ],
      turnaroundDays: 5,
      stripeLink: 'https://buy.stripe.com/test_yourLinkHere45'
    },

    // Additional unique automations from old set
    {
      title: 'Client Engagement: Schedule follow-up meetings and share success stories',
      tools: ['CRM', 'Calendar'],
      usedBy: '9.6k users',
      icons: [iconMap.crm, iconMap.calendar],
      stage: 'coming_soon',
      benefits: [
        'Increase meeting attendance by 65%',
        'Improve client retention by 40%',
        'Save 15+ hours per month on scheduling',
        'Better relationship management'
      ],
      pricing: {
        setup: 499,
        monthly: 99
      },
      included: [
        'Automated meeting scheduling',
        'Success story templates',
        'Follow-up reminders',
        'Meeting analytics',
        'Client history tracking',
        'Calendar integration',
        'Meeting preparation briefs'
      ],
      turnaroundDays: 3,
      stripeLink: 'https://buy.stripe.com/test_yourLinkHere58'
    },
    {
      title: 'Enhanced Relationships: Boost client satisfaction and improve employee UX',
      tools: ['CRM', 'Intercom'],
      usedBy: '8.9k users',
      icons: [iconMap.crm, iconMap.intercom],
      stage: 'coming_soon',
      benefits: [
        'Increase client satisfaction by 55%',
        'Reduce response time by 75%',
        'Improve employee productivity by 40%',
        'Better client insights'
      ],
      pricing: {
        setup: 649,
        monthly: 129,
        perUser: 15
      },
      included: [
        'Automated client touchpoints',
        'Satisfaction monitoring',
        'Employee feedback system',
        'Performance analytics',
        'Client journey mapping',
        'Interaction history',
        'Sentiment analysis'
      ],
      turnaroundDays: 4,
      stripeLink: 'https://buy.stripe.com/test_yourLinkHere59'
    },
    {
      title: 'Organize Info: Compile and organize client details in your system',
      tools: ['Airtable', 'CRM'],
      usedBy: '7.5k users',
      icons: [iconMap.airtable, iconMap.crm],
      stage: 'coming_soon',
      benefits: [
        'Reduce data entry time by 80%',
        'Improve data accuracy by 95%',
        'Better data accessibility',
        'Enhanced reporting capabilities'
      ],
      pricing: {
        setup: 549,
        monthly: 109
      },
      included: [
        'Automated data collection',
        'Custom field mapping',
        'Data validation rules',
        'Duplicate detection',
        'Search optimization',
        'Data visualization',
        'Export capabilities'
      ],
      turnaroundDays: 3,
      stripeLink: 'https://buy.stripe.com/test_yourLinkHere60'
    },
    {
      title: 'Client Support: Provide answers to client questions through onboarding',
      tools: ['Intercom', 'Zendesk'],
      usedBy: '8.3k users',
      icons: [iconMap.intercom, iconMap.zendesk],
      stage: 'coming_soon',
      name: 'coming soon',
      benefits: [
        'Reduce support tickets by 60%',
        'Improve onboarding completion by 45%',
        'Faster time-to-value for clients',
        'Better self-service rates'
      ],
      pricing: {
        setup: 599,
        monthly: 119,
        perUser: 10
      },
      included: [
        'Knowledge base automation',
        'Smart FAQ system',
        'Onboarding workflows',
        'Progress tracking',
        'Support ticket deflection',
        'Customer feedback loops',
        'Usage analytics'
      ],
      turnaroundDays: 4,
      stripeLink: 'https://buy.stripe.com/test_yourLinkHere61'
    },
    {
      title: 'Improved Experience: Reduce response times and increase client satisfaction',
      tools: ['Intercom', 'Zendesk'],
      usedBy: '9.7k users',
      icons: [iconMap.intercom, iconMap.zendesk],
      stage: 'coming_soon',
      benefits: [
        'Cut response time by 85%',
        'Increase satisfaction scores by 50%',
        'Improve first-contact resolution',
        'Better support efficiency'
      ],
      pricing: {
        setup: 699,
        monthly: 139,
        perAgent: 20
      },
      included: [
        'AI-powered responses',
        'Smart routing system',
        'SLA management',
        'CSAT automation',
        'Support analytics',
        'Ticket prioritization',
        'Performance dashboards'
      ],
      turnaroundDays: 4,
      stripeLink: 'https://buy.stripe.com/test_yourLinkHere62'
    },
    {
      title: 'Enhanced Support: Strengthen project management and reduce churn',
      tools: ['Asana', 'Slack'],
      usedBy: '10.8k users',
      icons: [iconMap.asana, iconMap.slack],
      stage: 'coming_soon',
      benefits: [
        'Reduce project delays by 55%',
        'Decrease churn rate by 40%',
        'Improve team productivity by 35%',
        'Better project visibility'
      ],
      pricing: {
        setup: 749,
        monthly: 149,
        perProject: 25
      },
      included: [
        'Project templates',
        'Automated workflows',
        'Progress tracking',
        'Risk alerts',
        'Resource management',
        'Team collaboration tools',
        'Client reporting'
      ],
      turnaroundDays: 5,
      stripeLink: 'https://buy.stripe.com/test_yourLinkHere63'
    },
    {
      title: 'Strategy For In-App Ads',
      tools: ['AI Assistant'],
      usedBy: 'New',
      icons: [iconMap.ai],
      stage: 'coming_soon',
      benefits: [
        'Save time with pre-written prompts',
        'Consistent quality output',
        'Proven templates'
      ],
      pricing: {
        setup: 49,
        monthly: 0
      },
      included: [
        'Customizable prompt template',
        'Usage instructions',
        'Best practices guide'
      ],
      turnaroundDays: 1,
      stripeLink: 'https://members.clickminded.com/prompt/217/',
      type: 'prompt'
    },
    {
      title: 'Show Appreciation To All Customers',
      tools: ['AI Assistant'],
      usedBy: 'New',
      icons: [iconMap.ai],
      stage: 'coming_soon',
      benefits: [
        'Save time with pre-written prompts',
        'Consistent quality output',
        'Proven templates'
      ],
      pricing: {
        setup: 49,
        monthly: 0
      },
      included: [
        'Customizable prompt template',
        'Usage instructions',
        'Best practices guide'
      ],
      turnaroundDays: 1,
      stripeLink: 'https://members.clickminded.com/prompt/252/',
      type: 'prompt'
    },
    {
      title: 'Interactive Activities For Social Media',
      tools: ['AI Assistant'],
      usedBy: 'New',
      icons: [iconMap.ai],
      stage: 'coming_soon',
      benefits: [
        'Save time with pre-written prompts',
        'Consistent quality output',
        'Proven templates'
      ],
      pricing: {
        setup: 49,
        monthly: 0
      },
      included: [
        'Customizable prompt template',
        'Usage instructions',
        'Best practices guide'
      ],
      turnaroundDays: 1,
      stripeLink: 'https://members.clickminded.com/prompt/147/',
      type: 'prompt'
    }
  ];