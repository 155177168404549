import { Fragment, useEffect, useState, useContext, useRef, useMemo } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import Cookies from 'js-cookie';
import Fuse from 'fuse.js';
import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton from 'react-loading-skeleton';
import Progress from './Progress';
import { setShowNotif, setContents, currencyFormat } from './helpers.js';
import {
  ArrowUpCircleIcon,
  ArrowTopRightOnSquareIcon,
  ExclamationTriangleIcon,
  ChevronDownIcon,
  MagnifyingGlassIcon,
  MagnifyingGlassCircleIcon,
  LockClosedIcon,
} from '@heroicons/react/20/solid';
import Automation from './Automation.js';
import useBase from './useBase.js';
import {
  Bars3Icon,
  PhoneIcon,
  Cog6ToothIcon,
  HomeIcon,
  XMarkIcon,
  BoltIcon,
  LightBulbIcon,
  CurrencyDollarIcon,
  BanknotesIcon,
  ClipboardDocumentListIcon,
  BuildingOffice2Icon,
  GiftIcon,
  TrophyIcon,
  IdentificationIcon,
  ArrowTrendingUpIcon,
  FolderIcon,
  GlobeAmericasIcon,
  ChevronRightIcon,
  ShoppingCartIcon,
  UsersIcon,
  LinkIcon
} from '@heroicons/react/24/outline';
import { useNavigate, useLocation } from 'react-router-dom';
import Airtable from 'airtable';
import Company from './Company';
import Onboarding from './Onboarding';
import Financing from './Financing';
import Notification from './Notification';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import {
  checkValidDomainName,
  currencyToNumber,
  checkEmail,
  getWebURL,
  classNames,
  extractDomain,
} from './helpers.js';
import { countryCodes, regionCodes } from './helperData';
import EmailModal from './EmailModal';
import SidePanel from './SidePanel';
import Summary from './Summary';
import UpgradeList from './UpgradeList';
import JITModal from './JITModal';
import IndustriesMappingModal from './IndustriesMappingModal.js';
import NotificationButtons from './NotificationButtons';
import AutomationContext from './AutomationContext';
import { UserButton, UserProfile, useUser } from '@clerk/clerk-react';
import Perks from './Perks.js';
import AllGrants from './AllGrants.jsx';
import PlansPage from './PlansPage.jsx';
import MyNetwork from './MyNetwork.js';
import { AppContext } from './AppContext.js';
import './App.css';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import { Disclosure } from '@headlessui/react';
import { InfinitySpin } from 'react-loader-spinner';


LogRocket.init('upgraded/upgraded-app');
setupLogRocketReact(LogRocket);

const navigationSections = [
  {
    name: 'Main',
    items: [
      { name: 'Dashboard', href: '#', icon: HomeIcon, current: false, pro: false },
      { name: 'Company Info', href: '#company-info', icon: IdentificationIcon, current: false, pro: false },
    ]
  },
  {
    name: 'Savings',
    items: [
      { name: 'Financing', href: '#financing', icon: BanknotesIcon, current: false, pro: false },
      { name: 'Vendor Savings', href: '#vendor-savings', icon: LightBulbIcon, current: false, pro: true },
      { name: 'Grants', href: '#grants', icon: CurrencyDollarIcon, current: false, pro: true },
      { name: 'Growth Upgrades', href: '#top-upgrades', icon: ArrowTrendingUpIcon, current: false, pro: false },
      { name: 'AI & Automations', href: '#automations', icon: BoltIcon, current: false, pro: true },

    ]
  },
  {
    name: 'Extras',
    items: [
      { name: 'My Reports', href: '#my-reports', icon: FolderIcon, current: false, pro: true },
      { name: 'Onboarding', href: '#onboarding', icon: ClipboardDocumentListIcon, current: false, pro: false },
      { name: 'Upgrade Orders', href: '#upgrade-orders', icon: ShoppingCartIcon, current: false, pro: false },
      { name: 'Team Earnings', href: '#team-earnings', icon: UsersIcon, current: false, pro: false },
    ]
  }
];

const people = [
  {
    name: 'Leslie Alexander',
    email: 'leslie.alexander@example.com',
    role: 'Co-Founder / CEO',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    href: '#',
    lastSeen: '3h ago',
    lastSeenDateTime: '2023-01-23T13:23Z',
  },
  {
    name: 'Michael Foster',
    email: 'michael.foster@example.com',
    role: 'Co-Founder / CTO',
    imageUrl:
      'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    href: '#',
    lastSeen: '3h ago',
    lastSeenDateTime: '2023-01-23T13:23Z',
  },
  {
    name: 'Dries Vincent',
    email: 'dries.vincent@example.com',
    role: 'Business Relations',
    imageUrl:
      'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    href: '#',
    lastSeen: null,
  },
  {
    name: 'Lindsay Walton',
    email: 'lindsay.walton@example.com',
    role: 'Front-end Developer',
    imageUrl:
      'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    href: '#',
    lastSeen: '3h ago',
    lastSeenDateTime: '2023-01-23T13:23Z',
  },
  {
    name: 'Courtney Henry',
    email: 'courtney.henry@example.com',
    role: 'Designer',
    imageUrl:
      'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    href: '#',
    lastSeen: '3h ago',
    lastSeenDateTime: '2023-01-23T13:23Z',
  },
  {
    name: 'Tom Cook',
    email: 'tom.cook@example.com',
    role: 'Director of Product',
    imageUrl:
      'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    href: '#',
    lastSeen: null,
  },
]

const statuses = {
  Complete: 'text-green-700 bg-green-50 ring-green-600/20',
  'In Progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
  "Not Started": 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
}

const userNavigation = [
  {
    name: 'All Company Info',
    href: `/company-info/${getQueryVariable('website')}`,
  },
];

const formatDate = (isoString) => {
  const randomDays = Math.floor(Math.random() * (7 - 3 + 1)) + 3;
  const newDate = moment(isoString).add(randomDays, 'days');
  return newDate.format('ddd, MMM Do');
};

const tabs = [
  { name: 'Grants', href: '#earnings-pipeline', current: true, categories: ["Grant"] },
  { name: 'Vendor Savings', href: '#earnings-pipeline', current: false, categories: ["Tech", "Tech (require manual redemption)", "Tech (only revealed, not redeemed)", "Tech (redeemed by builtfirst api before)", "Tech (redeemed by builtfirst api)"] },
  { name: 'AI & Automations', href: '#earnings-pipeline', current: false, categories: ["Automation"] },
  { name: 'Growth Upgrades', href: '#earnings-pipeline', current: false, categories: ["Top Upgrade"] },
]

const profilePics = [
  "https://media.licdn.com/dms/image/v2/D4E03AQHQ-y6D0772hA/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1704393407867?e=1743033600&v=beta&t=pILwPoNt0j25MpU5StuZGOdlyu1wMcFNAPZVa2ToSEo",
  "https://media.licdn.com/dms/image/v2/C5603AQGl9suKPS3CEQ/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1551218467652?e=1743033600&v=beta&t=IxEGesd21i8ZCbvUdcZLWWn3fLs7YgSRlzbSLOSCBkM",
  "https://media.licdn.com/dms/image/v2/D5603AQEGcWez6SOnyQ/profile-displayphoto-shrink_400_400/B56ZQ7moreGoAg-/0/1736166771936?e=1743033600&v=beta&t=jUSDlZwSmmb3LnHyEkQb-eDJ0o05ymwWWoSbTO8iPEU",
  "https://media.licdn.com/dms/image/v2/D5603AQEApqM7uYTkvA/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1700366456097?e=1743033600&v=beta&t=0x8aor8grea-vk9vdjMjBuyMb98ispQSNO0QSb7ZHc8",
  "https://media.licdn.com/dms/image/v2/D4D03AQFWIyZYq76PGA/profile-displayphoto-shrink_800_800/B4DZRv2sciG4Ag-/0/1737043397653?e=1743033600&v=beta&t=15uA5krHrYqDvxoGDEFxH01eE6wRBoD8M-wBtd2ImkE",
  "https://media.licdn.com/dms/image/v2/D5603AQGcg5r9cWL2Rg/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1713910652257?e=1743033600&v=beta&t=iddHW87ckwSJ2jMkWPJpEPK5OpwMCqO9a0h8zXaL-KU",
  "https://media.licdn.com/dms/image/v2/D4E03AQHqe4c2EA3cNg/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1696989646337?e=1743033600&v=beta&t=zhH-fAhwukgHojODGqHJZHZLIQ7TEw4fhzGLxR17gwQ",
  "https://media.licdn.com/dms/image/v2/D5603AQFeOClRWFkEVA/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1719007678335?e=1743033600&v=beta&t=tNjpiRTxb9GMprya7JFJKkeeA3tokJrulBda-Qhhmlc",
  "https://media.licdn.com/dms/image/v2/D5635AQFNITyJ4yAXpQ/profile-framedphoto-shrink_400_400/profile-framedphoto-shrink_400_400/0/1728600383823?e=1738357200&v=beta&t=p07RZXjV8f6WaqzS_qhD4d7UZ4ZsTuo57zP2KuTnrOs",
  "https://media.licdn.com/dms/image/v2/C4D03AQHCPfBqLbwjIA/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1517238819804?e=1743033600&v=beta&t=X1UCYSBKjLbtNq0phiwaq2Bzq5brvIP0jOD5Fq8uecI",
  "https://media.licdn.com/dms/image/v2/D5603AQFv0-rvsam5RQ/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1686339048577?e=1743033600&v=beta&t=aU8VdyqujDjHe7MRwZMkAt-W3hh5TT0bB3K7chtG3pY"
];

const emails = [
  'ercan@getupgraded.ca',
  'haris@getupgraded.ca',
  'will@getupgraded.ca',
  'jenny@getupgraded.ca',
  'ankit@getupgraded.ca',
  'arbri@getupgraded.ca',
  'matthew@getupgraded.ca',
  'fatma@getupgraded.ca',
  'youssef@getupgraded.ca',
  'jason@getupgraded.ca',
  'hooman@getupgraded.ca'
];


const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: '8px',
    borderColor: state.isFocused ? '#007aff' : '#e0e0e0', // Light gray border, blue on focus
    boxShadow: state.isFocused ? '0 0 0 2px rgba(0, 122, 255, 0.3)' : 'none', // Blue glow on focus
    padding: '6px 12px',
    transition: 'border-color 0.3s ease, box-shadow 0.3s ease',
    backgroundColor: '#f9f9f9', // Subtle off-white background
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#f0f0f5' : '#ffffff', // Light gray background on hover
    color: state.isSelected ? '#007aff' : '#333333', // Blue for selected, dark gray for text
    padding: '10px 15px',
    cursor: 'pointer',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#333333', // Dark gray text
    fontWeight: '500',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#b0b0b0', // Light gray arrow
  }),
  indicatorSeparator: () => ({
    display: 'none', // No separator between dropdown and clear indicator
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow for dropdown
    overflow: 'hidden',
  }),
};

const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;
const companiesTableId = process.env.REACT_APP_AIR_COMPANIES;
const usersTableId = process.env.REACT_APP_AIR_USERS;
const base = new Airtable({ apiKey: apiKey }).base(baseId);
// const admin = Cookies.get("domain") === "all" && getQueryVariable(process.env.REACT_APP_QUERY_SECRET_1) === "true";
const clayApiKey = process.env.REACT_APP_CLAY_API_KEY;

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (pair[0] == variable) {
      // LogRocket.identify("", {
      // 	name: "James Morrison",
      // 	email: "jamesmorrison@" + pair[1],
      // 	subscriptionType: "pro",
      // });
      return pair[1];
    }
  }
  return false;
}

function capitalize(str) {
  if (!str) return str; // This checks for null, undefined, and empty string
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// export function calculateTechSavings(company, discounts) {
//   if (!company?.fields?.Technologies || !discounts) return 0;
  
//   const technologies = company.fields.Technologies.split(',').map(tech => tech.trim());
//   let totalSavings = 0;

//   technologies.forEach(tech => {
//     const matchingDiscount = discounts.find(d => 
//       d.fields?.Title?.toLowerCase().trim() === tech.toLowerCase().trim()
//     );
    
//     if (matchingDiscount?.fields?.MaxSavings) {
//       const savings = currencyToNumber(matchingDiscount.fields.MaxSavings);
//       if (!isNaN(savings)) {
//         totalSavings += savings;
//       }
//     }
//   });

//   return totalSavings;
// }

// main application
export default function App() {
  const {
    company,
    setCompany,
    clerkUser,
    setClerkUser,
    userRecord,
    setUserRecord,
    open,
    setOpen,
    domainStatus,
    setDomainStatus,
    loading,
    setLoading,
    grants,
    setGrants,
    grantLoading,
    setGrantLoading,
    JITWorking,
    setJITWorking,
    unknownDomain,
    setUnknownDomain,
    matchedDiscounts,
    setMatchedDiscounts,
    totalTechSavings,
    setTotalTechSavings,
    eligibleGrants,
    setEligibleGrants,
    grantsSum,
    setGrantsSum,
    questions,
    answers,
  } = useContext(AppContext);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [submitEnrichNotif, setSubmitEnrichNotif] = useState({
    showNotif: false,
    contents: { type: '', heading: '', message: '' },
  });

  // global automation states used for endless fries
  const [automationPeople, setAutomationPeople] = useState({});
  const [selectedTab, setSelectedTab] = useState(tabs[0].name)
  const [megaUpgrades, setMegaUpgrades] = useState([]);
  const [filteredMegaUpgrades, setFilteredMegaUpgrades] = useState([]);

  const [selectedEmail, setSelectedEmail] = useState('ercan@getupgraded.ca');
  const [teamUpgrades, setTeamUpgrades] = useState([]);
  const [filteredTeamUpgrades, setFilteredTeamUpgrades] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState('Pending');

  const [moreStatuses, setMoreStatuses] = useState(
    filteredTeamUpgrades.reduce((acc, upgrade) => {
      acc[upgrade.id] = upgrade.fields.Status;
      console.log("acc", acc)
      console.log("upgrade", upgrade)
      return acc;
    }, {})
  );

  useEffect(() => {
    // Filter mega upgrades based on the selected tab
    if (!selectedTab || !megaUpgrades.length) {
      setFilteredMegaUpgrades([]);
      return;
    }

    const currentTab = tabs.find((tab) => tab.name === selectedTab);
    if (!currentTab) {
      setFilteredMegaUpgrades([]);
      return;
    }

    const filtered = megaUpgrades.filter((megaUpgrade) =>
      currentTab.categories.includes(megaUpgrade.fields.UpgradeType) &&
      megaUpgrade.fields.CompanyName === company?.fields?.Name
    ).filter((megaUpgrade, index, self) =>
      index === self.findIndex((m) => m.fields.UpgradeName === megaUpgrade.fields.UpgradeName)
    );

    setFilteredMegaUpgrades(filtered);

  }, [megaUpgrades, selectedTab, tabs, company]);

  useEffect(() => {
    // Filter mega upgrades based on the selected tab
    if (!selectedTab || !teamUpgrades.length) {
      setFilteredTeamUpgrades([]);
      return;
    }

    const currentTab = tabs.find((tab) => tab.name === selectedTab);
    if (!currentTab) {
      setFilteredTeamUpgrades([]);
      return;
    }

    const filtered = teamUpgrades.filter((teamUpgrade) =>
      currentTab.categories.includes(teamUpgrade.fields.UpgradeType)
    ).filter((teamUpgrade, index, self) =>
      index === self.findIndex((m) => m.fields.UpgradeName === teamUpgrade.fields.UpgradeName)
    );

    setFilteredTeamUpgrades(filtered);

  }, [teamUpgrades, selectedTab, tabs, company]);

  useEffect(() => {
    if (filteredTeamUpgrades && filteredTeamUpgrades.length > 0) {
      const initialStatuses = filteredTeamUpgrades.reduce((acc, upgrade) => {
        acc[upgrade.id] = upgrade.fields.Status;
        console.log("acc", acc); // Should now display populated data
        console.log("upgrade", upgrade);
        return acc;
      }, {});

      setMoreStatuses(initialStatuses);
    }
  }, [filteredTeamUpgrades]);

  // old auth section
  // const [signedUp, setSignedUp] = useState(false);
  // const [userEmail, setUserEmail] = useState("");
  // const [restricted, setRestricted] = useState(false);
  // const [emailCloseable, setEmailCloseable] = useState(false);

  // to create reactive states using cookies
  // deprecated bcuz new auth
  // useEffect(() => {
  // 	if (Cookies.get("signed_up")) {
  // 		setSignedUp(Cookies.get("signed_up"));
  // 	}
  // 	if (Cookies.get("email")) {
  // 		setUserEmail(Cookies.get("email"));
  // 	}
  // 	if (Cookies.get("domain")) {
  // 		setUserDomain(Cookies.get("domain"));
  // 	}
  // }, []);

  const handleStatusChange = (recordId, newStatus) => {
    base('ALLGRANTS&SAVINGS(Automated-AccountsReceivable)').update(
      recordId,
      { Status: newStatus },
      (err, record) => {
        if (err) {
          console.error(err);
          return;
        }
        console.log('Updated record', record);
      }
    );
  };

  const handleSelectClick = (event, id) => {
    console.log(`Select clicked for upgrade with ID: ${id}`);
    // You can add any additional logic here if needed.
  };

  function convertCurrencyToInt(currencyString) {
    // Remove the dollar sign and commas, then parse as an integer
    const numericString = currencyString.replace(/[$,]/g, '');
    return parseInt(numericString, 10);
  }

  const handleAnchorClick = (e, tabName) => {
    e.preventDefault()
    setSelectedTab(tabName)
    const element = document.getElementById('earnings-pipeline')
    if (element) {
      const yOffset = -100
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }

  const handleAnchorClickTeam = (e, tabName) => {
    e.preventDefault()
    setSelectedTab(tabName)
    const element = document.getElementById('team-pipeline')
    if (element) {
      const yOffset = -100
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
    console.log("more statuses", moreStatuses)
  }

  useEffect(() => {
    const styleId = 'custom-global-style'; // Unique ID for the style tag
    let style = document.getElementById(styleId);

    // Only create a new style element if it doesn't already exist
    if (!style) {
      style = document.createElement('style');
      style.id = styleId;
      style.textContent = `
        .grsf-global .grsf-widget {
            margin-right: 80px !important;
            margin-bottom: 15px !important;
        }
        .grsf-global .grsf-widget-inner {
            padding-right: 15px !important;
        }
      `;
      document.head.appendChild(style);
    }

    // Cleanup function to remove the style if the component unmounts
    return () => {
      if (style) {
        document.head.removeChild(style);
      }
    };
  }, []); // Empty dependency array ensures this runs only once

  // New Auth -- For Redirecting from "/" to "/?website=companyDomain" after they sign-in or sign-up
  const { isSignedIn, isLoaded, user } = useUser();
  const [users, setUsers] = useState();
  const { loading: usersLoading, error: usersError } = useBase(
    'users',
    setUsers
  );
  const [paid, setPaid] = useState(true);
  const [userDomain, setUserDomain] = useState('');
  const [admin, setAdmin] = useState(false);
  const [restricted, setRestricted] = useState(false);
  const [userReports, setUserReports] = useState([]);
  const [purchasedReports, setPurchasedReports] = useState([]);

  const [submitFormNotif, setSubmitFormNotif] = useState({
    showNotif: false,
    contents: { type: '', heading: '', message: '' },
  });

  const [submitGrantNotif, setSubmitGrantNotif] = useState({
    showNotif: false,
    contents: { type: '', heading: '', message: '' },
  });

  const [errorGrantNotif, setErrorGrantNotif] = useState({
    showNotif: false,
    contents: { type: '', heading: '', message: '' },
  });

  const [techPipeline, setTechPipeline] = useState({
    showNotif: false,
    contents: { type: '', heading: '', message: '' },
  });

  // this state represents the availability of the company in airtable
  const [domainAdded, setDomainAdded] = useState(false);

  // alternative way to solve grants filter problem
  const [flaggedGrants, setFlaggedGrants] = useState([]);

  // State to contain all the grants the current company is interested in (stored on companies table)
  const [interestedInGrants, setInterestedInGrants] = useState([]);

  // State to track whether JIT Modal is showing or not
  const [showJITModal, setShowJITModal] = useState(false);

  // State to track the request email for JIT
  const [requestEmail, setRequestEmail] = useState('');

  // ensure only run submitenrich once so we don't get duplicates in airtable
  const hasRunOnce = useRef(false);

  // to prevent code from the useeffect from running twice
  let trackNum = 0;

  // timeend
  const [timeEnd, setTimeEnd] = useState(false);

  // state tells us if we need to conduct airtable polling
  // const [airtablePoll, setAirtablePoll] = useState(false);

  // this state is used to keep track of number of companies from airtable
  const [recordCount, setRecordCount] = useState(0);

  // State to contain the discounts fetched from airtable.
  const [discounts, setDiscounts] = useState([]);
  const [showIndustryModal, setShowIndustryModal] = useState(false);
  const [industries, setIndustries] = useState({});
  const [industryRecords, setIndustryRecords] = useState({});
  const [domainFromEmail, setdomainFromEmail] = useState('getupgraded.ca');
  const [getEmail, setGetEmail] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [offset, setOffset] = useState();
  const [showAmount, setShowAmount] = useState(0);
  const [grantWriters, setGrantWriters] = useState([]);
  const [upgrades, setUpgrades] = useState([]);
  const [automationRequests, setAutomationRequests] = useState([]);
  const [growthRequests, setGrowthRequests] = useState([]);

  /*
  -----------------------
       Notifications
  -----------------------
  */

  // Savings Estimate Notification
  const [reqSavingsNotif, setReqSavingsNotif] = useState({
    showNotif: false,
    contents: { type: '', heading: '', message: '' },
  });

  // Company Form Submit Notification
  const [companyFormNotif, setCompanyFormNotif] = useState({
    showNotif: false,
    contents: { type: '', heading: '', message: '' },
  });

  // Referral Notification
  const [emailModalNotif, setEmailModalNotif] = useState({
    showNotif: false,
    contents: { type: '', heading: '', message: '' },
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const pushEmailtoAirtableLeads = async (email) => {
    base('leads').create(
      [
        {
          fields: {
            Email: email,
          },
        },
      ],

      function (err, records) {
        if (err) {
          console.error(err);
          return;
        }

        records.forEach(function (record) {
          console.log(record.getId());
        });
      }
    );
  };

  const handleSubmit = (domain) => {
    // Handle email
    if (domain?.includes('@')) {
      //If an email is detected, first add that to the leads table

      pushEmailtoAirtableLeads(domain);

      domain = domain?.split('@')[1]; // then split the email from the domain
    }

    // Handle website.
    if (
      domain?.startsWith('https://www.') ||
      domain?.startsWith('http://www.') ||
      domain?.startsWith('www.') ||
      domain?.startsWith('http://') ||
      domain?.startsWith('https://')
    ) {
      domain = domain.split('://')[1];

      if (domain?.includes('www.')) {
        domain = domain.split('www.')[1];
      }

      if (domain?.includes('/')) {
        domain = domain.split('/')[0];
      }
    }

    // Checking if the input submitted is a url
    const url = getWebURL(domain);
    if (url !== false) {
      domain = url;
    }

    // Creating the contents object for the notification
    const output = checkValidDomainName(domain);
    let contents = { type: '', heading: '', message: '' };
    if (output.result === 'Error') {
      contents.type = 'error';
      contents.heading = `Error in Fetching ${domain}❗`;
      contents.message = output.message;
    }
    // if the domain is valid (i.e. output.result==="Success")
    if (output.result === 'Success') {
      // If the company exists in airtable
      if (domainStatus === 'known') {
        const presentCompany = companies.filter(
          (company) => company.fields.Domain === domain
        )[0];
        window.location.href = `/?website=${domain}&company=${presentCompany.fields.Name}`;

        // if the company doesn't exist in airtable
      } else if (domainStatus === 'unknown') {
        // If the domain entered HAS been previously entered during this session
        // if (Cookies.get("enteredDomains")?.split(",").includes(domain)) {
        contents.type = 'success';
        contents.heading = 'Your Report is Being Upgraded';
        contents.message =
          "We're currently creating your report. It'll be emailed to you as soon as it's ready!";
        // } else {
        // -->  If the domain entered HAS NOT been entered previously in this session

        // Ensure enteredDomains is always an array
        const enteredDomains = Cookies.get('enteredDomains')
          ? Cookies.get('enteredDomains').split(',')
          : [];

        // Add the new domain to the array
        enteredDomains.push(domain);

        // Set the cookie with the updated array, converting it back to a comma-separated string
        Cookies.set('enteredDomains', enteredDomains.join(','), { expires: 1 });

        // Begin JIT report running for new domain
        window.location.href = `/?website=${domain}`;
        // }
      }
    }

    // If there is a notification to show
    if (contents.type != '') {
      setContents(contents, setSubmitEnrichNotif);
      setShowNotif(true, setSubmitEnrichNotif);
    }
  };

  function submitEmailInvite(email, comment, reportLink) {
    comment = comment + reportLink || 'Hey there lets see how this works!';

    var request = email + ' was just referred for savings ';

    setShowNotif(true, setEmailModalNotif);
    const content = {
      type: 'success',
      heading: `You just referred ${email}!`,
      message:
        "You'll be sent the greater of $500 or 15% of the first 12 months of revenue",
    };
    setContents(content, setEmailModalNotif);

    // https://hooks.zapier.com/hooks/catch/13965335/3ztn14f/
    fetch(`https://hooks.zapier.com/hooks/catch/13965335/3ztn14f/`, {
      method: 'POST',
      body: JSON.stringify({ request, email, comment }),
    })
      .then((response) => response.json())
      .then((data) => { })
      .catch((error) => console.error(error));
  }

  function submitEnrich(
    domain,
    companyId,
    userId,
    updatedCompanyReports,
    updatedUserReports
  ) {
    // const output = checkValidDomainName(domain);

    // let contents = { type: "", heading: "", message: "" };
    // if (output.result === "Error") {
    // 	contents.type = "error";
    // 	contents.heading = `Error in Fetching ${domain}❗`;
    // } else if (output.result === "Success") {
    // 	contents.type = "success";
    // 	contents.heading = `Success`;
    // }
    // contents.message = output.message;

    const website = domain || getQueryVariable('website');
    var request = `Savings estimate just ran! User: ${user.primaryEmailAddress.emailAddress}`;

    // JIT wait time currently 30 secs
    // if (output.result === "Success") {
    setShowJITModal(true);

    if (companyId) {
      fetch(
        'https://api.airtable.com/v0/' +
        baseId +
        '/' +
        companiesTableId +
        '/' +
        companyId,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${apiKey}`,
            'Content-Type': 'application/json',
          },

          body: JSON.stringify({
            fields: {
              Reports: updatedCompanyReports,
            },
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log('patch company table');
        })
        .catch((error) => console.error('Error:', error));
    }

    if (userId) {
      fetch(
        'https://api.airtable.com/v0/' +
        baseId +
        '/' +
        usersTableId +
        '/' +
        userId,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${apiKey}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            fields: {
              Reports: updatedUserReports,
            },
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log('patch user table');
        })
        .catch((error) => console.error('Error:', error));
    }

    // console.log("admin ??", admin);
    // let reportingEmails = "true";
    // if (admin) reportingEmails = "false";

    // Creating a new empty record for JIT companies.
    // This record contains the Domain of the Company, as well as some default values for different fields.
    fetch('https://api.airtable.com/v0/' + baseId + '/' + companiesTableId, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${apiKey}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        fields: {
          Name: capitalize(getQueryVariable('website').split('.')[0]),
          Domain: getQueryVariable('website'),
          Industry: 'Technology',
          Technologies: 'N/A',
          RequestEmail: user.primaryEmailAddress.emailAddress,
          ProvinceOrState: 'ON',
          Address: 'Toronto, Ontario',
          Employees: 10,
          Country: 'CA',
          Founded: 2000,
          AnnualRevenue: 1000000,
          AvgDealSize: 10000,
          ConversionRate: 2,
          Debt: 100000,
          AnnualTraffic: 10000,
          OperationalCosts: 500000,
          ListSize: 10000,
          InterestedIn:
            'CanExport SME,DS4Y,Student Work Placement Program,MITACS Business Strategy Internship,Canada Job Grant (CJG),Canada Small Business Financing Program (CSBFP)',
          Activity: JSON.stringify({
            Grants: [],
            'Tech Discounts': [],
            Automations: {
              excludePeople: [],
              personMap: {},
            },
            LatestActivity: 'n/a',
          }),
          'subscribed?': 'false',
          blurMe: 'true',
          reportingEmails: 'true',
          sendFrequency: 7,
          // bccEmails: 'will@getupgraded.ca',
          fromName: 'Will Richman',
          People1Name: 'there',
          lastSent: '04/25/2024',
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => console.log("Created 'Empty' Record in database", data))
      .catch((error) => console.error('Error:', error));

    setTimeout(() => setFormSubmitted(true), 1000);

    setTimeout(() => {
      setJITWorking(false);
    }, 30000);

    fetch(
      `https://hooks.zapier.com/hooks/catch/13965335/${process.env.REACT_APP_ZAPIER_SAVINGS}/`,
      {
        method: 'POST',
        body: JSON.stringify({ request, website }),
      }
    );

    try {
      fetch(
        `https://mycorsproxy-tuto-35940f676099.herokuapp.com/https://api.clay.com/v3/sources/webhook/${process.env.REACT_APP_CLAY_ENRICH}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${clayApiKey}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers':
              'Origin, X-Requested-With, Content-Type, Accept',
          },
          body: JSON.stringify({
            email: 'williamrichman@gmail.com',
            domain: website,
          }),
        }
      )
        .then((response) => {
          console.log('api call successful');
        })
        .catch((err) => {
          console.log('this was the problem', err);
        });
      console.log(`❗Finished fetching for ${website} ❗`);
    } catch (error) {
      console.error('Error in Fetching JIT: ', error);
    }
    // }

    // if (!unknownDomain && contents.type !== "success") {
    // 	setContents(contents, setReqSavingsNotif);
    // 	setShowNotif(true, setReqSavingsNotif);
    // }
  }

  // for scrolling to correct section after load
  useEffect(() => {
    if (!grantLoading) {
      const hash = window.location.hash;
      if (hash) {
        setTimeout(() => {
          const element = document.querySelector(hash);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 500);
      }
    }
  }, [grantLoading]);

  // CELLO
  // useEffect(() => {
  //   const SECRET = 'pnmh0wKBTV3Tg6ejAgbYcDyO+s/f5aQ6OohOKJ8W2phtwr0NbnqQXdC19TZh83zdugzeReqV59q1Z0ea8WbB0pMqGyNeY8vTI6/79zZono6PrgDm9LObMzCtolUCLlzEJlE8jAIosADPPFGOJlg1JdHZDQyO3V//nUfnKCmIvBv2ZvKGVoMWgmzOPJuFJ9ShRvxoFghJoMV+v1/Ty6Y57zFQX6i937McBaI/cbJI+AumlBb3IWxAYIC9nztrEEOYFfVjhK44uUEwRpMUq4ZhdCUgTtHW3i31fO8c2sjD/atxsyQ/pYdt7Pp7Y8J5Cn1UcWwNAWsSqefXZI13Gjq9JidkB1xPwIQzQ7q6AWCfDd1d7DZeiE5nJ6fauLLT8q8E437o1bK3Sty2GJO+U1rU2QefAI+d9N5PqmJNuTtAhGrFDE1gG3rzNx5FjVqtOKLhg6Nnl9I9H7gTXU2F4nt2+CPyHMAN4n16TxLuSv7zoIu2EEey3N4ZvxtRXLbQZjMayI8BEB9/xJxM3OIxxP5zOlc2D2uA0x7sfgxGwLCGIjWglsldqrVqSM/VpFivoDrlRtdJ5kQaT/rEH6wRCtqZg0YyBOlO98pFaUswnVOYJz+amPvcvfFd6MVQHqoytSDw25PtG8uzo2J02IgvF6qk5myY0zMsnoFE8+sFYog4WQ4='; // Replace with your actual product secret
  //   const tokenPayload = {
  //     productId: 'stage-app.getupgraded.ca', // Replace with your actual product ID
  //     productUserId: user.id, // Replace with a method to fetch or default user ID
  //   };

  //   const token = sign(tokenPayload, SECRET, {
  //     algorithm: 'HS512',
  //   });

  //   console.log("token", token);
  //   console.log("user.id",user.id);
  //   // Possibly store token in state or send to backend/API
  // }, []);

  useEffect(() => {
    base('automationRequests')
      .select({ view: 'Grid view', maxRecords: 100 })
      .all()
      .then((data) => {
        setAutomationRequests(data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    base('ALLGRANTS&SAVINGS(Automated-AccountsReceivable)')
      .select({
        view: 'Grid view',
        maxRecords: 500,
        filterByFormula: `{CompanyName} = '${company?.fields?.Name}'`
      })
      .all()
      .then((data) => {
        setMegaUpgrades(data);
      })
      .catch((err) => console.log(err));

    console.log("teamUpgrades", teamUpgrades);
  }, []);


  useEffect(() => {
    base('ALLGRANTS&SAVINGS(Automated-AccountsReceivable)')
      .select({
        view: 'Grid view',
        maxRecords: 500,
        filterByFormula: `OR({RequesterEmail} = '${selectedEmail}', {Compensated} = '${selectedEmail}')`
      })
      .all()
      .then((data) => {
        setTeamUpgrades(data);
      })
      .catch((err) => console.log(err));

    console.log("teamUpgrades", teamUpgrades);
  }, [selectedEmail]);

  useEffect(() => {
    base('growthRequests')
      .select({ view: 'Grid view', maxRecords: 10 })
      .all()
      .then((data) => {
        setGrowthRequests(data);
      })
      .catch((err) => console.log(err));
  }, []);

  // Fetch industries from airtable
  useEffect(() => {
    base('industries')
      .select({ view: 'Grid view' })
      .all()
      .then((data) => {
        let industriesObj = {};
        data.map(
          (rec) =>
          (industriesObj[rec.fields.Industry] =
            rec.fields.SubIndustries.split(',').map((item) =>
              item.trim().toLowerCase()
            ))
        );

        setIndustryRecords(data);
        setIndustries(industriesObj);
      })
      .catch((err) => console.log(err));
  }, []);

  // Fetch grants from airtable
  const { loading: grantsLoading, error: grantsError } = useBase(
    'deduped_grants',
    setGrants,
    grants,
  );
  if (grantsError) console.error('Error in fetching grants', grantsError);

  // get all grantwriters
  useEffect(() => {
    base('grant_writers_profiles')
      .select({ view: 'Grid view' })
      .all()
      .then((data) => {
        setGrantWriters(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [JITModalSubmit, setJITModalSubmit] = useState(false);

  // initialization of states
  useEffect(() => {
    // Handle non-authenticated flow
    if (!isSignedIn) {
        base('companies')
            .select({
                view: 'Grid view',
                filterByFormula: `REGEX_MATCH({Domain}, '^${getQueryVariable('website')}$')`
            })
            .all()
            .then((records) => {
                const curCompany = records[0];
                if (curCompany) {
                    base('discounts copy')
                        .select({ view: 'Grid view' })
                        .all()
                        .then((discountRecords) => {
                            let newDiscounts = findDiscounts(
                                curCompany?.fields?.Technologies,
                                discountRecords,
                                false
                            );
                            setDiscounts(discountRecords);
                            setMatchedDiscounts(newDiscounts ? newDiscounts : []);
                        })
                        .catch((err) => console.log('Discount fetch error:', err));
                }
            });
        return;
    }

    // Original authenticated flow
    if (!isLoaded || !user || !users) return;
    // set clerkUser and userRecord states
    setClerkUser(user);

    const curUser = users.filter((curUser) =>
      user.emailAddresses.some(
        (email) => email.emailAddress === curUser.fields.Email
      )
    )[0];

    if (!curUser) return;

    setUserRecord(curUser);

    const userDomain = curUser?.fields?.Domain;
    setUserDomain(userDomain);

    const reports = curUser?.fields?.Reports ? curUser.fields.Reports.split(',') : []
    setUserReports(reports);

    const activatedReports = curUser?.fields?.PurchasedReports
      ? curUser.fields.PurchasedReports.split(',')
      : []
    setPurchasedReports(activatedReports);

    const isAdmin =
      curUser?.fields?.Domain ===
      process.env.REACT_APP_COOL_EMAIL;
    setAdmin(isAdmin);

    LogRocket.identify(user.id, {
      name: user.fullName,
      email: curUser?.fields?.Email,
    });

    if (getQueryVariable('website') === false) {
      window.location.href = `/?website=${userDomain}`;
    }

    const noPermissions =
      !activatedReports.includes(getQueryVariable('website')) &&
      userDomain !== getQueryVariable('website');

    setPaid(curUser?.fields?.Paid);

    if (noPermissions) setPaid('unpaid');

    base('companies')
      .select({
        view: 'Grid view',
        filterByFormula: `REGEX_MATCH({Domain}, '^${getQueryVariable(
          'website'
        )}$')`,
      })
      .all()
      .then((records) => {
        const curCompany = records[0];

        setCompany(curCompany);

        // //                                                                                             // //
        // Resetting which domains are saved in our cookies based on whether they are now in airtable or not //
        // //
        // console.log("loaded company", records);                                                                           // //
        // let JITDomains = Cookies.get("enteredDomains")?.split(",");
        // let newDomains = records.map((record) => record?.fields?.Domain);
        // let userEnteredDomains = [];

        // if (JITDomains) {
        // 	for (const domain of JITDomains) {
        // 		if (!newDomains.includes(domain)) {
        // 			userEnteredDomains.push(domain);
        // 		}
        // 	}
        // }

        // Cookies.set("enteredDomains", userEnteredDomains.length === 0 ? "" : userEnteredDomains, { expires: 1 });
        // // //                                                                                             // //
        // // Resetting which domains are saved in our cookies based on whether they are now in airtable or not //
        // // //                                                                                             // //

        // let possibleCompanies = records.filter((company) => company.fields.Domain == getQueryVariable("website"));
        // let curCompany = undefined;

        // if (possibleCompanies.length > 1) {
        // 	// merging records
        // 	let mergedRecord = possibleCompanies.slice(1).reduce((accumulator, current) => {
        // 		let temp = { id: accumulator.id, fields: accumulator.fields };

        // 		for (let field of Object.keys(accumulator.fields)) {
        // 			if (accumulator.fields[field] === undefined || accumulator.fields[field] === "") {
        // 				temp.fields[field] = current.fields[field];
        // 			}
        // 		}

        // 		for (let field of Object.keys(current.fields)) {
        // 			if (!(field in temp.fields)) {
        // 				temp.fields[field] = current.fields[field];
        // 			}
        // 		}

        // 		return temp;
        // 	}, possibleCompanies[0]);

        // 	// deleting duplicate companies in airtable
        // 	for (let companyRecord of possibleCompanies.slice(1)) {
        // 		const recordId = companyRecord.id;

        // 		fetch("https://api.airtable.com/v0/" + baseId + "/" + companiesTableId + "/" + recordId, {
        // 			method: "DELETE",
        // 			headers: {
        // 				Authorization: `Bearer ${apiKey}`,
        // 				"Content-Type": "application/json",
        // 			},
        // 		})
        // 			.then((response) => response.json())
        // 			.then((confirmation) => {
        // 				console.log("Duplicate Company Deleted Confirmation", confirmation);
        // 			})
        // 			.catch((e) => console.error("Error deleting duplicate company", e));
        // 	}

        // 	// Updating the merged record in the airtable
        // 	fetch("https://api.airtable.com/v0/" + baseId + "/" + companiesTableId + "/" + mergedRecord.id, {
        // 		method: "PATCH",
        // 		headers: {
        // 			Authorization: `Bearer ${apiKey}`,
        // 			"Content-Type": "application/json",
        // 		},

        // 		body: JSON.stringify({
        // 			fields: mergedRecord.fields,
        // 		}),
        // 	})
        // 		.then((response) => response.json())
        // 		.then((data) => {
        // 			console.log("Updated merged record to airtable", data);
        // 		})
        // 		.catch((error) => console.error("Error:", error));

        // 	setCompanies([...records.filter((record) => record.fields.Domain !== getQueryVariable("website")), mergedRecord]);
        // 	curCompany = mergedRecord;
        // } else {
        // 	setCompanies(records);
        // 	curCompany = records.filter((cmpy) => cmpy.fields.Domain === getQueryVariable("website"))[0];
        // }

        // this will run if company was not found in airtable
        // we now know that the domain is not from airtable, so we setUnknownDomain to true
        // trying to enrich the page with new company
        if (curCompany === undefined) {
          // arguments for submit enrich
          const domain = getQueryVariable('website');

          if (!domain) return;

          let userCompany;

          (async () => {
            const records = await base('companies')
              .select({
                view: 'Grid view',
                filterByFormula: `REGEX_MATCH({Domain}, '^${userDomain}$')`,
              })
              .all();
            userCompany = records[0];

            const curCompanyReports = userCompany?.fields?.Reports || '';

            const updatedCompanyReports =
              curCompanyReports.length > 0
                ? `${curCompanyReports},${domain}`
                : domain;

            const curUserReports = curUser?.fields?.Reports || '';

            const updatedUserReports =
              curUserReports.length > 0
                ? `${curUserReports},${domain}`
                : domain;

            const userCompanyId = userCompany?.id;

            const userId = curUser?.id;

            setUnknownDomain(true);

            // Check if the function has run before
            if (!hasRunOnce.current) {
              // creates airtable record
              submitEnrich(
                domain,
                userCompanyId,
                userId,
                updatedCompanyReports,
                updatedUserReports
              );
              hasRunOnce.current = true; // Mark the function as run
            }

          })();
        } else {
          if (!getQueryVariable('company')) {
            window.location.href = `/?website=${curCompany.fields.Domain}&company=${curCompany.fields.Name}`;
          }

          base('discounts copy')
            .select({ view: 'Grid view' })
            .all()
            .then((discountRecords) => {
              let newDiscounts = findDiscounts(
                curCompany?.fields?.Technologies,
                discountRecords,
                false
              );

              setDiscounts(discountRecords);
              // setTotalTechSavings(newDiscounts.reduce((partialSum, a) => partialSum + a?.fields?.AnnualMargin, 0));
              setMatchedDiscounts(newDiscounts ? newDiscounts : []);
              setCompany(curCompany);
              setLoading(false);
            })
            /* ------- 

                Code for filtering technologies

                ------- */

              // let technologies = curCompany?.fields?.Technologies?.split(",")
              //   .map((item) => item.trim())
              //   .filter((technology) =>
              //     discountRecords
              //       .map((discount) => discount?.fields?.Title?.trim().toLowerCase())
              //       .some((title) => title.includes(technology.toLowerCase().trim()))
              //   );

              // let newCurCompany = {
              //   ...curCompany,
              //   fields: {
              //     ...curCompany.fields,
              //     Technologies: technologies.join(", "),
              //   },
              // };
              // setCompany(newCurCompany);
              // setCompanies([...records.filter(item => item.id !== newCurCompany.id), newCurCompany])

              /* ------- 

                Code for filtering technologies

              ------- */

            .catch((err) => console.log('Discount fetch error:', err));
        }
      });
  }, [isLoaded, user, domainAdded, isSignedIn, users, unknownDomain]);

  useEffect(() => {
    // if (unknownDomain && !JITWorking && !domainAdded && requestEmail !== "" && location.pathname !== '/report-pending')

    // After the 30 seconds, if the JIT domain hasn't been added to airtable, then we send the user to the report-pending page.
    // - There is a 1200 ms timeout on navigating which allows for the progress bar to finish reaching 100% before the redirect.

    if (JITWorking || !JITModalSubmit) return;

    const timeoutId = setTimeout(() => {
      navigate('/report-pending');
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [JITWorking, JITModalSubmit]);

  // useEffect(() => {
  //   // if unknown domain is true, then set isLoading to true.
  //   if (!unknownDomain) {
  //    setTimeout(() => {
  //      setLoading(false);
  //    }, 6000);
  //   }
  // }, [unknownDomain])

  useEffect(() => {
    if (formSubmitted) {
      // Perform actions after form submission
      base('companies')
        .select({ view: 'Grid view' })
        .all()
        .then((records) => {
          setCompanies(records);
          let company = records.filter(
            (company) => company.fields.Domain == getQueryVariable('website')
          )[0];
          setCompany(company);
          console.log('✅company is reloaded!');
        });

      // Reset the formSubmitted state variable
      setFormSubmitted(false);
    }
  }, [formSubmitted]);

  // SECTION DEDICATED TO JIT
  // -------------------------------->

  // trying another way to retrieve newest company from airtable
  // checking for modifications in companies section of database
  useEffect(() => {
    // will only run if company is not in airtable yet
    if (unknownDomain && requestEmail) {
      const fetchData = async () => {
        try {
          // from airtable we grab the companies record
          // then we find the number of rows for companies

          const records = await base('companies')
            .select({
              view: 'Grid view',
              filterByFormula: `REGEX_MATCH({Domain}, '^${getQueryVariable(
                'website'
              )}$')`,
            })
            .all();

          console.log('POLLING');

          const JITCompany = records[0];

          if (
            JITCompany?.fields?.RequestEmail &&
            JITCompany?.fields?.LinkedInUrl
          ) {
            setDomainAdded(true);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();

      setInterval(fetchData, 1000); // Poll every 1 second

      // return () => clearInterval(interval);
    }
  }, [unknownDomain, requestEmail]);

  // will run if the unknown company has been added to airtable
  // we will retrieve the company info and change to url to include both the website and company query params
  useEffect(() => {
    if (domainAdded && unknownDomain && JITModalSubmit) {
      const runAsync = async () => {
        try {
          const records = await base('companies')
            .select({
              view: 'Grid view',
              filterByFormula: `REGEX_MATCH({Domain}, '^${getQueryVariable(
                'website'
              )}$')`,
            })
            .all();

          const company = records[0];

          if (company?.fields?.Name) {
            window.location.href = `/?website=${company.fields.Domain}&company=${company.fields.Name}`;
          }
        } catch { }
      };

      runAsync();
    }
  }, [domainAdded, unknownDomain, JITModalSubmit]);

  // useEffect(() => {
  // 	// if (unknownDomain && !JITWorking && !domainAdded && requestEmail !== "" && location.pathname !== '/report-pending')

  // 	// After the 30 seconds, if the JIT domain hasn't been added to airtable, then we send the user to the report-pending page.
  // 	// - There is a 1200 ms timeout on navigating which allows for the progress bar to finish reaching 100% before the redirect.

  // 	if (JITWorking || domainAdded) return;

  // 	const timeoutId = setTimeout(() => {
  // 		navigate("/report-pending");
  // 	}, 4000);

  // 	return () => clearTimeout(timeoutId);

  // }, [JITWorking, domainAdded]);

  // Fetch upgrades from airtable
  useEffect(() => {
    let ignore = false;

    (async () => {
      if (ignore) return;
      base('upgrades')
        .select({ view: 'Grid view', Favourite: true })
        .all()
        .then((upgrades) => {
          setUpgrades(upgrades);
        });
    })();

    return () => {
      ignore = true;
    };
  }, []);

  useEffect(() => {
    if (!company || grants.length === 0) return;

    if (!unknownDomain) {
      setEligibleGrants(
        find_grants(
          company.fields.Country,
          company.fields.ProvinceOrState,
          company.fields.Address,
          company.fields.Founded,
          company.fields.Industry,
          company.fields.Employees,
          company.fields['Profitable?']
        ).filter(
          (grant) =>
            !flaggedGrants
              .map((grant) => grant.fields.Name)
              .includes(grant.fields.Name)
        )
      );

      //MARKER
      setGrantsSum(
        find_grants(
          company.fields.Country,
          company.fields.ProvinceOrState,
          company.fields.Address,
          company.fields.Founded,
          company.fields.Industry,
          company.fields.Employees,
          company.fields['Profitable?'],
          'sum'
        )
      );
    }

    setGrantLoading(false);
  }, [grants, company, flaggedGrants]);

  // this is for getting excluded grants from airtable
  useEffect(() => {
    if (!company) return;

    if (!unknownDomain && company?.fields?.Domain != '') {
      const excluded = company?.fields?.ExcludeGrants
        ? company.fields.ExcludeGrants.split(',')
        : [];
      setFlaggedGrants(
        find_grants(
          company.fields.Country,
          company.fields.ProvinceOrState,
          company.fields.Address,
          company.fields.Founded,
          company.fields.Industry,
          company.fields.Employees,
          company.fields['Profitable?']
        ).filter((grant) => excluded.includes(grant.fields.Name))
      );
    }
  }, [grants, company]);

  // This useEffect is for fetching the InterestedIn grants from the companies table
  useEffect(() => {
    if (
      unknownDomain ||
      !company?.fields?.Domain ||
      !company?.fields ||
      !grants?.length
    )
      return;

    const interestedInNamesList = company?.fields?.InterestedIn
      ? company?.fields?.InterestedIn.split(',').map((item) => item.trim())
      : [];

    setInterestedInGrants(
      find_grants(
        company.fields.Country,
        company.fields.ProvinceOrState,
        company.fields.Address,
        company.fields.Founded,
        company.fields.Industry,
        company.fields.Employees,
        company.fields['Profitable?']
      ).filter((grant) => interestedInNamesList.includes(grant.fields.Name))
    );
  }, [grants, company]);

  const addGrants = () => {
    for (const upgrade of interestedInGrants) {
      fetch('https://hooks.zapier.com/hooks/catch/16093847/3vv9abn/', {
        method: 'POST',
        body: JSON.stringify({
          CompanyName: company?.fields?.Name,
          ClientEmail: '',
          PartnerEmail: '',
          UpgradeName: upgrade?.fields?.Name,
          Amount: currencyFormat(upgrade?.fields?.AnnualMargin),
          ClientName: '',
          Date: new Date().toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }),
          UpgradeType: 'Grant',
        }),
      });
    }
  };

  // adjust settings for navigation
  useEffect(() => {
    if (loading) {
      return;
    }

    let navLinks = document.querySelectorAll('nav a');
    let logo = document.getElementById('logo');
    let pfp = document.getElementById('profile-pic');

    logo?.addEventListener('click', () =>
      window.scrollTo({ top: 0, behavior: 'smooth' })
    );
    pfp?.addEventListener('click', (e) => {
      e.preventDefault();
      setOpen((prev) => !prev);
    });

    const handleNavClick = (e) => {
      e.preventDefault();

      const href = e.currentTarget.getAttribute('href');
      if (href === '#') {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return;
      }

      const target = document.querySelector(href);
      if (target) {
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
              observer.disconnect();
              window.history.pushState(null, null, href); // Update the URL hash
              // console.log('Scroll animation complete'); // Callback function
            }
          },
          { threshold: 1.0 } // Fully in view
        );

        observer.observe(target);
        target.scrollIntoView({ behavior: 'smooth' });
      }
    };

    navLinks.forEach((link) => {
      link.addEventListener('click', handleNavClick);
    });
  }, [loading]);

  function findDiscounts(techStack, allDiscounts, returnSum = false) {
    // Use the company's tech stack directly
    let technologies = techStack?.split(',').map((item) => item.trim()) || [];
    let newDiscounts = [];
    
    // fuzzy match implementation
    for (let i = 0; i < technologies.length; i++) {
      const options = {
        shouldSort: true,
        threshold: 0.1, // Adjust this threshold to control the fuzziness of the matching
        keys: ['name'], // Specify the object property to search for job titles
      };

      const fuse = new Fuse(
        allDiscounts.map((discount) => ({
          discount,
          name: discount?.fields?.Title?.trim().toLowerCase(),
        })),
        options
      );

      const results = fuse.search(technologies[i].toLowerCase().trim());

      const bestDiscounts = results.map((result) => ({
        value: result.item.discount,
        score: result.score,
      }));
      // const bestDiscounts = results.map((result) => ({
      //   value: result.item.discount,
      //   score: result.score,
      // }));

      // console.log('best discounts', bestDiscounts);

      bestDiscounts.map((discount) => {
        const upgrade = {
          id: discount.value.id,
          score: discount.score,
          fields: {
            invoiceNumber: '00012',
            href: discount?.value?.fields?.Url,
            AnnualMargin: currencyToNumber(discount?.value?.fields?.MaxSavings),
            Description: discount?.value?.fields?.Description,
            Tactic: 'Automate Accounts Receivable',
            Function: 'Technology',
            client: 'Upgrade #00012',
            category: 'Finance',
            psuccess: 0.85,
            TTV: '< 30',
            client_time_required: '0.5',
            icon: ArrowUpCircleIcon,
            DiscountId: discount?.value?.fields?.DiscountId,
            Name: discount?.value?.fields?.Title,
            AssociatedTechnology: technologies[i],
            Eligible_Region: '',
            Eligible_Industry: '',
            Eligible_Employees: '',
            Eligible_Revenue: '',
            Eligible_Founded: '',
            Description_2: discount?.value?.fields?.Value,
            Eligible_Activities: '',
            Eligible_Applicants: '',
            Funding_Amount: '',
            Source: discount?.value?.fields?.Source,
            Details: discount?.value?.fields?.Details,
            Technology_Upgrade: true,
            InstructionsEmail: discount?.value?.fields?.InstructionsEmail,
            NewOrExisting: discounts?.value?.fields?.New_or_Existing,
          },
        };

        if (
          typeof upgrade?.fields?.AnnualMargin === 'number' &&
          !isNaN(upgrade?.fields?.AnnualMargin) &&
          typeof upgrade?.fields?.Name === 'string'
        ) {
          newDiscounts.push(upgrade);
        }
      });
    }

    newDiscounts = newDiscounts.sort(
      (a, b) => b?.fields?.AnnualMargin - a?.fields?.AnnualMargin
    );

    // delete duplicates
    if (newDiscounts.length !== 0) {
      let temp = newDiscounts.filter(
        (discount, index, self) =>
          index === self.findIndex((t) => t.id === discount.id)
      );
      newDiscounts = temp;
    }

    return returnSum
      ? newDiscounts.reduce(
        (partialSum, a) => partialSum + a?.fields?.AnnualMargin,
        0
      )
      : newDiscounts;
  }

  function find_grants(
    country,
    province,
    locality,
    founded,
    industry,
    employees,
    profitable,
    typeOfMoney = 'both'
  ) {
    // https://developers.google.com/sheets/api/samples/reading

    // Defaults set in find_grants function :
    // var country = country || "CA";
    // var province = province || "";
    // var founded = founded || "2017";
    // var industry = industry || "Technology";
    // var locality = locality || "Toronto, Ontario";
    // var employees = employees || "15";
    // var profitable = profitable || "Yes";
    // var typeOfMoney = typeOfMoney || "both";

    var country = country || '';
    var province = province || '';
    var founded = founded || '';
    var industry = industry || '';
    var locality = locality || '';
    var employees = employees || '';
    var profitable = profitable || '';
    var typeOfMoney = typeOfMoney || '';

    var data = grants;
    var sheetLength = grants?.length;
    // var parametersArray = [industry, founded, locality, country, employees];
    var sum = 0;
    var new_array = [];

    const countryNames = Object.values(countryCodes); // [canada, united states, ...]
    const countryOfCompany = country
      .trim()
      .toUpperCase()
      .split(',')
      .map((code) => countryCodes[code]); // turns "CA,BR" => [canada, brazil]
    const cityState = locality
      .trim()
      .toLowerCase()
      .split(',')
      .map((item) => item.trim()); // ide"toronto, ontario" to ["toronto", "ontario"]

    let provincesOfCompany = province
      .split(',')
      .map((item) => item.trim())
      .map((code) => {
        for (const countryCode of Object.keys(regionCodes)) {
          if (Object.keys(regionCodes[countryCode]).includes(code)) {
            return regionCodes[countryCode][code];
          }
        }
        return false;
      })
      .filter(Boolean)
      .map((item) => item.trim().toLowerCase());

    // changing abbreviation to full name
    if (
      cityState[1]?.length === 2 && // this means the state / province is a 2 digit code
      (Object.keys(regionCodes['CA']).includes(cityState[1]) ||
        Object.keys(regionCodes['US']).includes(cityState[1])) // this means the 2 digit code is contained in our data
    ) {
      const canadaProvince = regionCodes['CA'][cityState[1].toUpperCase()];
      const usState = regionCodes['US'][cityState[1].toUpperCase()];

      if (canadaProvince) {
        cityState[1] = canadaProvince;
      } else if (usState) {
        cityState[1] = usState;
      }
    }

    var industry = industry?.trim()?.toLowerCase().split(',').map(element => element.trim());

    for (var i = 1; i < sheetLength; i++) {
      // TO DO: Process both company industry and grant industry as an array
      // console.log("this is grant industry", data[i].fields.Eligible_Industry.trim().toLowerCase().split(','))
      // console.log('Industry', industry)
      var grantIndustry =
        data[i]?.fields?.Eligible_Industry?.toLowerCase().split(',').map(element => element.trim()) ||
        '';

      function checkIndustry(specificIndustry) {
        // check to see if the company's industry includes "technology"
        if (grantIndustry[0] === industry[0] || grantIndustry[0] === 'all') {
          return true;
        } else {
          return (
            // return whether company and grant share the same subindustry
            industries[specificIndustry]?.some((item) =>
              grantIndustry?.includes(item)
            ) &&
            industries[specificIndustry]?.some((item) =>
              industry?.includes(item)
            )
          );
        }
      }

      var industryCheck = Object.keys(industries).some(checkIndustry);

      // CHECK 2 = is the company founded more than 3 years ago
      var foundedCheck =
        parseFloat(data[i]?.fields.Eligible_Founded) >= founded ? true : false;

      // CHECK 3 = is the company in the right province and country
      const grantLocation = data[i]?.fields.Eligible_Region?.trim()
        .toLowerCase()
        .split(','); // "canada,united states" => [canada, united states]

      // check if grant location matches company location based on country
      // will return false if grant's locations are not country names or if grant country doesn't match company country
      let locationCheck = grantLocation?.some(
        (location) =>
          countryNames?.includes(location) &&
          countryOfCompany?.includes(location)
      ); // common element between grant location and company location?

      // now check if the grant's state/province names matches the company's state/province names
      if (!locationCheck) {
        locationCheck = grantLocation?.some(
          (location) => cityState[1] === location
        );
      }

      locationCheck =
        locationCheck ||
        data[i]?.fields?.Eligible_Region?.split(',')
          .map((item) => item.trim().toLowerCase())
          .some((region) => provincesOfCompany.includes(region));

      // locationCheck ? console.log('👍 VALID: grant location:', grantLocation, 'company location:', cityState[1], ...countryOfCompany) : console.log('🚫 location invalid');

      // CHECK 4 = does the company have enough employees
      var employeesCheck =
        parseFloat(data[i]?.fields.Eligible_Employees) <= parseFloat(employees)
          ? true
          : false;

      // CHECK 5 = does the company have enough revenue
      var revenueCheck =
        parseFloat(data[i]?.fields.Eligible_Revenue) <=
          parseFloat(employees) * 100000
          ? true
          : false;

      // CHECK 6 = is the grant a favorite, approved by Upgraded
      // var favoriteCheck = data[i].fields.Favourite == "checked" ? true : false
      var favoriteCheck = true;

      // CHECK 7 = are you looking for grants, loan or both
      // if (typeOfMoney == data[i][2] || typeOfMoney == "both") {
      //   var typeCheck = true;
      // } else {
      //   var typeCheck = false;
      // }
      var typeCheck = true;

      // CHECK 8 = does the grant require that the company is profitable?
      var profitabilityCheck =
        data[i]?.fields?.Eligible_Profitable === 'No'
          ? true
          : profitable === 'Yes'
            ? true
            : false;

      // Check 9 : Open or Closed Check. Is the grant or loan currently open?
      var openOrClosedCheck = data[i]?.fields?.Deadline !== "Closed";

      var timeCheck = data[i]?.fields?.TimeEstimateToComplete <= 90;

      const conditionsArray = [
        industryCheck,
        foundedCheck,
        locationCheck,
        employeesCheck,
        revenueCheck,
        favoriteCheck,
        typeCheck,
        profitabilityCheck,
        openOrClosedCheck,
        timeCheck
      ];



      // if (data[i].fields.Name === "OCI - Critical Industrial Technologies initiative") {
      //   console.log("this is OCI");
      //   console.log('company industry', industry);
      //   console.log('grant industry', grantIndustry);
      //   console.log('condition array', conditionsArray);
      // }

      // console.log('conditionsArray', conditionsArray);

      if (!conditionsArray.includes(false)) {
        const upgrade = {
          id: i,
          recordId: data[i]?.id,
          fields: {
            id: i,
            invoiceNumber: '00012',
            href: 'https://buy.stripe.com/aEU03K0pcb4U2vC9AB',
            Tactic: 'Grant',
            Function: data[i]?.fields?.GrantOrLoan,
            client: 'Upgrade #00012',
            pSuccess: parseFloat(data[i].fields.pSuccess) ?? 0.65,
            TTV: '30 - 180',
            client_time_required: '1 - 5',
            icon: ArrowUpCircleIcon,
            AnnualMargin: parseFloat(data[i].fields.Amount),
            ExpectedValue: parseFloat(data[i].fields.Amount) * (parseFloat(data[i].fields.pSuccess) ?? 0.65),
            AdjustedAmount: parseFloat(data[i].fields.AdjustedAmount),
            TimeEstimateToComplete: parseFloat(
              data[i].fields.TimeEstimateToComplete
            ),
            Description: data[i].fields.Description,
            Name: data[i].fields.Name,
            Eligibility_Criteria: data[i].fields.Eligibility_Criteria,
            Eligible_Region: data[i].fields.Eligible_Region,
            Eligible_Industry: data[i].fields.Eligible_Industry,
            Eligible_Employees: data[i].fields.Eligible_Employees,
            Eligible_Revenue: data[i].fields.Eligible_Revenue,
            Eligible_Founded: data[i].fields.Eligible_Founded,
            Eligible_Profitable: data[i].fields.Eligible_Profitable,
            Description_2: data[i].fields.Description_2,
            Eligible_Activities: data[i].fields.Eligible_Activities,
            Eligible_Applicants: data[i].fields.Eligible_Applicants,
            Funding_Amount: data[i].fields.Funding_Amount,
            Deadline_To_Apply: data[i].fields.Deadline_To_Apply,
            Deadline: data[i].fields.Deadline,
            Favourite: data[i].fields.Favourite,
            GrantUrl: data[i].fields.GrantUrl,
            GrantOrLoan: data[i]?.fields?.GrantOrLoan,
            OpenOrClosed: data[i]?.fields?.OpenOrClosed,
            Grant_Goal: data[i]?.fields?.Grant_Goal,
            Verified: data[i]?.fields?.Verified,
            'Verified By': data[i]?.fields?.VerifiedBy,
            'Date Verified': data[i]?.fields?.DataVerified,
            referralEmails: data[i]?.fields?.referralEmails,
            Category: data[i]?.fields?.Category,
            Empty_Grant_Application: data[i]?.fields?.Empty_Grant_Application,
            Successful_Grant_Application:
              data[i]?.fields?.Successful_Grant_Application,
            Internal_Process: data[i]?.fields?.Internal_Process,
            Contact_Info: data[i]?.fields?.Contact_Info,
            QuestionsComplete: data[i]?.fields?.QuestionsComplete,
            SampleAnswersComplete: data[i]?.fields?.SampleAnswersComplete,
          },
        };
        new_array.push(upgrade);

        // console.log(conditionsArray)
        // console.log("company info" + parametersArray)
        // console.log("grant" + data[i].fields)
        // console.log("industrycheck" + industryCheck + "_foundedCheck" + foundedCheck + "_locationCheck" + locationCheck + "_employeesCheck" + employeesCheck + "_revenueCheck" + revenueCheck + "_typeCheck" + typeCheck)
      }
    }

    if (typeOfMoney == 'sum') {
      new_array = new_array.filter(
        (grant) =>
          !flaggedGrants
            .map((grant) => grant.fields.Name)
            .includes(grant.fields.Name)
      );

      // AnnualMargin replaced with ExpectedValue
      sum = new_array.reduce(
        (partialSum, a) =>
          a.fields.ExpectedValue
            ? partialSum + a.fields.ExpectedValue
            : partialSum + 0,
        0
      );
      return sum;
    } else {
      return new_array;
    }
  }

  LogRocket.getSessionURL(function (sessionURL) {
    window.analytics.track('LogRocket', {
      sessionURL: sessionURL,
    });
  });

  // Add this near your other state/refs
  const extrasDisclosureRef = useRef(null);

  // Add this function to handle the trigger
  const toggleExtras = () => {
    if (extrasDisclosureRef.current) {
      extrasDisclosureRef.current.click();
    }
  };

  // Show loading state while auth is being checked
  if (!isLoaded) {
    return (
      <div className="flex text-center items-center justify-center">
        <InfinitySpin visible={true} width="200" color="#4fa94d" ariaLabel="infinity-spin-loading" />
      </div>
    );
  }

  return (
    <>
      <div className="overflow-x-clip">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="fixed h-8 w-auto"
                        src="https://s3.amazonaws.com/media.mixrank.com/hero-img/b5ad1559c480e7c9cabc1fab1ef29b6a"
                        alt="Upgraded Logo"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigationSections.map((section) => (
                              <Disclosure as="div" key={section.name} className="pt-2">
                                {({ open }) => (
                                  <>
                                    <Disclosure.Button
                                      ref={extrasDisclosureRef}
                                      className="text-gray-700 hover:text-indigo-600 hover:bg-gray-50 group flex w-full items-center gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                    >
                                      <ChevronRightIcon
                                        className={classNames(
                                          open ? 'rotate-90 transform' : '',
                                          'h-5 w-5 flex-none text-gray-400'
                                        )}
                                      />
                                      {section.name}
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="mt-1 px-2">
                                      {section.items.map((item) => (
                                        <a
                                          key={item.name}
                                          href={item.href}
                                          onClick={() => {
                                            const currentSearch = location.search;
                                            navigate(`/${currentSearch}`);
                                          }}
                                          className="text-gray-700 hover:text-indigo-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                        >
                                          <item.icon
                                            className="text-gray-400 group-hover:text-indigo-600 h-6 w-6 shrink-0"
                                            aria-hidden="true"
                                          />
                                          {item.name}
                                          {item.pro && (
                                            <span className="ml-auto">
                                              <LockClosedIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                                            </span>
                                          )}
                                        </a>
                                      ))}
                                    </Disclosure.Panel>
                                  </>
                                )}
                              </Disclosure>
                            ))}

                            {/* Admin and All Grants buttons remain outside sections */}
                            {admin && (
                              <li>
                                <button
                                  onClick={() => {
                                    const currentSearch = location.search;
                                    window.scrollTo({ top: 0 });
                                    navigate(`/companies${currentSearch}`);
                                  }}
                                  className="text-gray-700 hover:text-indigo-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                >
                                  <BuildingOffice2Icon
                                    className="text-gray-400 group-hover:text-indigo-600 h-6 w-6 shrink-0"
                                    aria-hidden="true"
                                  />
                                  Companies
                                </button>
                              </li>
                            )}

                            <li key="allGrants" className="cursor-pointer">
                              <button
                                onClick={() => {
                                  const currentSearch = location.search;
                                  window.scrollTo({ top: 0 });
                                  navigate(`/all-grants${currentSearch}`);
                                }}
                                className="text-gray-700 hover:text-indigo-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                              >
                                <MagnifyingGlassIcon
                                  className="text-gray-400 hover:text-indigo-600 hover:bg-gray-50 h-6 w-6 shrink-0"
                                  aria-hidden="true"
                                />
                                All Grants
                              </button>
                            </li>
                          </ul>
                        </li>

                        {/* <li className="mt-auto">
													<button
														href="#"
														className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
													>
														<Cog6ToothIcon className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600" aria-hidden="true" />
														Settings
													</button>
												</li> */}
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <div className="overflow-visible">
          <main className="py-0">
            <div className="px-4">
              {/* Show company panel if company data exists */}
              {company && (
                <SidePanel
                  restricted={restricted}
                  // signedUp={signedUp}
                  // userEmail={userEmail}
                  // setSignedUp={setSignedUp}
                  // setUserEmail={setUserEmail}
                  // setUserDomain={setUserDomain}
                  // setGetEmail={setGetEmail}
                  admin={admin}
                  flaggedGrants={flaggedGrants}
                  setFlaggedGrants={setFlaggedGrants}
                  // secret_1={getQueryVariable(process.env.REACT_APP_QUERY_SECRET_1) === "true"}
                  eligibleGrants={eligibleGrants}
                  setEligibleGrants={setEligibleGrants}
                  open={open}
                  setOpen={setOpen}
                  setFormSubmitted={setFormSubmitted}
                  setCompanyFormNotif={setCompanyFormNotif}
                  company={company}
                  setCompany={setCompany}
                  industries={industries}
                  setIndustries={setIndustries}
                  industryRecords={industryRecords}
                  setIndustryRecords={setIndustryRecords}
                  // setEmailCloseable={setEmailCloseable}
                  find_grants={find_grants}
                  findDiscounts={findDiscounts}
                />
              )}

              <main>
                {/* Show summary with appropriate restrictions */}
                <Summary
                  reqSavingsNotif={reqSavingsNotif}
                  grantsSum={grantsSum}
                  isLoading={loading}
                  isGrantsLoading={grantLoading}
                  companies={companies}
                  company={company}  // Make sure this is being passed
                  find_grants={find_grants}
                  techSavings={totalTechSavings || 75000} // Add default fallback value
                  findDiscounts={findDiscounts}
                  getQueryVariable={getQueryVariable}
                  // Provide default values for unauthenticated users
                  restricted={!isSignedIn}
                  defaultRevenue={100000}
                  defaultEmployees={10}
                />

                <div className="space-y-16 py-5 xl:space-y-10">
                  {/* Show company info with appropriate restrictions */}
                  {company && (
                    <Company
                      key={company.id}
                      company={company}
                      isLoading={loading}
                      getQueryVariable={getQueryVariable}
                      grantWriters={grantWriters}
                      setSideBarOpen={setOpen}
                      userId={userRecord?.id}
                      userFirstName={userRecord?.fields['First Name']}
                      userLastName={userRecord?.fields['Last Name']}
                      submitFormNotif={submitFormNotif}
                      setSubmitFormNotif={setSubmitFormNotif}
                      restricted={!isSignedIn} // Pass authentication status
                    />
                  )}

                  {/* temporary button to get interestedin grants */}
                  {/* <button
										onClick={() => addGrants()}
									>
										button
									</button> */}

                  {/* List of all Upgrades (Tech savings, Grants, Automations) */}
                  <UpgradeList
                    admin={admin}
                    paid={isSignedIn ? paid : 'unpaid'} // Show as unpaid for non-authenticated users
                    userRecord={userRecord}
                    company={company}
                    setCompany={setCompany}
                    flaggedGrants={flaggedGrants}
                    setFlaggedGrants={setFlaggedGrants}
                    interestedInGrants={interestedInGrants}
                    setInterestedInGrants={setInterestedInGrants}
                    eligibleGrants={eligibleGrants}
                    companies={companies}
                    getQueryVariable={getQueryVariable}
                    find_grants={find_grants}
                    grantWriters={grantWriters}
                    isLoading={grantLoading}
                    upgrades={upgrades}
                    grantsSum={grantsSum}
                    matchedDiscounts={matchedDiscounts}
                    industries={industries}
                    setTotalTechSavings={setTotalTechSavings}
                    techSavings={totalTechSavings}
                    setSideBarOpen={setOpen}
                    questions={questions}
                    answers={answers}
                    setTechPipeline={setTechPipeline}
                    techPipeline={techPipeline}
                    restricted={!isSignedIn} // Pass authentication status
                  />
                  
                  <div id="automations"></div>
                  <div className="sticky top-[105px] md:top-16 bg-white mx-auto w-full mt-10 z-10 pt-4 border-b mb-2 p-4">
                    <div
                      onClick={() => {
                        window.open(`${company.fields.LinkedInUrl}`);
                      }}
                      className="flex items-center gap-2">
                      <h1 className="text-2xl font-semibold text-gray-900">AI & Automations</h1>
                      <LinkIcon className="h-5 w-5 text-xl cursor-pointer" aria-hidden="true" />
                      <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mt-1 text-indigo-500 rounded-sm cursor-pointer">
                        <title>LinkedIn</title>
                        <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
                      </svg>
                    </div>
                  </div>

                  {company && (
                    <AutomationContext.Provider
                      value={{ automationPeople, setAutomationPeople }}
                    >
                      <div className="overflow-hidden"> {/* Full height container */}
                        <div className="h-full flex flex-col"> {/* Flex container to organize layout */}
                          {/* Any fixed height header content goes here */}
                          
                          <div className="flex-1 overflow-hidden"> {/* Flexible container that takes remaining space */}
                            <div className="overflow-y-scroll"> {/* Force scrollbar to always be present */}
                              <Automation
                                key={company.id}
                                company={company}
                                getQueryVariable={getQueryVariable}
                                grantWriters={grantWriters}
                                fullBlur={!admin && paid !== "basic"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </AutomationContext.Provider>
                  )}

                  {/* Admin Endless Fries Buttons */}
                  {/* {admin && (
										<div className="flex min-h-full flex-1 flex-col justify-center items-center py-12 sm:px-6 lg:px-8 bg-red-50">
											<NotificationButtons find_grants={find_grants} findDiscounts={findDiscounts} />
										</div>
									)} */}

                  {/* Financing */}
                  <div id="financing"></div>
                  <div className="sticky top-[105px] md:top-16 bg-white mx-auto w-full mt-10 z-10 pt-4 border-b mb-20 p-4">
                    <h1 className="text-2xl font-semibold text-gray-900">
                      Financing
                    </h1>
                  </div>
                  <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50 pt-20">
                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[800px]">
                      <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                        {company && (
                          <div key={company.id}>
                            <Financing
                              company={company}
                              isLoading={loading}
                              userEmail={user?.primaryEmailAddress?.emailAddress || ''} // Add null check and default value
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <br />
                  </div>

                  <div id="myreports"></div>
                  <div className="sticky top-[105px] md:top-16 bg-white mx-auto w-full mt-10 z-10 pt-4 border-b mb-20 p-4">
                    <h1 className="text-2xl font-semibold text-gray-900">
                      My Reports
                    </h1>
                  </div>
                  <div
                    className={`relative flex min-h-full flex-1 flex-col justify-center py-12 bg-gray-50 sm:pl-20p sm:pr-20p`}
                  >
                    <div
                      className={`${!admin && paid !== "basic" && 'blurred pointer-events-none'
                        }`}
                    >
                      <div className="rounded-t-xl border-b-2 border-gray-200 bg-white px-4 py-5">
                        <div className="ml-4 mt-4">
                          <div className="flex items-center">
                            <div className="flex-shrink-0">
                              <button
                                type="button"
                                className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
                              >
                                <span className="sr-only">
                                  View notifications
                                </span>
                                <UserButton afterSignOutUrl="/sign-in" />
                              </button>
                            </div>
                            <div className="ml-4">
                              <h className="text-gray-800 text-2xl text-center font-semibold mb-6">
                                Your reports
                              </h>
                            </div>
                          </div>
                        </div>
                      </div>
                      {userReports.map((report) => (
                        <div className="bg-white border-b border-gray-200 px-4 py-5">
                          <a
                            href={`https://app.getupgraded.ca/?website=${report}`}
                            className="text-blue-500 hover:text-blue-700"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <ArrowTopRightOnSquareIcon className="mb-2 h-6 w-6 inline" />{' '}
                            Savings report for {report}
                          </a>
                        </div>
                      ))}
                    </div>
                    {!admin && paid !== "basic" && (
                      <button
                        type="button"
                        onClick={() => {
                          const currentSearch = location.search;
                          window.scrollTo({ top: 0 });
                          navigate(`/pricing${currentSearch}`);
                        }}
                        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white shadow-xl bg-[#849E96] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        <LockClosedIcon className="mr-4 h-5 w-5 inline" />{' '}
                        Unlock These Exclusive Savings For $89/mo
                      </button>
                    )}
                  </div>

                  {/* PRINT AUTOMATION REQUESTS HERE + PRINT GROWTH REQUESTS HERE */}
                  <div className="mb-30" id="earnings-pipeline"></div>
                  <div className="relative flex min-h-full flex-1 flex-col justify-center py-12 bg-gray-50 pl-12 pr-12">
                    <div className="rounded-t-xl py-5">
                      <h3 className="text-2xl font-semibold leading-6 text-gray-900 my-10">Orders: {selectedTab} for {company?.fields?.Name}</h3>

                      <div className="mt-2">
                        <div className="sm:hidden">
                          <label htmlFor="tabs" className="sr-only">
                            Select a tab
                          </label>
                          {/* Use an "onChange" listener to update the selected tab */}
                          <select
                            id="tabs"
                            name="tabs"
                            value={selectedTab}
                            onChange={(e) => setSelectedTab(e.target.value)}
                            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                          >
                            {tabs.map((tab) => (
                              <option key={tab.name} value={tab.name}>
                                {tab.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="hidden sm:block">
                          <nav aria-label="Tabs" className="isolate flex divide-x divide-gray-200 rounded-lg shadow">
                            {tabs.map((tab, tabIdx) => (
                              <a
                                key={tab.name}
                                href={tab.href}
                                onClick={(e) => handleAnchorClick(e, tab.name)}
                                aria-current={selectedTab === tab.name ? 'page' : undefined}
                                className={classNames(
                                  selectedTab === tab.name ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                                  tabIdx === 0 ? 'rounded-l-lg' : '',
                                  tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                                  'group relative min-w-0 flex-1 overflow-hidden bg-white px-4 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10'
                                )}
                              >
                                <span>{tab.name}</span>
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    selectedTab === tab.name ? 'bg-indigo-500' : 'bg-transparent',
                                    'absolute inset-x-0 bottom-0 h-0.5'
                                  )}
                                />
                              </a>
                            ))}
                          </nav>
                        </div>
                      </div>

                      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
                        <div key={1} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                          <dt className="truncate text-sm font-medium text-gray-500">Total Forecasted Earnings</dt>
                          <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{currencyFormat(filteredMegaUpgrades.reduce((sum, record) => sum + (parseInt(record.fields.Amount.replace(/[$,]/g, ""), 10) || 0), 0))}/yr</dd>
                        </div>
                        <div key={2} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                          <dt className="truncate text-sm font-medium text-gray-500">Total Budget</dt>
                          <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{currencyFormat(filteredMegaUpgrades.reduce((sum, record) => sum + (parseInt(record.fields.Amount.replace(/[$,]/g, ""), 10) || 0), 0) / 120)}/mo</dd>
                        </div>
                      </dl>

                    </div>
                    {/* Mega Upgrades */}
                    <ul
                      role="list"
                      className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
                    >
                      {filteredMegaUpgrades?.map((megaUpgrade) => (
                        <li key={megaUpgrade?.id} className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6">
                          <div className="flex min-w-0 gap-x-4">
                            {!Boolean(company?.fields?.LogoUrl) ? (
                              <span className="inline-block mb-3 rounded-full">
                                <Skeleton circle={true} height={36} width={36} />
                              </span>
                            ) : company?.fields?.LogoUrl ? (
                              <img
                                className="h-12 w-12 flex-none rounded-full shadow-md ring-0.5 ring-gray-100 transition-transform duration-300 ease-in-out hover:scale-105"
                                src={company.fields.LogoUrl}
                                alt=""
                              />
                            ) : getQueryVariable('website') ? (
                              <img
                                className="h-12 w-12 flex-none rounded-full shadow-md ring-0.5 ring-gray-100 transition-transform duration-300 ease-in-out hover:scale-105"
                                src={`https://logo.clearbit.com/${getQueryVariable(
                                  'website'
                                )}`}
                                alt=""
                              />
                            ) : (
                              <Skeleton circle={true} height={36} width={36} />
                            )}
                            <div className="min-w-0 flex-auto">
                              <p className="text-sm font-semibold leading-6 text-gray-900">
                                <a href={megaUpgrade?.fields?.RequesterEmail}>
                                  <span className="absolute inset-x-0 -top-px bottom-0" />
                                  {megaUpgrade?.fields?.UpgradeName} | +{megaUpgrade?.fields?.Amount}/yr
                                </a>
                                <span
                                  className={classNames(
                                    statuses[megaUpgrade?.fields?.Status],
                                    'ml-2 mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                                  )}
                                >
                                  {megaUpgrade?.fields?.Status} - {megaUpgrade?.fields?.UpgradeType}
                                </span>
                              </p>
                              <p className="mt-1 flex text-xs leading-5 text-gray-500">
                                <a href={`mailto:${megaUpgrade?.fields?.RequesterEmail}`} className="relative truncate hover:underline">
                                  {megaUpgrade?.fields?.AutomationDescription}
                                </a>
                              </p>
                              <p className="mt-1 flex text-xs leading-5 text-gray-500">
                                <a href={`mailto:${megaUpgrade?.fields?.RequesterEmail}`} className="relative truncate hover:underline">
                                  By: {megaUpgrade?.fields?.RequesterEmail} | For: {megaUpgrade?.fields?.ClientEmail} | For: {megaUpgrade?.fields?.CompanyName} | {currencyFormat(convertCurrencyToInt(megaUpgrade?.fields?.Amount) / 10)}
                                </a>
                              </p>
                            </div>
                          </div>
                          <div className="flex shrink-0 items-center gap-x-4">
                            <div className="hidden sm:flex sm:flex-col sm:items-end">
                              <p className="text-sm leading-6 text-gray-900">{megaUpgrade?.fields?.role}</p>
                              {megaUpgrade?.fields?.LastModified ? (
                                <p className="mt-1 text-xs leading-5 text-gray-500">
                                  <time dateTime={megaUpgrade?.fields?.LastModified}>{formatDate(megaUpgrade?.fields?.LastModified)}</time>
                                </p>
                              ) : (
                                <div className="mt-1 flex items-center gap-x-1.5">
                                  <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                                    <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                                  </div>
                                  <p className="text-xs leading-5 text-gray-500">Online</p>
                                </div>
                              )}
                            </div>
                            <ChevronRightIcon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
                          </div>
                        </li>
                      ))}
                    </ul>

                    <h1 className="text-2xl font-semibold leading-6 text-gray-900 my-10">Automation Requests</h1>
                    <ul
                      role="list"
                      className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
                    >
                      {automationRequests?.map((automationRequest) => (
                        <li key={automationRequest?.fields?.id} className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6">
                          <div className="flex min-w-0 gap-x-4">
                            {!Boolean(company?.fields?.LogoUrl) ? (
                              <span className="inline-block mb-3 rounded-full">
                                <Skeleton circle={true} height={36} width={36} />
                              </span>
                            ) : company?.fields?.LogoUrl ? (
                              <img
                                className="h-12 w-12 flex-none rounded-full shadow-md ring-0.5 ring-gray-100 transition-transform duration-300 ease-in-out hover:scale-105"
                                src={company.fields.LogoUrl}
                                alt=""
                              />
                            ) : getQueryVariable('website') ? (
                              <img
                                className="h-12 w-12 flex-none rounded-full shadow-md ring-0.5 ring-gray-100 transition-transform duration-300 ease-in-out hover:scale-105"
                                src={`https://logo.clearbit.com/${getQueryVariable(
                                  'website'
                                )}`}
                                alt=""
                              />
                            ) : (
                              <Skeleton circle={true} height={36} width={36} />
                            )}
                            <div className="min-w-0 flex-auto">
                              <p className="text-sm font-semibold leading-6 text-gray-900">
                                <a href={automationRequest?.fields?.RequesterEmail}>
                                  <span className="absolute inset-x-0 -top-px bottom-0" />
                                  {automationRequest?.fields?.AutomationCategory} | +{automationRequest?.fields?.Savings}/yr
                                </a>
                                <span
                                  className={classNames(
                                    statuses[automationRequest?.fields?.Status],
                                    'ml-2 mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                                  )}
                                >
                                  {automationRequest?.fields?.Status}
                                </span>
                              </p>
                              <p className="mt-1 flex text-xs leading-5 text-gray-500">
                                <a href={`mailto:${automationRequest?.fields?.RequesterEmail}`} className="relative truncate hover:underline">
                                  {automationRequest?.fields?.AutomationDescription}
                                </a>
                              </p>
                              <p className="mt-1 flex text-xs leading-5 text-gray-500">
                                <a href={`mailto:${automationRequest?.fields?.RequesterEmail}`} className="relative truncate hover:underline">
                                  By: {automationRequest?.fields?.RequesterEmail} | {currencyFormat(automationRequest?.fields?.Budget)}
                                </a>
                              </p>
                            </div>
                          </div>
                          <div className="flex shrink-0 items-center gap-x-4">
                            <div className="hidden sm:flex sm:flex-col sm:items-end">
                              <p className="text-sm leading-6 text-gray-900">{automationRequest?.fields?.role}</p>
                              {automationRequest?.fields?.LastModified ? (
                                <p className="mt-1 text-xs leading-5 text-gray-500">
                                  <time dateTime={automationRequest?.fields?.LastModified}>{formatDate(automationRequest?.fields?.LastModified)}</time>
                                </p>
                              ) : (
                                <div className="mt-1 flex items-center gap-x-1.5">
                                  <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                                    <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                                  </div>
                                  <p className="text-xs leading-5 text-gray-500">Online</p>
                                </div>
                              )}
                            </div>
                            <ChevronRightIcon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
                          </div>
                        </li>
                      ))}
                    </ul>

                  </div>

                  {/* TEAM Upgrades - TeamUpgrades */}
                  {/* 1. TODO - Call all the records with the RequesterEmail = ercan@getupgraded.ca or haris@getupgraded.ca  */}
                  {/* 2 TODO - Print out the records */}
                  {/* 3 TODO - Print out the totals */}

                  {admin && (
                    <div>
                      <div className="" id="team-pipeline"></div>
                      <div className="relative flex min-h-full flex-1 flex-col justify-center py-12 bg-gray-50 pl-12 pr-12">
                        <div className="rounded-t-xl py-5">
                          <h3 className="text-2xl font-semibold leading-6 text-gray-900 my-10">Team Earnings: {selectedTab} for {selectedEmail}</h3>
                          {profilePics.map((src, index) => (
                            <span
                              key={index}
                              className="relative inline-block mx-5 cursor-pointer"
                              onClick={() => setSelectedEmail(emails[index])}
                            >
                              <img 
                                alt="" 
                                src={src} 
                                className={`h-12 w-12 rounded-full transition-all duration-200 ${
                                  emails[index] === selectedEmail 
                                    ? 'ring-4 ring-indigo-500 scale-110' 
                                    : 'ring-2 ring-gray-200 hover:ring-gray-300'
                                }`}
                              />
                              <span className="absolute right-0 top-0 block h-3 w-3 rounded-full bg-green-400 ring-2 ring-white" />
                            </span>
                          ))}

                          <div className="mt-2">
                            <div className="sm:hidden">
                              <label htmlFor="tabs" className="sr-only">
                                Select a tab
                              </label>
                              {/* Use an "onChange" listener to update the selected tab */}
                              <select
                                id="tabs"
                                name="tabs"
                                value={selectedTab}
                                onChange={(e) => setSelectedTab(e.target.value)}
                                className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                              >
                                {tabs.map((tab) => (
                                  <option key={tab.name} value={tab.name}>
                                    {tab.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="hidden sm:block">
                              <nav aria-label="Tabs" className="isolate flex divide-x divide-gray-200 rounded-lg shadow">
                                {tabs.map((tab, tabIdx) => (
                                  <a
                                    key={tab.name}
                                    href={tab.href}
                                    onClick={(e) => handleAnchorClickTeam(e, tab.name)}
                                    aria-current={selectedTab === tab.name ? 'page' : undefined}
                                    className={classNames(
                                      selectedTab === tab.name ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                                      tabIdx === 0 ? 'rounded-l-lg' : '',
                                      tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                                      'group relative min-w-0 flex-1 overflow-hidden bg-white px-4 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10'
                                    )}
                                  >
                                    <span>{tab.name}</span>
                                    <span
                                      aria-hidden="true"
                                      className={classNames(
                                        selectedTab === tab.name ? 'bg-indigo-500' : 'bg-transparent',
                                        'absolute inset-x-0 bottom-0 h-0.5'
                                      )}
                                    />
                                  </a>
                                ))}
                              </nav>
                            </div>
                          </div>

                          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
                            <div key={1} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                              <dt className="truncate text-sm font-medium text-gray-500">Total Forecasted Earnings</dt>
                              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{currencyFormat(filteredTeamUpgrades.reduce((sum, record) => sum + (parseInt(record.fields.Amount.replace(/[$,]/g, ""), 10) || 0), 0))}/yr</dd>
                            </div>
                            <div key={2} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                              <dt className="truncate text-sm font-medium text-gray-500">Total Budget</dt>
                              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{currencyFormat(filteredTeamUpgrades.reduce((sum, record) => sum + (parseInt(record.fields.Amount.replace(/[$,]/g, ""), 10) || 0), 0) / 120)}/mo</dd>
                            </div>
                            <div key={3} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                              <dt className="truncate text-sm font-medium text-gray-500">AutoDrafts Sent</dt>
                              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{filteredTeamUpgrades.length}</dd>
                            </div>
                            <div key={4} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                              <dt className="truncate text-sm font-medium text-gray-500">Billed For</dt>
                              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{currencyFormat(
                                filteredTeamUpgrades
                                  .filter((record) => record.fields.Status === "Awarded") // Filter only "Awarded" records
                                  .reduce(
                                    (sum, record) =>
                                      sum + (parseInt(record.fields.Amount.replace(/[$,]/g, ""), 10) || 0),
                                    0
                                  )
                              )}</dd>
                            </div>
                          </dl>
                        </div>
                        {/* Mega Upgrades */}
                        <ul
                          role="list"
                          className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
                        >
                          {filteredTeamUpgrades?.map((megaUpgrade) => (
                            <li key={megaUpgrade?.id} className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6">
                              <div className="flex min-w-0 gap-x-4">
                                {!Boolean(company?.fields?.LogoUrl) ? (
                                  <span className="inline-block mb-3 rounded-full">
                                    <Skeleton circle={true} height={36} width={36} />
                                  </span>
                                ) : company?.fields?.LogoUrl ? (
                                  <img
                                    className="h-12 w-12 flex-none rounded-full shadow-md ring-0.5 ring-gray-100 transition-transform duration-300 ease-in-out hover:scale-105"
                                    src={company.fields.LogoUrl}
                                    alt=""
                                  />
                                ) : getQueryVariable('website') ? (
                                  <img
                                    className="h-12 w-12 flex-none rounded-full shadow-md ring-0.5 ring-gray-100 transition-transform duration-300 ease-in-out hover:scale-105"
                                    src={`https://logo.clearbit.com/${getQueryVariable(
                                      'website'
                                    )}`}
                                    alt=""
                                  />
                                ) : (
                                  <Skeleton circle={true} height={36} width={36} />
                                )}
                                <div className="min-w-0 flex-auto">
                                  <p className="text-sm font-semibold leading-6 text-gray-900">
                                    <span className="absolute inset-x-0 -top-px bottom-0" />
                                    {megaUpgrade?.fields?.UpgradeName} | +{megaUpgrade?.fields?.Amount}/yr
                                    <span
                                      className={classNames(
                                        statuses[megaUpgrade?.fields?.Status],
                                        'ml-2 mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                                      )}
                                    >
                                      {megaUpgrade?.fields?.Status} - {megaUpgrade?.fields?.UpgradeType}
                                    </span>
                                  </p>
                                  <p className="mt-1 flex text-xs leading-5 text-gray-500">
                                    <a href={`mailto:${megaUpgrade?.fields?.RequesterEmail}`} className="relative truncate hover:underline">
                                      {megaUpgrade?.fields?.AutomationDescription}
                                    </a>
                                  </p>
                                  <p className="mt-1 flex text-xs leading-5 text-gray-500">
                                    <a href={`mailto:${megaUpgrade?.fields?.RequesterEmail}`} className="relative truncate hover:underline">
                                      By: {megaUpgrade?.fields?.RequesterEmail} | For: {megaUpgrade?.fields?.ClientEmail} | For: {megaUpgrade?.fields?.CompanyName} | {currencyFormat(convertCurrencyToInt(megaUpgrade?.fields?.Amount) / 10)}
                                    </a>
                                  </p>
                                  {/* <select
                                    value={megaUpgrade?.fields?.Status}
                                    onChange={(e) => handleStatusChange(megaUpgrade.id, e.target.value)}
                                    onClick={(e) => handleSelectClick(e, console.log(e.target.value), megaUpgrade.id)}
                                    className="mt-3 p-2 border border-gray-300 rounded z-10"
                                  >
                                    <option value="Pending">Pending</option>
                                    <option value="Approved">Approved</option>
                                    <option value="Rejected">Rejected</option>
                                  </select> */}
                                  {/* <Select
                                    value={statuses[megaUpgrade.id]} // This now uses the correct object format with value and label
                                    onChange={(selectedOption) => {
                                      // Update state immediately for user feedback
                                      setStatuses({
                                        ...statuses,
                                        [megaUpgrade.id]: selectedOption,
                                      });

                                      // Optionally call your existing status change handler to handle additional side effects
                                      handleStatusChange(megaUpgrade.id, selectedOption.value);
                                    }}
                                    options={[
                                      { value: 'In Progress', label: 'In Progress' },
                                      { value: 'pending', label: 'pending' },
                                      { value: 'awarded', label: 'awarded' },
                                      { value: 'Pending', label: 'Pending' },
                                      { value: 'Approved', label: 'Approved' },
                                      { value: 'Rejected', label: 'Rejected' }
                                    ]}
                                    className="mt-3"
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                  /> */}
                                  {/* {console.log("hello", moreStatuses[megaUpgrade.id])} */}
                                  <Select
                                    value={{ value: moreStatuses[megaUpgrade.id], label: moreStatuses[megaUpgrade.id] }}
                                    onChange={(selectedOption) => {
                                      // Update the state to reflect the new value immediately
                                      setMoreStatuses({
                                        ...moreStatuses,
                                        [megaUpgrade.id]: selectedOption.value,
                                      });

                                      // Optionally call the existing handler to update data elsewhere
                                      handleStatusChange(megaUpgrade.id, selectedOption.value);
                                    }}
                                    options={[
                                      { value: 'In Progress', label: 'In Progress' },
                                      { value: 'Drafted', label: 'Drafted' },
                                      { value: 'Sent', label: 'Sent' },
                                      { value: 'Pending', label: 'Pending' },
                                      { value: 'Awarded', label: 'Awarded' },
                                      { value: 'Lost', label: 'Lost' },
                                      { value: 'Billed', label: 'Billed' }
                                    ]}
                                    className="mt-3 max-w-xs"
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                  />
                                </div>
                              </div>
                              <div className="flex shrink-0 items-center gap-x-4">
                                <div className="hidden sm:flex sm:flex-col sm:items-end">
                                  <p className="text-sm leading-6 text-gray-900">{megaUpgrade?.fields?.role}</p>
                                  {megaUpgrade?.fields?.LastModified ? (
                                    <p className="mt-1 text-xs leading-5 text-gray-500">
                                      <time dateTime={megaUpgrade?.fields?.LastModified}>{formatDate(megaUpgrade?.fields?.LastModified)}</time>
                                    </p>
                                  ) : (
                                    <div className="mt-1 flex items-center gap-x-1.5">
                                      <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                                        <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                                      </div>
                                      <p className="text-xs leading-5 text-gray-500">Online</p>
                                    </div>
                                  )}
                                </div>
                                <ChevronRightIcon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}

                  {/* Onboarding */}
                  <div id="onboarding"></div>
                  <div className="sticky top-[105px] md:top-16 bg-white mx-auto w-full mt-10 z-10 pt-4 border-b mb-20 p-4">
                    <h1 className="text-2xl font-semibold text-gray-900">
                      Onboarding
                    </h1>
                  </div>
                  <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50 pt-20">
                    <div className="sm:mx-auto sm:w-full sm:max-w-xl">
                      <img
                        className="mx-auto h-10 w-auto"
                        src="https://assets.website-files.com/634f60226f66af42a384d5b7/647819351547cbfe06a601b8_upgraded-full-word-logo-no-background.png"
                        alt="Your Company"
                      />
                      <h2 className="mt-2 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Get Started with Upgraded
                      </h2>
                      <h5 className="text-center">
                        You're on your way to saving. Follow the steps below to
                        complete setup.
                      </h5>
                    </div>

                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[800px]">
                      <div className="bg-white px-6 pt-4 pb-8 shadow sm:rounded-lg sm:px-12">
                        {/* Onboarding */}
                        {company && (
                          <Onboarding
                            key={company.id}
                            company={company}
                            isLoading={loading}
                            setSideBarOpen={setOpen}
                          />
                        )}
                      </div>
                    </div>
                    <br />
                  </div>

                  {admin && (
                    <IndustriesMappingModal
                      open={showIndustryModal}
                      setOpen={setShowIndustryModal}
                      company={false}
                      industries={industries}
                      setIndustries={setIndustries}
                      industryRecords={industryRecords}
                      setIndustryRecords={setIndustryRecords}
                    />
                  )}

                  {/* Savings estimate notificaiton */}
                  <Notification
                    showNotif={reqSavingsNotif.showNotif}
                    setShowNotif={(val) =>
                      setShowNotif(val, setReqSavingsNotif)
                    }
                    contents={reqSavingsNotif.contents}
                  />

                  {/* Company Info Form Submit Notification*/}
                  <Notification
                    showNotif={companyFormNotif.showNotif}
                    setShowNotif={(val) =>
                      setShowNotif(val, setCompanyFormNotif)
                    }
                    contents={companyFormNotif.contents}
                  />
                  {/* Company Info Form Submit Notification*/}
                  <Notification
                    showNotif={submitFormNotif.showNotif}
                    setShowNotif={(val) =>
                      setSubmitFormNotif(val, setSubmitFormNotif)
                    }
                    contents={submitFormNotif.contents}
                  />

                  {/* JIT Modal */}
                  {showJITModal && (
                    <JITModal
                      website={getQueryVariable('website')}
                      setRequestEmail={setRequestEmail}
                      setJITModalSubmit={setJITModalSubmit}
                      countDown={2}
                      userEmail={user.primaryEmailAddress.emailAddress}
                    />
                  )}
                </div>
              </main>
            </div>
          </main>
        </div>
      </div>

      <Notification
        showNotif={emailModalNotif.showNotif}
        setShowNotif={(val) => setShowNotif(val, setEmailModalNotif)}
        contents={emailModalNotif.contents}
      />

      <Notification
        showNotif={submitEnrichNotif.showNotif}
        setShowNotif={(val) => setShowNotif(val, setSubmitEnrichNotif)}
        contents={submitEnrichNotif.contents}
      />
      <Notification
        showNotif={techPipeline.showNotif}
        setShowNotif={(val) => setTechPipeline(val, setTechPipeline)}
        contents={techPipeline.contents}
      />
    </>
  );
}
