import React, { useEffect, useState } from "react";
import { currencyFormat, checkValidDomainName } from "./helpers";
// import { Tooltip } from "@mui/material";
// import { AreaChart, Card, Title, Metric, Text } from "@tremor/react";
import { Card } from "@tremor/react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
	AreaChart,
	Legend,
	Area,
} from "recharts";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

export default function Summary({
	// submitEnrich,
	reqSavingsNotif,
	grantsSum,
	isLoading,
	isGrantsLoading,
	companies,
	company,
	find_grants,
	findDiscounts,
	getQueryVariable,
	techSavings,
	restricted,
	defaultRevenue = 100000,
	defaultEmployees = 10
}) {
	const [loading, setLoading] = useState(true);
	const stats_skeleton_height = 115; // height used for skeleton loading for stats / savings pillars
	const graph_skeleton_height = 370; // height used for skeleton loading for graph

	const [sum, setSum] = useState(grantsSum);

	const [domainStatus, setDomainStatus] = useState("");

	const [rootValue, setRootValue] = useState(null);

	useEffect(() => {
		setSum(grantsSum);
	}, [grantsSum]);

	useEffect(() => {
		setLoading(isLoading);
	}, [isLoading]);

	// Helper function to get revenue value
	const getRevenue = () => {
		if (company?.fields?.AnnualRevenue) {
			return company.fields.AnnualRevenue;
		}
		return defaultRevenue * 12; // Convert default monthly to annual
	};

	// Helper function to get employees value
	const getEmployees = () => {
		return company?.fields?.Employees || defaultEmployees;
	};

	const chartdata = [
		{
			date: "July 24",
			"Status Quo": Math.round((getRevenue() / 12)),
			"Revenue Upgrades": Math.round((getRevenue() / 12) * (10 / 55)),
			"Savings Upgrades": Math.round((getRevenue() / 12) * (10 / 55)),
		},
		{
			date: "",
			"Status Quo": Math.round((getRevenue() / 12) * (65 / 55)),
			"Revenue Upgrades": Math.round((getRevenue() / 12) * (30 / 55)),
			"Savings Upgrades": Math.round((getRevenue() / 12) * (30 / 55)),
		},
		{
			date: "",
			"Status Quo": Math.round((getRevenue() / 12) * (67.5 / 55)),
			"Revenue Upgrades": Math.round((getRevenue() / 12) * (47.5 / 55)),
			"Savings Upgrades": Math.round((getRevenue() / 12) * (47.5 / 55)),
		},
		{
			date: "Oct 24",
			"Status Quo": Math.round((getRevenue() / 12) * (70 / 55)),
			"Revenue Upgrades": Math.round((getRevenue() / 12) * (50 / 55)),
			"Savings Upgrades": Math.round((getRevenue() / 12) * (50 / 55)),
		},
		{
			date: "",
			"Status Quo": Math.round((getRevenue() / 12) * (72.5 / 55)),
			"Revenue Upgrades": Math.round((getRevenue() / 12) * (47.5 / 55)),
			"Savings Upgrades": Math.round((getRevenue() / 12) * (47.5 / 55)),
		},
		{
			date: "",
			"Status Quo": Math.round((getRevenue() / 12) * (75 / 55)),
			"Revenue Upgrades": Math.round((getRevenue() / 12) * (55 / 55)),
			"Savings Upgrades": Math.round((getRevenue() / 12) * (55 / 55)),
		},
		{
			date: "Jan 25",
			"Status Quo": Math.round((getRevenue() / 12) * (77.5 / 55)),
			"Revenue Upgrades": Math.round((getRevenue() / 12) * (60 / 55)),
			"Savings Upgrades": Math.round((getRevenue() / 12) * (60 / 55)),
		},
		{
			date: "",
			"Status Quo": Math.round((getRevenue() / 12) * (80 / 55)),
			"Revenue Upgrades": Math.round((getRevenue() / 12) * (67.5 / 55)),
			"Savings Upgrades": Math.round((getRevenue() / 12) * (67.5 / 55)),
		},
		{
			date: "",
			"Status Quo": Math.round((getRevenue() / 12) * (82.5 / 55)),
			"Revenue Upgrades": Math.round((getRevenue() / 12) * (65 / 55)),
			"Savings Upgrades": Math.round((getRevenue() / 12) * (65 / 55)),
		},
		{
			date: "Apr 25",
			"Status Quo": Math.round((getRevenue() / 12) * (85 / 55)),
			"Revenue Upgrades": Math.round((getRevenue() / 12) * (70 / 55)),
			"Savings Upgrades": Math.round((getRevenue() / 12) * (70 / 55)),
		},
		{
			date: "",
			"Status Quo": Math.round((getRevenue() / 12) * (87.5 / 55)),
			"Revenue Upgrades": Math.round((getRevenue() / 12) * (75 / 55)),
			"Savings Upgrades": Math.round((getRevenue() / 12) * (75 / 55)),
		},
		{
			date: "",
			"Status Quo": Math.round((getRevenue() / 12) * (90 / 55)),
			"Revenue Upgrades": Math.round((getRevenue() / 12) * (80 / 55)),
			"Savings Upgrades": Math.round((getRevenue() / 12) * (80 / 55)),
		},
	];

	const valueFormatter = function (number) {
		return "$ " + new Intl.NumberFormat("us").format(number).toString();
	};


	// const data = [
	// 	{ year: 0, Upgraded: 0, TraditionalInvestor: 0, SavingsAccount: 15000 },
	// 	{ year: 5, Upgraded: 1000, TraditionalInvestor: 500, SavingsAccount: 18000 },
	// 	{ year: 10, Upgraded: 4000, TraditionalInvestor: 2000, SavingsAccount: 25000 },
	// 	{ year: 15, Upgraded: 9000, TraditionalInvestor: 4000, SavingsAccount: 35000 },
	// 	{ year: 20, Upgraded: 20000, TraditionalInvestor: 10000, SavingsAccount: 50000 },
	// 	{ year: 25, Upgraded: 40000, TraditionalInvestor: 20000, SavingsAccount: 70000 },
	// 	{ year: 30, Upgraded: 78676, TraditionalInvestor: 61958, SavingsAccount: 90574 }
	//   ];

	const data = rootValue ? [
		{ year: 0, Upgraded: rootValue * 0.02, TraditionalInvestor: rootValue * 0.01, SavingsAccount: rootValue },  // 30% of rootValue
		{ year: 5, Upgraded: rootValue * 0.04, TraditionalInvestor: rootValue * 0.01, SavingsAccount: rootValue * 1.26 },  // 2%, 1%, 36%
		{ year: 10, Upgraded: rootValue * 0.16, TraditionalInvestor: rootValue * 0.04, SavingsAccount: rootValue * 1.5 },  // 8%, 4%, 50%
		{ year: 15, Upgraded: rootValue * 0.36, TraditionalInvestor: rootValue * 0.08, SavingsAccount: rootValue * 1.7 },  // 18%, 8%, 70%
		{ year: 20, Upgraded: rootValue * 0.8, TraditionalInvestor: rootValue * 0.2, SavingsAccount: rootValue * 2.0 },  // 40%, 20%, 100%
		{ year: 25, Upgraded: rootValue * 1.6, TraditionalInvestor: rootValue * 0.4, SavingsAccount: rootValue * 2.4 },  // 80%, 40%, 140%
		{ year: 30, Upgraded: rootValue * 3.2, TraditionalInvestor: rootValue * 1.23916, SavingsAccount: rootValue * 2.81148 }  // 157.352%, 123.916%, 181.148%
	] : [];

	// Update useEffect for rootValue
	useEffect(() => {
		setRootValue(getRevenue() / 12);
	}, [company]);

	const renderCustomLegend = (props) => {
		const { payload } = props;

		return (
			<div style={{ textAlign: 'center', paddingTop: '10px' }}>
				{payload.map((entry, index) => (
					<div
						key={`item-${index}`}
						style={{
							display: 'inline-flex',
							alignItems: 'center',
							marginRight: 20,
							fontSize: '14px',
							color: '#272C2E', // Dark grey text color
							fontWeight: 'normal'
						}}
					>
						{/* Custom legend line color and text */}
						<svg width="30" height="10" style={{ verticalAlign: 'middle' }}>
							<line
								x1="0"
								y1="5"
								x2="30"
								y2="5"
								stroke={entry.color} // Line color matching the area fill
								strokeWidth="4"
							/>
						</svg>
						<span style={{ marginLeft: 5 }}>{entry.value}</span> {/* Text positioned to the right of the line */}
					</div>
				))}
			</div>
		);
	};

	// Custom Tooltip Component
	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			// Sort payload to ensure "Do It Yourself" (SavingsAccount) is at the bottom
			const sortedPayload = [...payload].sort((a, b) => (a.name === "Do It Yourself" ? 1 : -1));

			return (
				<div className="custom-tooltip" style={{ backgroundColor: 'white', borderColor: '#ddd', borderRadius: 5, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)', padding: '10px' }}>
					{/* Top Label with Months */}
					<p className="label" style={{ color: '#888', fontSize: '12px', marginBottom: '5px' }}>{`Month: ${label}`}</p>

					{/* Display sorted entries in tooltip */}
					<ul style={{ margin: 0, padding: 0, listStyleType: 'none' }}>
						{sortedPayload.map((entry, index) => (
							<li key={`item-${index}`} style={{ color: 'black', fontSize: 12, fontWeight: 'semibold', marginBottom: 2 }}>
								{entry.name}: +${entry.value.toLocaleString()}/mo
							</li>
						))}
					</ul>
				</div>
			);
		}

		return null;
	};

	// const calculateTechSavings = (company, discounts) => {
	// 	if (!company?.fields?.Technologies || !discounts) return 0;
		
	// 	const technologies = company.fields.Technologies.split(',').map(t => t.trim());
		
	// 	const matches = discounts.filter(discount => 
	// 		technologies.some(tech => 
	// 			tech.toLowerCase().includes(discount.fields.Title?.toLowerCase())
	// 		)
	// 	);

	// 	const totalSavings = matches.reduce((sum, discount) => 
	// 		sum + (discount.fields.MaxSavings ? Number(discount.fields.MaxSavings.replace(/[^0-9.-]+/g, '')) : 0)
	// 	, 0);

	// 	console.log('Tech Savings Calculation:', {
	// 		technologies,
	// 		matchCount: matches.length,
	// 		totalSavings
	// 	});

	// 	return totalSavings;
	// };

	return (
		<div className="relative isolate overflow-hidden pt-4">
			<div className="flex flex-col items-center w-full p-6 pb-6 rounded-lg shadow-xl sm:p-8 m-auto">
				<h2 className="text-2xl font-semibold w-full">
					{company?.fields?.Name || "Your Company"} 
					<h3 className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
						Monthly Revenue
					</h3>
					{/* {loading ? (
						<Skeleton height={40} width={100} />
					) : (
						<p className="text-tremor-metric text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">
							{currencyFormat(getRevenue() / 12)}
						</p>
					)} */}
					{loading ? (
						<Skeleton height={graph_skeleton_height} />
					) : (
						<div>
							<AreaChart
								className="mt-1 h-72 w-[100%]"
								data={chartdata}
								index="date"
								yAxisWidth={95}
								stack={true}
								categories={["Status Quo", "Revenue Upgrades", "Savings Upgrades"]}
								colors={["indigo", "cyan", "emerald"]}
								valueFormatter={valueFormatter}
							/>
							<ResponsiveContainer width="100%" height={400}>
								<AreaChart
									data={data}
									margin={{ top: 50, right: 30, left: 30, bottom: 5 }}
								>
									{/* Remove dotted background grid lines */}
									<CartesianGrid strokeDasharray="3 3" vertical={false} />

									{/* X and Y Axis */}
									<XAxis
										dataKey="year"
										label={{ value: 'Months', position: 'insideBottomRight', offset: -10, fontSize: 12, fill: '#888' }}
										tick={{ fontSize: 12, fill: '#888' }} // Smaller, subtle ticks
										stroke="#ccc" // Light axis line color
										tickLine={false} // Remove tick lines for a cleaner look
										axisLine={{ stroke: '#ccc', strokeWidth: 1 }} // Subtle axis line styling
									/>
									<YAxis
										label={{
											value: '',
											angle: -90,
											position: 'insideLeft',
											style: { textAnchor: 'middle', fontSize: 12, fill: '#888' }
										}}
										tick={{
											fontSize: 12,
											fill: '#888'
										}}
										stroke="#ccc"
										tickFormatter={(value) => `$${value.toLocaleString()}`}
									/>

									{/* Custom Tooltip */}
									<Tooltip
										content={<CustomTooltip />}
										formatter={(value) => `+$${value.toLocaleString()}/mo`}
										contentStyle={{
											backgroundColor: 'white', // White background for the tooltip
											borderColor: '#ddd', // Light border color for subtlety
											borderRadius: 5, // Rounded corners for a cleaner look
											boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)' // Subtle shadow for depth
										}}
										itemStyle={{
											color: 'black', // Black color for text inside tooltip
											fontSize: 12, // Smaller font size for a cleaner look
											fontWeight: 'semibold' // Font weight for emphasis
										}}
										labelStyle={{
											color: '#888', // Lighter grey color for the label
											fontSize: 12 // Smaller font size for the label
										}}
									/>

									{/* Smaller and more subtle legend */}
									<Legend content={renderCustomLegend} />

									{/* Define stacked areas for each data key */}
									<Area
										type="monotone"
										dataKey="SavingsAccount"
										stackId="1"
										stroke="#F7D488"
										fill="#F7D488"
										fillOpacity={1}
										name="Do It Yourself" // Custom name for the legend label
									/>
									<Area
										type="monotone"
										dataKey="TraditionalInvestor"
										stackId="1"
										stroke="#F7CA6F"
										fill="#F7CA6F"
										fillOpacity={1}
										name="Traditional Software & Agencies" // Custom name for the legend label
									/>
									<Area
										type="monotone"
										dataKey="Upgraded"
										stackId="1"
										stroke="#F5BE53"
										fill="#F5BE53"
										fillOpacity={1}
										name="Upgraded" // Custom name for the legend label
									/>
								</AreaChart>
							</ResponsiveContainer>
						</div>
					)}
					<div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-5 mt-5">
						{loading ? (
							<Skeleton height={stats_skeleton_height} />
						) : (
							<Card className="mx-auto max-w-xs" decoration="top" style={{ borderTopColor: '#825c79' }}>
								<div className="flex items-center gap-2">
									<p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Financing</p>
									<div className="group relative">
										<InformationCircleIcon className="h-4 w-4 text-gray-400 cursor-help" />
										<div className="absolute bottom-full left-0 mb-2 hidden group-hover:block w-64 bg-gray-800 text-white text-xs rounded-lg p-2 shadow-lg z-10">
											<p>Calculated as 40% of annual revenue minus existing debt.</p>
										</div>
									</div>
								</div>
								<p className="text-2xl text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">
									{currencyFormat(getRevenue() * 0.4 - (company?.fields?.Debt || 0))}
								</p>
							</Card>
						)}

						{loading ? (
							<Skeleton height={stats_skeleton_height} />
						) : (
							<Card className="mx-auto max-w-xs" decoration="top" style={{ borderTopColor: '#849e96' }}>
								<div className="flex items-center gap-2">
									<p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Vendor Savings</p>
									<div className="group relative">
										<InformationCircleIcon className="h-4 w-4 text-gray-400 cursor-help" />
										<div className="absolute bottom-full left-0 mb-2 hidden group-hover:block w-64 bg-gray-800 text-white text-xs rounded-lg p-2 shadow-lg z-10">
											<p>Based on available vendor discounts, partnerships, reducing unused software, negotiation, bulk pricing, and more. Companies typically realize 10-20% of this amount in actual savings.</p>
										</div>
									</div>
								</div>
								<p className="text-2xl text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">
									{company?.fields ? currencyFormat(techSavings) + "/yr" : "Loading..."}
								</p>
							</Card>
						)}
						{isGrantsLoading ? (
							<Skeleton height={stats_skeleton_height} />
						) : (
							<Card className="mx-auto max-w-xs" decoration="top" style={{ borderTopColor: '#A7C8BE' }}>
								<div className="flex items-center gap-2">
									<p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Grants</p>
									<div className="group relative">
										<InformationCircleIcon className="h-4 w-4 text-gray-400 cursor-help" />
										<div className="absolute bottom-full left-0 mb-2 hidden group-hover:block w-64 bg-gray-800 text-white text-xs rounded-lg p-2 shadow-lg z-10">
											<p>Total available grants based on your industry and location. Most companies successfully secure 5-15% of their eligible grant amount with proper application support.</p>
										</div>
									</div>
								</div>
								<p className="text-2xl text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">
									{company?.fields ? currencyFormat(sum) + "/yr" : "Loading..."}
								</p>
							</Card>
						)}

						{loading ? (
							<Skeleton height={stats_skeleton_height} />
						) : (
							<Card className="mx-auto max-w-xs" decoration="top" style={{ borderTopColor: '#4B0082' }}>
								<div className="flex items-center gap-2">
									<p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Growth Upgrades</p>
									<div className="group relative">
										<InformationCircleIcon className="h-4 w-4 text-gray-400 cursor-help" />
										<div className="absolute bottom-full left-0 mb-2 hidden group-hover:block w-64 bg-gray-800 text-white text-xs rounded-lg p-2 shadow-lg z-10">
											<p>Based on proven growth tactics and playbooks that typically drive up to 85% revenue increase through optimized sales processes, marketing automation, and customer success strategies. Most companies can achieve 20-40% of this potential in their six months with proper implementation.</p>
										</div>
									</div>
								</div>
								<p className="text-2xl text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">
									{company && company.fields && company.fields.AnnualRevenue
										? currencyFormat(company.fields.AnnualRevenue * 0.243 * 5) + "/yr"
										: "Loading..."}
								</p>
							</Card>
						)}

						{loading ? (
							<Skeleton height={stats_skeleton_height} />
						) : (
							<Card className="mx-auto max-w-xs" decoration="top" style={{ borderTopColor: '#7CA5CF' }}>
								<div className="flex items-center gap-2">
									<p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">AI & Automations</p>
									<div className="group relative">
										<InformationCircleIcon className="h-4 w-4 text-gray-400 cursor-help" />
										<div className="absolute bottom-full left-0 mb-2 hidden group-hover:block w-64 bg-gray-800 text-white text-xs rounded-lg p-2 shadow-lg z-10">
											<p>Based on an average of $22,000 potential savings per employee annually through AI and automation. Most companies achieve 25 - 50% of this amount in their first year of implementation.</p>
										</div>
									</div>
								</div>
								<p className="text-2xl text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">
									{company && company.fields ? currencyFormat(company.fields.Employees * 22000) + "/yr" : "Loading..."}
								</p>
							</Card>
						)}
					</div>
				</h2>
				{/* <span class="text-sm font-semibold text-gray-500">{new Date().toLocaleString("en-US", { month: "long" })} 2023</span> */}
			</div>
			{/* Stats */}
		</div>
	);
}
