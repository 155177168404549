import { React, useState, useEffect } from "react";
import Airtable from "airtable";
import { CubeIcon, CubeTransparentIcon } from "@heroicons/react/20/solid";
import { Accordion, AccordionBody, Tooltip } from "@material-tailwind/react";
import { hasSampleAnswer, shortenText, classNames, isAnswer, getLastEditedTime, revise, setShowNotif, setContents, removeQueryParameters } from "./helpers.js";
import Notification from "./Notification.js";
import OpenAI from "openai";
import { encodingForModel } from "js-tiktoken";

const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;
// const answersTableId = process.env.REACT_APP_AIR_ANSWERS;
const answersTableId = "tblaUCzGrjHIiKgQ3";
// const questionsTableId = process.env.REACT_APP_AIR_QUESTIONS;
const questionsTableId = "tblCEYvZjJPl6iIaP";
const base = new Airtable({ apiKey: apiKey }).base(baseId);

const openai = new OpenAI({
	apiKey: process.env.REACT_APP_OPENAI_APIKEY,
	dangerouslyAllowBrowser: true,
});

const statuses = {
	Complete: "fill-indigo-300 ring-green-600/20",
	Incomplete: "fill-yellow-600 ring-gray-500/10",
	Archived: "fill-yellow-800 bg-yellow-50 ring-yellow-600/20",
	Link: "fill-green-800 bg-green-50 ring-yellow-600/20",
};

export default function GrantAnswersCard({ question, grant, company, answer, setAnswers, admin, sampleAnswerShow, pastAnswersContext}) {
	const defaultAccordionState = !isAnswer(answer) || revise(answer);
	const [openAnswerCard, setOpenAnswerCard] = useState(defaultAccordionState);
	const [autodraftLoading, setAutodraftLoading] = useState(false);
	const [feedbackSubmittedNotif, setFeedbackSubmittedNotif] = useState({
		showNotif: false,
		contents: { type: "", heading: "", message: "" },
	});
	const [submitAnswerNotif, setSubmitAnswerNotif] = useState({
		showNotif: false,
		contents: { type: "", heading: "", message: "" },
	});
	const [questionAutodraftNotif, setQuestionAutodraftNotif] = useState({
		showNotif: false,
		contents: { type: "", heading: "", message: "" },
	});


	const maxAnswerDisplaySize = 50;

	const handleOpenClick = () => {
		setOpenAnswerCard((prev) => !prev);
	};

	const submitAnswer = (companyId, questionId, grantIdentifier, answer, newAnswerText) => {
		let notificationContents = { type: "", heading: "", message: "" };
		if (isAnswer(answer) || (answer?.fields && answer.id)) {
			// Send PATCH request to edit answer
			// patch record answer.id to have new info newAnswerText

			fetch("https://api.airtable.com/v0/" + baseId + "/" + answersTableId + "/" + answer.id, {
				method: "PATCH",
				headers: {
					Authorization: `Bearer ${apiKey}`,
					"Content-Type": "application/json",
				},

				body: JSON.stringify({
					fields: {
						AnswerText: newAnswerText,
					},
				}),
			})
				.then((response) => {
					if (!response.ok) {
						throw Error(JSON.stringify(response));
					}

					return response.json();
				})
				.then((data) => {
					return new Promise((resolve, reject) => {
						JSON.stringify(data).toLowerCase().includes("error") ? reject(data) : resolve(data);
					});
				})
				.then(() => {
					base("answers")
						.select({ view: "Grid view" })
						.all()
						.then((records) => {
							setAnswers(records);
						});

					notificationContents.type = "success";
					notificationContents.heading = "Answer Re-Submitted";
					notificationContents.message = `Your answer has been updated.`;
					setContents(notificationContents, setSubmitAnswerNotif);
					setShowNotif(true, setSubmitAnswerNotif);
				})
				.catch((error) => {
					console.error("Error in editing answer:", error);
					notificationContents.type = "error";
					notificationContents.heading = "Error Editing Answer";
					notificationContents.message = `There was a problem submitting your answer. Please try again later.`;
					setContents(notificationContents, setSubmitAnswerNotif);
					setShowNotif(true, setSubmitAnswerNotif);
				});
		} else {
			// Send POST request to create answer
			fetch("https://api.airtable.com/v0/" + baseId + "/" + answersTableId, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${apiKey}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					fields: {
						AnswerText: newAnswerText,
						CompanyID: companyId,
						grantIdentifier: grantIdentifier,
						QuestionID: questionId,
						AnswerID: `${Date.now()}`,
					},
				}),
			})
				.then((response) => {
					if (!response.ok) {
						throw Error(JSON.stringify(response));
					}

					return response.json();
				})
				.then((data) => {
					return new Promise((resolve, reject) => {
						JSON.stringify(data).toLowerCase().includes("error") ? reject(data) : resolve(data);
					});
				})
				.then(() => {
					base("answers")
						.select({ view: "Grid view" })
						.all()
						.then((records) => {
							setAnswers(records);
						});

					notificationContents.type = "success";
					notificationContents.heading = "Answer Submitted";
					notificationContents.message = `Answer submitted to this question.`;
					setContents(notificationContents, setSubmitAnswerNotif);
					setShowNotif(true, setSubmitAnswerNotif);
				})
				.catch((error) => {
					console.error("Error in submitting Answer:", error);
					notificationContents.type = "error";
					notificationContents.heading = "Error Submitting Answer";
					notificationContents.message = `There was a problem submitting your answer. Please try again later.`;
					setContents(notificationContents, setSubmitAnswerNotif);
					setShowNotif(true, setSubmitAnswerNotif);
				});
		}
	};

	const submitSampleAnswer = (companyId, questionId, grantIdentifier, question, newAnswerText) => {
		let notificationContents = { type: "", heading: "", message: "" };
		// if (isAnswer(answer) || (answer?.fields && answer.id)) {
			// Send PATCH request to edit answer
			// patch record answer.id to have new info newAnswerText

			// To do:
			// 1. DONE - Send PATCH request to edit question - change to questionstableId
			// 2. Modify everything from answers to questions

			fetch("https://api.airtable.com/v0/" + baseId + "/" + questionsTableId + "/" + question.id, {
				method: "PATCH",
				headers: {
					Authorization: `Bearer ${apiKey}`,
					"Content-Type": "application/json",
				},

				body: JSON.stringify({
					fields: {
						SampleAnswer: newAnswerText,
					},
				}),
			})
				.then((response) => {
					if (!response.ok) {
						throw Error(JSON.stringify(response));
					}

					return response.json();
				})
				.then((data) => {
					return new Promise((resolve, reject) => {
						JSON.stringify(data).toLowerCase().includes("error") ? reject(data) : resolve(data);
					});
				})
				.then(() => {
					base("answers")
						.select({ view: "Grid view" })
						.all()
						.then((records) => {
							setAnswers(records);
						});

					notificationContents.type = "success";
					notificationContents.heading = "Sample Answer Re-Submitted";
					notificationContents.message = `Your answer has been updated.`;
					setContents(notificationContents, setSubmitAnswerNotif);
					setShowNotif(true, setSubmitAnswerNotif);
				})
				.catch((error) => {
					console.error("Error in editing sample answer:", error);
					notificationContents.type = "error";
					notificationContents.heading = "Error Editing Sample Answer";
					notificationContents.message = `There was a problem submitting your sample answer. Please try again later.`;
					setContents(notificationContents, setSubmitAnswerNotif);
					setShowNotif(true, setSubmitAnswerNotif);
				});
		// }
	};

	// REQUIRES : the answer object is such that isAnswer(answer) === true
	const submitFeedback = (answer, feedbackText) => {
		if (!isAnswer(answer)) return;

		const answerId = answer.id;
		let notificationContents = { type: "", heading: "", message: "" };
		if (typeof feedbackText !== "string") return;

		fetch("https://api.airtable.com/v0/" + baseId + "/" + answersTableId + "/" + answerId, {
			method: "PATCH",
			headers: {
				Authorization: `Bearer ${apiKey}`,
				"Content-Type": "application/json",
			},

			body: JSON.stringify({
				fields: {
					Feedback: feedbackText,
				},
			}),
		})
			.then((response) => {
				if (!response.ok) {
					throw Error(JSON.stringify(response));
				}

				return response.json();
			})
			.then((data) => {
				return new Promise((resolve, reject) => {
					if (JSON.stringify(data).toLowerCase().includes("error")) {
						reject(data);
					} else {
						resolve(data);
					}
				});
			})
			.then(() => {
				// Emulating pulling from db.
				//  - A state change is quicker to perform then pulling from db
				//  - Completely safe to do here because the record is being patched above, mutating only the same field as
				//		our state management.
				//  - No Long term effects (no magic records dissapearing or appearing, all behvaiour will remain as expected) of
				//      updating state only rather than pulling from airtable.
				setAnswers((prev) => [
					...prev.filter((ans) => ans.id != answer.id),
					{ ...answer, fields: { ...answer.fields, Feedback: feedbackText } },
				]);

				notificationContents.type = "success";
				notificationContents.heading = !feedbackText ? "Feedback Deleted" : "Feedback Submitted";
				notificationContents.message = !feedbackText
					? "Feedback successfully removed off this answer"
					: `Feedback '${feedbackText}' added to this answer.`;
				setContents(notificationContents, setFeedbackSubmittedNotif);
				setShowNotif(true, setFeedbackSubmittedNotif);
			})
			.catch((error) => {
				notificationContents.type = "error";
				notificationContents.heading = "Failed to Submit Feedback. Please try again later.";
				notificationContents.message = JSON.stringify(error);
				setContents(notificationContents, setFeedbackSubmittedNotif);
				setShowNotif(true, setFeedbackSubmittedNotif);

				console.error("Error in submitting feedback: ", error);
			});
	};

	const handleAutodraftQuestion = async (question, company) => {
		const maxInputTokens = 16385 - 1000; // Change to match the model, -1000 to account for innaccuracies in tiktoken
		const model = "gpt-4o";
		const questionMessages = pastAnswersContext?.length > 0 ? [
			{ role: "user", content: `The following is a series of questions of the company and the company's answers:\r\n` },
			...pastAnswersContext.map((q) => ({
				role: "user",
				content: `Question: ${q.question}. \r\n Answer: ${q.answer}`,
			})),
		] : [];

		const initialMessages = [
			{
				role: "system",
				content: `You will be given information about a company and then be asked to answer a question regarding the company. Answer the question from the perspective of the company.`,
			},
			{
				role: "user",
				content: `Employees: ${company.fields.Employees}. \r\n Country: ${company.fields.Country}. \r\n Province: ${company.fields.ProvinceOrState}. \r\n Industry: ${company.fields.Industry}. \r\n Founded: ${company.fields.Founded}. Profitable: ${company.fields["Profitable?"]}`,
			},
			{
				role: "user",
				content: `This is the description of the company named ${company.fields.Name}. \r\n Description: ${company.fields.Description} \r\n`,
			},
			{ role: "user", content: `The following is additional information about the company.\r\n ${company.fields.LongDescription}` },
		];

		let questionToAskMessage = {
			role: "user",
			content: `Based on what you know about ${company.fields.Name}, answer this question: ${question.fields.QuestionText}`,
		};

		if (hasSampleAnswer(question)) {
			questionToAskMessage.content += `\n Please format your answer according to this sample answer: ${question.fields.SampleAnswer}`
		}

		let messages = [...initialMessages, ...questionMessages, questionToAskMessage];

		// // // // // // // // // // // // // // // // // // // // // // // // // //
		// Code for using only scraped information + company description in prompt //
		// // // // // // // // // // // // // // // // // // // // // // // // // //
		// messages = [...initialMessages, questionToAskMessage];

		// // // // // // // // // // // // // // // // // // // // // // // // // //
		// Code for using only scraped information + company description in prompt //
		// // // // // // // // // // // // // // // // // // // // // // // // // //

		let notificationContents = { type: "", heading: "", message: "" };
		try {
			const encoder = encodingForModel(model);
			let tokenCount = encoder.encode(messages.map((message) => message.content).join("\n")).length;
			let numberOfQuestionsRemoved = 0;
			if (tokenCount > maxInputTokens) {
				
				while (tokenCount > maxInputTokens) {
					if (questionMessages.length === 0) {
						throw new Error(`Number of tokens required ${tokenCount} is larger than maximum input tokens ${maxInputTokens}`);
					}

					questionMessages.splice(0, 1);
					++numberOfQuestionsRemoved;

					messages = [...initialMessages, ...questionMessages, questionToAskMessage];
					tokenCount = encoder.encode(messages.map((message) => message.content).join("\n")).length;
				}
			}

			console.log("context messages", questionMessages);
			console.log("we removed", numberOfQuestionsRemoved, "questions");

			setAutodraftLoading(true);
			const chatCompletion = await openai.chat.completions.create({
				model: model,
				messages: [...messages],
			});
			setAutodraftLoading(false);

			let retVal = chatCompletion.choices[0].message.content;
			const element = document.getElementById(question.fields.QuestionID);
			element.value = retVal;
		} catch (e) {
			notificationContents.type = "error";
			notificationContents.heading = "Failed to Autodraft Question";
			notificationContents.message = JSON.stringify(e);
			setContents(notificationContents, setQuestionAutodraftNotif);
			setShowNotif(true, setQuestionAutodraftNotif);

			console.error("Error:", e);
		}
	};

	return (
		<li
			role="listitem"
			key={question.id}
			className="flex max-w-screen w-full items-center overflow-x-hidden justify-between gap-x-6 py-5 px-5 hover:bg-gray-50 rounded-xl"
		>
			<div className="max-w-screen w-full">
				<div className="flex items-start gap-x-4">
					{isAnswer(answer) && !revise(answer) ? (
						<Tooltip placement="top" content="Answered">
							<div>
								<CubeIcon aria-hidden className={classNames(statuses[question.fields.Link ? "Link" : "Complete"], "w-4 h-4")} />
							</div>
						</Tooltip>
					) : (
						<Tooltip placement="top" content="Yet to Answer">
							<div>
								<CubeTransparentIcon aria-hidden className={classNames(statuses["Incomplete"], "w-4 h-4")} />
							</div>
						</Tooltip>
					)}

					{/* Sample Answer: {question.fields.SampleAnswer ? question.fields.SampleAnswer : "N/A"} */}

					<div className="max-w-[750px] flex-auto">
						<Accordion open={Boolean(openAnswerCard)}>
							<div className="text-wrap overflow-x-hidden">
								<button className="inline-flex hover:underline" onClick={() => handleOpenClick()}>
									{revise(answer) && <p className="text-md font-semibold text-amber-600 text-left">Revise:&nbsp;</p>}
									<p className="text-md font-semibold text-left text-wrap text-gray-900">{question.fields.QuestionText}</p>
								</button>
							</div>

							<AccordionBody>
								{hasSampleAnswer(question) && revise(answer) && <div className="mt-1">
									<hr className="p-1" />
								</div>}
								{revise(answer) && (
									<div className="flex">
										<span className="inline-flex">
											<p className="font-semibold max-w-sm w-full">Feedback on your answer:&nbsp;</p>
											<p className="text-wrap">{answer.fields.Feedback}</p>
										</span>
									</div>
								)}
								<div className="font-semibold max-w-sm w-full">Answer:</div>
								<span className="inline-flex">
									<form
										className="inline-flex"
										onSubmit={(e) => {
											e.preventDefault();
											let newAnswerText = e.target[question.fields.QuestionID].value;
											console.log(newAnswerText)
											console.log(
												"company id", company.id,
												"question.fields.QuestionID", question.fields.QuestionID,
												"grant.id", `${grant?.fields?.Name}-${removeQueryParameters(grant?.fields?.GrantUrl)}`,
												"answer", answer,
												"newAnswerText", newAnswerText);
											submitAnswer(company.id, question.fields.QuestionID, `${grant?.fields?.Name}-${removeQueryParameters(grant?.fields?.GrantUrl)}`, answer, newAnswerText);
										}}
									>
										<textarea
											className="rounded-md border resize text-black"
											id={question.fields.QuestionID}
											rows="4"
											cols="60"
											defaultValue={isAnswer(answer) ? answer.fields.AnswerText : ""}
											placeholder={
												!isAnswer(answer)
													? "Enter an Answer in the Shared Google Doc, and submit 'Complete' in this field to stop reminders"
													: ""
											}
										/>
										<div className="flex items-center justify-center p-3 ">
											<button
												type="submit"
												className="rounded-md bg-white px-2.5 py-1.5 text-sm text-black shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
											>
												{isAnswer(answer) ? "Resubmit" : "Submit"}
											</button>
										</div>
									</form>
									{admin && (
										<div className="inline-flex justify-center items-center text-center">
											<button
												onClick={() => handleAutodraftQuestion(question, company)}
												type="button"
												className="rounded-md bg-white px-2.5 py-1.5 text-sm text-black shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
												disabled={autodraftLoading}
											>
												{autodraftLoading ? "Loading..." : "Autodraft"}
											</button>
										</div>
									)}
								</span>

								{admin && isAnswer(answer) && !question.fields.Link && (
									<div>
										<div className="p-2">
											<h4 className="font-semibold text-xs">{revise(answer) ? "Edit Feedback:" : "Add Feedback:"}</h4>
										</div>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												if (!answer.id) {
													console.log("no answer id");
													return;
												}
												let feedbackText = e.target[`${answer.fields.AnswerID}Feedback`].value;
												if (revise(answer) && feedbackText == answer.fields.Feedback) return;

												submitFeedback(answer, feedbackText);
											}}
											className="inline-flex"
										>
											<div className="inline-flex px-2">
												<textarea
													className="text-xs rounded-md border resize text-black"
													id={`${answer.fields.AnswerID}Feedback`}
													rows="1"
													cols="60"
													defaultValue={revise(answer) ? answer.fields.Feedback : ""}
													placeholder="How can they improve their answer?"
												/>
											</div>
											<div className="flex justify-content text-center items-center">
												<button
													type="submit"
													className="rounded-md flex bg-white px-2.5 py-1.5 text-xs text-black shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
												>
													{revise(answer) ? "Resubmit" : "Submit"}
												</button>
											</div>
										</form>
									</div>
								)}
							</AccordionBody>
						</Accordion>
					</div>
					{isAnswer(answer) && (
						<div>
							{question.fields.Link ? (
								<p
									onClick={() => window.open(answer.fields.AnswerText)}
									className="text-sx text-gray-700 hover:cursor-pointer hover:underline text-left"
								>
									{answer.fields.AnswerText}
								</p>
							) : (
								<p className="text-sx text-gray-700 text-left">{shortenText(answer.fields.AnswerText, maxAnswerDisplaySize)}</p>
							)}
						</div>
					)}
				</div>
				<div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
					{isAnswer(answer) && <p className="whitespace-nowrap">Last Edited {getLastEditedTime(answer.fields.LastModified)}</p>}
				</div>
					{/* Sample Answer Form */}
					{/* { sampleAnswerShow ? "Hide" : "Show"} */}
					{ sampleAnswerShow ? (
					<div className="hello-unique mt-20">
						<p className="font-semibold max-w-sm w-full">Sample Answer:</p>
						<span className="inline-flex">
							<br />
							{/* <p className="text-wrap">{question.fields.SampleAnswer}</p> */}
							<form
								className="inline-flex"
								onSubmit={(e) => {
									e.preventDefault();
									let newSampleAnswerText = e.target[question.fields.QuestionID].value;
									submitSampleAnswer(company.id, question.fields.QuestionID, `${grant?.fields?.Name}-${removeQueryParameters(grant?.fields?.GrantUrl)}`, question, newSampleAnswerText);
								}}
							>
								<textarea
									className="rounded-md border resize text-black"
									id={question.fields.QuestionID}
									rows="3"
									cols="60"
									defaultValue={isAnswer(answer) ? question.fields.SampleAnswer : ""}
									placeholder={
										!isAnswer(answer)
											? "Enter an Answer in the Shared Google Doc, and submit 'Complete' in this field to stop reminders"
											: ""
									}
								/>
								<div className="flex items-center justify-center p-3 ">
									<button
										type="submit"
										className="rounded-md bg-white px-2.5 py-1.5 text-sm text-black shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
									>
										{isAnswer(answer) ? "Resubmit" : "Submit"}
									</button>
								</div>
							</form>
						</span>
					</div> ): null}
			</div>

			{/* <div className="flex flex-none items-center gap-x-4">
				<a
					href="#"
					className=" rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
				>
					View project<span className="sr-only">, {question.fields.QuestionText}</span>
				</a>
				
				
			</div> */}

			<Notification
				showNotif={feedbackSubmittedNotif.showNotif}
				setShowNotif={(val) => setShowNotif(val, setFeedbackSubmittedNotif)}
				contents={feedbackSubmittedNotif.contents}
			/>

			<Notification
				showNotif={submitAnswerNotif.showNotif}
				setShowNotif={(val) => setShowNotif(val, setSubmitAnswerNotif)}
				contents={submitAnswerNotif.contents}
			/>

			<Notification
				showNotif={questionAutodraftNotif.showNotif}
				setShowNotif={(val) => setShowNotif(val, setQuestionAutodraftNotif)}
				contents={questionAutodraftNotif.contents}
			/>
		</li>
	);
}
