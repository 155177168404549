import OpenAI from "openai";
import { encodingForModel } from "js-tiktoken";
import Airtable from "airtable";
import { isNumbered, numberingCompare, revise, isAnswer, hasSampleAnswer } from "./helpers.js";
import { currencyFormat } from './helpers.js';

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_APIKEY,
  dangerouslyAllowBrowser: true,
});

const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;

// Initialize Airtable base
const base = new Airtable({ apiKey }).base(baseId);

// Add this mock data generator
const generateMockAnswer = (question, companyRecord) => {
  const defaultAnswers = {
    experience: `${companyRecord.fields.Name} has extensive experience in ${companyRecord.fields.Industry || 'our industry'}. Founded in ${companyRecord.fields.Founded || 'recent years'}, we have successfully...`,
    impact: `Our solution directly addresses market needs by leveraging our ${companyRecord.fields.Technologies || 'technology stack'}. With ${companyRecord.fields.Employees || 'our team'} employees, we...`,
    innovation: `${companyRecord.fields.Name}'s innovative approach combines ${companyRecord.fields.Technologies || 'modern technologies'} to solve ${companyRecord.fields.Industry || 'industry'} challenges...`,
    default: `As ${companyRecord.fields.Name}, we are well-positioned to address this with our team of ${companyRecord.fields.Employees || 'experienced'} professionals...`
  };

  // Try to match question type based on keywords
  const questionText = question.fields.QuestionText.toLowerCase();
  if (questionText.includes('experience') || questionText.includes('background')) {
    return defaultAnswers.experience;
  } else if (questionText.includes('impact') || questionText.includes('benefit')) {
    return defaultAnswers.impact;
  } else if (questionText.includes('innovative') || questionText.includes('novel')) {
    return defaultAnswers.innovation;
  }
  return defaultAnswers.default;
};

// Add this function before handleAutodraftGrant
const getAutodraftQuestionsText = async (companyRecord, questions, pastAnswersContext = [], skipAIGeneration = false) => {
  try {
    const processedAnswers = [];
    
    for (const question of questions) {
      let answerText;
      
      if (skipAIGeneration) {
        // Use mock data if AI generation is skipped
        answerText = generateMockAnswer(question, companyRecord);
      } else {
        try {
          const completion = await openai.chat.completions.create({
            model: "gpt-4o",
            messages: [
              {
                role: "system",
                content: `You are helping ${companyRecord.fields.Name} with grant applications.`
              },
              {
                role: "user",
                content: question.fields.QuestionText
              }
            ],
            temperature: 0.7,
            max_tokens: 500
          });
          
          answerText = completion.choices[0].message.content;
        } catch (error) {
          console.warn('AI generation failed, using mock data:', error);
          answerText = generateMockAnswer(question, companyRecord);
        }
      }
      
      processedAnswers.push(
        `<strong>${question.fields.QuestionText}</strong>\nAnswer: ${answerText}`
      );
    }
    
    return processedAnswers;
  } catch (error) {
    console.error('Error in getAutodraftQuestionsText:', error);
    throw error;
  }
};

const handleAutodraftGrant = async (grantRecord, companyRecord, questions, clientEmail, pastAnswersContext, skipAIGeneration = false) => {
  const webhookURL = `https://hooks.zapier.com/hooks/catch/13965335/${process.env.REACT_APP_ZAPIER_AUTODRAFT}/`;

  try {
    // Validate inputs
    if (!questions || !Array.isArray(questions) || questions.length === 0) {
      throw new Error('No valid questions provided');
    }

    console.log('\nStarting grant record update process...');
    console.log('Company:', companyRecord.fields.Name);
    console.log('Grant:', grantRecord.fields.Name);

    const questionsText = (await getAutodraftQuestionsText(companyRecord, questions, pastAnswersContext, skipAIGeneration)).join("\n\n");
    
    if (!questionsText) {
      throw new Error('No valid answers generated');
    }

    // Create/Update record in ALLGRANTS&SAVINGS table
    console.log('\nUpdating ALLGRANTS&SAVINGS table...');
    const grantsTable = base("ALLGRANTS&SAVINGS(Automated-AccountsReceivable)");
    const today = new Date();
    const formattedDate = `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`;
    
    // Format amount properly
    const amount = grantRecord.fields.Amount || grantRecord.fields.AnnualMargin;
    const formattedAmount = typeof amount === 'string' ? amount : `$${amount}`;
    try {
      // Check if record exists
      const filterFormula = `AND({CompanyName} = '${companyRecord.fields.Name}', {UpgradeName} = '${grantRecord.fields.Name}')`;
      const existingRecords = await grantsTable.select({
        maxRecords: 1,
        filterByFormula: filterFormula
      }).firstPage();

      const recordData = {
        CompanyName: companyRecord.fields.Name,
        ClientEmail: clientEmail,
        RequesterEmail: clientEmail,
        PartnerEmail: '',
        UpgradeName: grantRecord.fields.Name,
        Amount: formattedAmount,
        Date: formattedDate,
        UpgradeType: 'Grant',
        Status: 'Sent'
      };

      if (existingRecords && existingRecords.length > 0) {
        // Update existing record
        const updatedRecord = await grantsTable.update([{
          id: existingRecords[0].id,
          fields: recordData
        }]);
        console.log('Record updated successfully:', updatedRecord[0].id);
      } else {
        // Create new record
        const createdRecords = await grantsTable.create([{
          fields: recordData
        }]);
        console.log('Record created successfully:', createdRecords[0].id);
      }

      // Send to Zapier webhook
      const response = await fetch(webhookURL, {
        method: "POST",
        body: JSON.stringify({
          grant: grantRecord.fields.Name,
          amount: formattedAmount,
          company: companyRecord.fields.Name,
          questions: questionsText,
          clientEmail: clientEmail
        }),
      });
      if (!response.ok) {
        throw new Error(`Webhook request failed: ${response.status}`);
      }

      return await response.json();

    } catch (airtableError) {
      console.error('Error updating Airtable:', {
        error: airtableError.message,
        status: airtableError.statusCode,
        details: airtableError.error
      });
      throw airtableError;
    }

  } catch (error) {
    console.error("Error in handleAutodraftGrant:", {
      message: error.message,
      stack: error.stack,
      details: error.error || error.details || 'No additional details'
    });
    throw error;
  }
};

const getQuestionsToDisplay = async (answers, grantIdentifier, companyId, questions) => {
  try {
    // Filter questions for this specific grant
    const grantQuestions = questions.filter(question => 
      question.fields.grantIdentifier?.includes(grantIdentifier)
    );

    // Map questions with their existing answers
    const processedQuestions = grantQuestions.map(question => {
      const existingAnswer = answers.find(answer => 
        answer.fields.QuestionID === question.fields.QuestionID &&
        answer.fields.CompanyID === companyId
      );

      return {
        ...question,
        fields: {
          ...question.fields,
          existingAnswer: existingAnswer?.fields?.AnswerText
        }
      };
    });

    return processedQuestions;
  } catch (error) {
    console.error('Error in getQuestionsToDisplay:', error);
    return null;
  }
};

export {
  openai,
  base,
  handleAutodraftGrant,
  getQuestionsToDisplay,
  getAutodraftQuestionsText
};