import {
  React,
  Fragment,
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import { FunnelIcon, TrashIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  BarsArrowDownIcon,
  BarsArrowUpIcon,
} from "@heroicons/react/24/outline";
import { currencyFormat, classNames, extractDomain } from "./helpers.js";
import { Tooltip } from "@material-tailwind/react";
import Airtable from "airtable";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Menu } from "@headlessui/react";
import { send } from "emailjs-com";
import { AppContext } from "./AppContext.js";
import { useUser } from "@clerk/clerk-react";

const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;
const companiesTableId = process.env.REACT_APP_AIR_COMPANIES;

const base = new Airtable({ apiKey }).base(baseId);

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

const pageSize = 36;

export default function AutomationRequests({}) {
  const [automationRequests, setAutomationRequests] = useState([]);
  const { user, isSignedIn, isLoaded: isUserLoaded } = useUser();
  const [selectedAutomation, setSelectedAutomation] = useState(null);
  const [isRequirementsOpen, setIsRequirementsOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState('Requested');

  useEffect(() => {
    base("automationRequests")
      .select({ 
        view: "Grid view",
        sort: [{ field: "LastModified", direction: "desc" }],
        filterByFormula: "NOT({LastModified} = '')"
      })
      .all()
      .then((data) => {
        const sortedData = data.sort((a, b) => {
          return new Date(b.fields.LastModified) - new Date(a.fields.LastModified);
        });
        setAutomationRequests(sortedData);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="bg-white px-6 py-12 sm:py-12 lg:px-8">
      <div className="mx-auto text-center">
        <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
          {currencyFormat(
            automationRequests.reduce((sum, automation) => {
              return sum + (automation.fields.Budget || 0);
            }, 0)
          )}
          /yr Automation Requests
        </h2>

        <p className="mt-6 text-lg leading-8 text-gray-600">
          Recent automation requests we think are a good fit for you
        </p>
        
        <div className="mt-4 flex justify-center gap-4">
          {['Requested', 'Interested', 'Submitted'].map((status) => (
            <button
              key={status}
              onClick={() => setActiveFilter(status)}
              className={`px-4 py-2 rounded-lg text-white transition-all duration-200 ${
                status === 'Requested' ? 'bg-[rgb(132,158,150)] hover:bg-[rgb(112,138,130)]' :
                status === 'Interested' ? 'bg-yellow-500 hover:bg-yellow-600' :
                'bg-green-500 hover:bg-green-600'
              } ${activeFilter === status ? 'ring-2 ring-offset-2 ring-black' : ''}`}
            >
              {status}
            </button>
          ))}
        </div>
        <br />
      </div>

      <div className="mx-auto max-w-7xl">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {automationRequests
            .filter(automation => automation.fields.Status === activeFilter)
            .map((automation, index) => {
            const fields = automation?.fields;
            const currentStatus = fields.Status || 'Requested';
            
            const statusStyles = {
              Requested: 'bg-[rgb(132,158,150)] hover:bg-[rgb(112,138,130)]',
              Interested: 'bg-yellow-500 hover:bg-yellow-600',
              Submitted: 'bg-green-500 hover:bg-green-600'
            };

            return (
              <div
                key={index}
                className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-200"
              >
                <div className="flex justify-between items-start mb-4">
                  <span className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm">
                    {fields.AutomationType || "General"}
                  </span>
                  <span className="text-gray-500 text-sm">
                    {new Date(fields.LastModified).toLocaleDateString('en-US', {
                      month: 'short',
                      day: 'numeric',
                      year: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric'
                    })}
                  </span>
                </div>

                <h3 className="font-bold text-xl mb-2 line-clamp-2">
                  {fields.AutomationDescription}
                </h3>

                <div className="mb-4">
                  <span className="text-sm text-gray-600">Upgrader Earnings</span>
                  <span className="block text-2xl font-bold text-gray-800">
                    {currencyFormat((fields.Budget || 0) / 3)}/yr
                  </span>
                </div>

                <div className="space-y-2">
                  {fields.RequiredSkills && (
                    <div className="flex flex-wrap gap-2">
                      {fields.RequiredSkills.split(',').map((skill, i) => (
                        <span key={i} className="px-2 py-1 bg-gray-100 rounded-full text-sm">
                          {skill.trim()}
                        </span>
                      ))}
                    </div>
                  )}
                </div>

                <div className="flex items-center gap-2">
                  <button 
                    onClick={() => {
                      setSelectedAutomation(automation);
                      setIsRequirementsOpen(true);
                    }}
                    className={`mt-4 w-full text-white py-2 px-4 rounded-lg transition-all duration-200 shadow-md hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] ${
                      currentStatus === 'Requested' ? 'bg-[rgb(132,158,150)] hover:bg-[rgb(112,138,130)]' :
                      currentStatus === 'Interested' ? 'bg-yellow-500 hover:bg-yellow-600' :
                      'bg-green-500 hover:bg-green-600'
                    }`}
                  >
                    Accept This Task
                  </button>
                  {currentStatus !== 'Requested' && (
                    <button 
                      onClick={() => {
                        setSelectedAutomation(automation);
                        setIsRequirementsOpen(true);
                      }}
                      className="mt-4 p-2 text-gray-600 hover:text-gray-800 rounded-lg hover:bg-gray-100"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Requirements Modal */}
      <Transition.Root show={isRequirementsOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setIsRequirementsOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-3 sm:mt-5">
                      <Dialog.Title as="h3" className="text-xl font-semibold leading-6 text-gray-900 text-center mb-6">
                        Task Requirements
                      </Dialog.Title>
                      <div className="mt-4">
                        {selectedAutomation?.fields?.Requirements && (
                          <div className="space-y-6">
                            {(() => {
                              try {
                                const requirements = JSON.parse(selectedAutomation.fields.Requirements);
                                return (
                                  <>
                                    {/* Client Requirements Section */}
                                    {requirements.fromClient && requirements.fromClient.length > 0 && (
                                      <div className="bg-blue-50 p-4 rounded-lg">
                                        <h4 className="text-lg font-semibold text-blue-900 mb-3">Client Information Needed</h4>
                                        <ul className="space-y-3">
                                          {requirements.fromClient.map((item, index) => (
                                            <li key={index} className="text-blue-800">
                                              <span className="font-medium">{item.name}:</span>
                                              <span className="text-blue-600 ml-2 italic">{item.value}</span>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    )}

                                    {/* Technical Requirements Section */}
                                    {requirements.technical && requirements.technical.length > 0 && (
                                      <div className="bg-gray-50 p-4 rounded-lg">
                                        <h4 className="text-lg font-semibold text-gray-900 mb-3">Technical Requirements</h4>
                                        <ul className="space-y-3">
                                          {requirements.technical.map((item, index) => (
                                            <li key={index} className="text-gray-800">
                                              <span className="font-medium">{item.name}:</span>
                                              <span className="text-gray-600 ml-2">{item.value}</span>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    )}
                                  </>
                                );
                              } catch (e) {
                                // Fallback for non-JSON requirements
                                return (
                                  <p className="text-gray-600 whitespace-pre-wrap">
                                    {selectedAutomation.fields.Requirements}
                                  </p>
                                );
                              }
                            })()}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-[rgb(132,158,150)] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[rgb(112,138,130)]"
                      onClick={() => setIsRequirementsOpen(false)}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

AutomationRequests.defaultProps = {
  admin: false,
};
